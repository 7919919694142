import { Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { PageButtonsComponent } from '../../../../shared/ui';
import { PageButton } from '../../../../shared/ui/page-buttons/pagebutton.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ProductEditViewComponent } from './product-edit-view/product-edit-view.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-product-edit',
  standalone: true,
  imports: [
    CommonModule,
    PageButtonsComponent,
    ProductEditViewComponent
  ],
  templateUrl: './product-edit.component.html',
  styleUrl: './product-edit.component.scss',
})
export class ProductEditComponent implements OnInit {

  private router= inject(Router);
  private route= inject(ActivatedRoute);
  
  //productoId:string;
  botones: PageButton[];
  title: string = 'Agregar Producto';
  @Input() editMode:boolean;
  @Input() productoId:string|null=null;

  @ViewChild('cp_productview') productView:any;

  ngOnInit(): void {

    this.route.params.subscribe((param) => {
      if (param.id) {
        this.productoId = param.id;
        this.title = 'Editar Producto';
        this.editMode = true;
      }
    });

    this._initBotones();
    
  }

  

  private _initBotones() {
    this.botones = [
      {
        label: 'cancelar',
        icon: 'bx bx-block',
        cssClass: 'btn-warning',
        actionType:()=> this._cancelar()
      },
      {
        label: 'guardar',
        icon: 'bx bx-save',
        cssClass: 'btn-info',
        actionType:()=> this._guardar()
      }
    ];
  }

  
  private _cancelar(){
    this.router.navigate(['inventory/product/list']);
  }

  private _guardar(){
    if(this.productView){
      this.productView.guardar();
    }
  }

  onSuccesss(){
    this.router.navigate([`inventory/product/list`]);
  }


}
