import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectCatalogComponent } from '@components/index';
import { SpinnerComponent } from '@shared/ui';
import { BsSidebarRef } from '../../../../../core/models/sidebar-ref';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@services/alert.service';
import { BsDropdownModule,BsDropdownConfig} from 'ngx-bootstrap/dropdown';
import { trigger,state, style, animate, transition } from '@angular/animations';
import { NumbersOnlyDirective } from '@directives/numbers-only.directive';

@Component({
  selector: 'app-company-general-establecimiento',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SelectCatalogComponent,
    SpinnerComponent,
    BsDropdownModule,
    NumbersOnlyDirective
  ],
  templateUrl: './company-general-establecimiento.component.html',
  styleUrl: './company-general-establecimiento.component.scss',
  providers:[BsDropdownConfig],
  animations: [
    trigger('toggleDiv', [
      state('show', style({
        opacity: 1,
        transform: 'translateX(0)'
      })),
      state('hide', style({
        opacity: 0,
        transform: 'translateX(-100%)'
      })),
      transition('show => hide', [
        animate('0.5s')
      ]),
      transition('hide => show', [
        animate('0.5s')
      ])
    ])
  ]
})
export class CompanyGeneralEstablecimientoComponent implements OnInit {
 
  private formBuilder = inject(FormBuilder);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  form: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  title: string = 'Listado';
  titleEdit: string = 'Nuevo';

  viewState = 'list';
  isEditVisible: boolean = false;

  constructor(private sidebar: BsSidebarRef) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      numero: ['', [Validators.required]],
      nombreComercial:['',null],
      direccion:['',[Validators.required]],
      estado:[true,[Validators.required]]
    });
  }

  get f() {
    return this.form.controls;
  }

  showEdit() {
    this.isEditVisible = true;
  }

  showList() {
    this.isEditVisible = false;
  }

  /*showEdit() {
    this.viewState = 'edit';
  }

  showList() {
    this.viewState = 'list';
  }

  get listClass() {
    return this.viewState === 'list' ? 'show' : 'hide';
  }

  get editClass() {
    return this.viewState === 'edit' ? 'show' : 'hide';
  }*/

}
