import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NumbersOnlyDirective } from '@directives/numbers-only.directive';
import { AlertService } from '@services/alert.service';
import { RightsidebarService } from '@services/rightsidebar.service';
import { SpinnerComponent } from '@shared/ui';
import { CompanyGeneralEstablecimientoComponent } from '../company-general-establecimiento/company-general-establecimiento.component';

@Component({
  selector: 'app-company-general-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnerComponent,
    NumbersOnlyDirective,
  ],
  templateUrl: './company-general-edit.component.html',
  styleUrl: './company-general-edit.component.scss'
})
export class CompanyGeneralEditComponent implements OnInit {
  private formBuilder = inject(FormBuilder);
  private http=inject(HttpClient);
  private msg = inject(AlertService);
  private rightsidebar =inject(RightsidebarService);
  
  form: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;


  ngOnInit(): void {
    //console.log('CompanyGeneralComponent.');    
    this.form = this.formBuilder.group({
      ruc: ['', [Validators.required]],
      razonSocial: ['', null],
      nombreComercial: ['', null],
      numeroEstablecimiento: ['',null],
      obligadoLlevarContabillidad:[false,null],
      contribuyenteEspecial:[false,null],
      exportador:[false,null],
      contribuyenteRimpe:[false,null],
      agenteRetencion:[false,null],
      ciudad:['', null],
      telefono:['', null],
      direccion:['', null],
      actividadEconomica:['', null],
      numeroDecimal:[0,null],
      logoUrl:['',null],
    });
    
    this._obtener();
  }

  private _obtener(){
    this.loading = true;

    this.http
      .get('company')
      .subscribe({
        next: (data: any) => {
          //console.log(data);
          this.form.patchValue(data);

         this.loading = false
        },
        error: (error) => (this.loading = false),
      });
  }


  addorEditEstablecimiento(){
    //console.log('JOla');
    this.rightsidebar.show(CompanyGeneralEstablecimientoComponent);
  }

  guardar(){
    
  }
}
