import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DatatableEditorModel } from '@components/datatable-editor/datatable-editor.model';
import { DatatableEditorComponent, InputSearchDualComponent, SelectCatalogComponent } from '@components/index';
import { InputSearchDualModel } from '@components/input-search-dual/input-search-dual.model';
import { AlertService } from '@services/alert.service';
import { PageButtonsComponent, SpinnerComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { TabsModule } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-cliente-edit',
  standalone: true,
  imports: [
    CommonModule,
    PageButtonsComponent,
    SpinnerComponent,
    ReactiveFormsModule,
    FormsModule,  
    SelectCatalogComponent,
    InputSearchDualComponent,
    TabsModule,
    DatatableEditorComponent
  ],
  templateUrl: './cliente-edit.component.html',
  styleUrl: './cliente-edit.component.scss'
})
export class ClienteEditComponent implements OnInit {

  private formBuilder= inject(FormBuilder);
  private http=inject(HttpClient);
  private msg = inject(AlertService);

  botones: PageButton[];
  loading: boolean;
  submitted:boolean;
  activeTab:string|null="productos"

  title: string = 'Nueva Factura';
  configClient: InputSearchDualModel;
  optionModelProductos: DatatableEditorModel;

  public form: FormGroup;

  constructor(){}

  ngOnInit(): void {
    this._initBotones();
    this._initSearchClient();
    this._initDatatableProductos();

    this.form = this.formBuilder.group({
      cliente: ['', [Validators.required]],
    });

  }

  get f() {
    return this.form.controls;
  }

  private _initBotones(){
    this.botones = [
      {
        label: 'cancelar',
        icon: 'bx bx-block',
        cssClass: 'btn-warning',
        actionType:()=> this._cancelar()
      },
      {
        label: 'guardar',
        icon: 'bx bx-save',
        cssClass: 'btn-info',
        actionType:()=> this._guardar()
      }
    ];
  }

  private _initDatatableProductos(){
    this.optionModelProductos = {
      defaultModel: {
        cantidad: 1,
        precio: '0.00',
        producto: {},
        observacion: '',
        unidad: '',
        subtotal: '0.00',
      },
      columns: [
        {
          title: 'Cant.',
          data: 'cantidad',
          width: 60,
          cell: {
            type: 'input',
            format: 'number',
            required: true,
          },
        },
        {
          title: 'Producto',
          data: 'producto',
          cell: {
            type: 'search',
            required: true,
            searching: {
              title: 'Buscador de productos',
              url: 'producto/search',
              codeName: 'codigo',
              valueName: 'nombre',
              columns: [
                {
                  title: 'Codigo',
                  data: 'codigo',
                  width: '130px',
                },
                {
                  title: 'Nombre',
                  data: 'nombre',
                },
              ],
            },
          },
        },
        {
          title: 'Und',
          data: 'unidad',
          width: 55,
          cell: {
            type: 'select',
            required: true,
            selecting: {
              name: 'TP_UNIDAD_CALCULO',
              valueName: 'id',
              labelName: 'codigo',
            },
          },
        },
        {
          title: 'Iva',
          data: 'unidad',
          width: 55,
          cell: {
            type: 'select',
            required: true,
            selecting: {
              name: 'TP_UNIDAD_CALCULO',
              valueName: 'id',
              labelName: 'codigo',
            },
          },
        },
        
        {
          title: '%',
          data: 'precio',
          width: 50,
          cell: {
            type: 'input',
            readonly:true,
            class: 'text-end',
            format: 'decimal',
          },
          //onChange: this._inputChange,
        },
        {
          title: 'Precio U.',
          data: 'precio',
          width: 60,
          cell: {
            type: 'input',
            required: true,
            class: 'text-end',
            format: 'decimal',
          },
          //onChange: this._inputChange,
        },
        {
          title: 'Desc',
          data: 'precio',
          width: 60,
          cell: {
            type: 'input',
            required: true,
            class: 'text-end',
            format: 'decimal',
          },
          //onChange: this._inputChange,
        },
        {
          title: 'Subtotal',
          data: 'subtotal',
          width: 80,
          cell: {
            type: 'input',
            class: 'text-end',
            readonly: true,
          },
          //type: 'input',
          //readonly: true,
          //class: 'text-end',
        },
      ],
    };
  }

  private _initSearchClient() {
    this.configClient = {
      url: 'persona/search',
      title: 'Buscador de cliente',
      codeName: 'identificacion',
      valueName: 'razon_social',
      columns: [
        {
          title: 'Identificacion',
          data: 'identificacion',
          width: '130px',
        },
        {
          title: 'Nombre',
          data: 'razon_social',
        },
      ],
    };
  }

  private _cancelar(){

  }

  private _guardar(){

  }

  changeTab(event:any){
    this.activeTab = event.id;
  }
}
