import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { ModalSearchEmitter, ModalSearchModel } from './modal-search.model';
import { HttpClient } from '@angular/common/http';
import { ProductEditModalComponent } from '@pages/inventory/products/product-edit/product-edit-modal/product-edit-modal.component';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-search',
  standalone: true,
  imports: [DataTablesModule],
  templateUrl: './modal-search.component.html',
  styleUrl: './modal-search.component.scss',
})
export class ModalSearchComponent implements OnInit, AfterViewInit {
  private http = inject(HttpClient);
  //private renderer = inject(Renderer2);

  
  dtOptions: any = {};
  enabledAplicarButton: boolean = true;
  settings: ModalSearchModel;
  selection: EventEmitter<ModalSearchEmitter> = new EventEmitter();
  change: EventEmitter<any> = new EventEmitter();

  private dt: any;
  private ignoreFirstRequest: boolean = false;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  constructor(
    //private modalRef: BsModalRef,
    private modalRef: BsModalRef,
    public modalService: ModalService
  ) {}

  ngOnInit(): void {
    this._initDatabase();
  }

  ngAfterViewInit(): void {
    const that = this;

    this.datatableElement.dtInstance.then((dtInstance: any) => {
      that.dt = dtInstance;
      $(dtInstance.tables().header()).addClass('table-light');

      $(dtInstance.tables().body()).on('dblclick', 'tr', (event) => {
        //if (!$(event.currentTarget).hasClass('selected-row')) {
        var rowData = dtInstance.row(event.target).data();

        $(event.currentTarget).addClass('selected-row');
        this.enabledAplicarButton = false;
        that._someClickHandler(rowData);
        //}
      });

      $(dtInstance.tables().body()).on('click', 'td', (event) => {
        if (event.detail === 1) {
          const currentRow = $(event.currentTarget).parent('tr');
          if (currentRow.hasClass('selected-row')) {
            currentRow.removeClass('selected-row');
            this.enabledAplicarButton = true;
          } else {
            $(dtInstance.tables().body())
              .find('tr.selected-row')
              .removeClass('selected-row');
            currentRow.addClass('selected-row');
            this.enabledAplicarButton = false;
          }
        }
        //console.log('seleccionado');
      });
    });
  }

  private _initDatabase() {
    //console.log('data-> ',this.settings.search);

    this.ignoreFirstRequest = (this.settings.data?.rows || []).length > 0;

    this.dtOptions = {
      pagingType: 'full_numbers',
      lengthMenu: [5, 10, 15, 20, 25],
      lengthChange: true,
      serverSide: true,
      pageLength: this.settings.pageLength || 5,
      data: [], //this.settings.data || [],
      processing: false,
      language: {
        paginate: {
          first: '<i class="bx bx-chevron-left"></i>',
          last: '<i class="bx bx-chevron-right"></i>',
          next: '<i class="bx bx-chevrons-right"></i>',
          previous: '<i class="bx bx-chevrons-left"></i>',
        },
        emptyTable: 'No hay datos disponibles',
        info: 'Visualizando del _START_ hasta el _END_ de _TOTAL_ registros',
        infoEmpty: '0 registros',
        lengthMenu: 'Mostrar _MENU_ registros',
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      },
      dom: "<'row'<'col-sm-6'l><'col-sm-6 tbfB'fB>><'row'<'col-sm-12'tr>><'row'<'col-sm-5'i><'col-sm-7'p>>",
      columns: this.settings.columns?.map((it) => {
        const row: any = { title: it.title, data: it.data };
        if (it.width) {
          row.width = it.width;
        }
        if (it.render) {
          row.render = it.render;
        }
        return row;
      }),
      responsive: false,
      paging: true,
      info: true,
      scrollX: this.settings.scrollx,
      scrollY: this.settings.scrolly,
      screenX: true,
      screenY: true,
      search: {
        search: this.settings.search,
        return: true,
      },
      drawCallback: () => {
        $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
      },
    };

    this._serverSide();
  }

  private _someClickHandler(data: any) {
    setTimeout(() => {
      this.modalRef.hide();
      this.selection.emit({
        index: this.settings.index,
        data,
        action: this.settings.action,
      });
    }, 10);
  }

  private _serverSide() {
    this.dtOptions.ajax = (params: any, callback: any) => {
      if (this.ignoreFirstRequest) {
        const data = this.settings.data;
        if (data) {
          //console.log('--->',data)
          this.ignoreFirstRequest = false;
          callback({
            recordsTotal: data.totalRows,
            recordsFiltered: data.totalRows,
            data: data.rows,
          });
        }
      } else {
        let sortBy = '';
        let sortDirection = '';

        //Orden
        if (params.order) {
          sortBy = params.columns[params.order[0].column].data;
          sortDirection = params.order[0].dir;
        }

        let sparams: any = [];
        var value: any = undefined;

        const page = params.start / params.length + 1;
        const auxUrl = this.settings.url?.split('?') || [];
        var url = this.settings.url || '';
        if (auxUrl.length > 1) {
          url = auxUrl[0];
        }

        var pdata: any = {
          page: page,
          pageSize: params.length,
          search: params.search.value || null,
          sortBy: '',
          sortDirection: '',
          ignoreFields: this.settings?.ignoreFields || null,
        };

        if (this.settings.url && this.settings.url.length > 0) {
          this.enabledAplicarButton = true;
          this.http.post(url, pdata).subscribe({
            next: (data: any) => {
              callback({
                recordsTotal: data.totalRows,
                recordsFiltered: data.totalRows,
                data: data.rows,
              });
            },
            error: (err) => {
              console.log('errores', err);
            },
          });
        }
      }
    };
  }

  close() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  agregar() {
    this.change.emit(null);
  }

  reload(){
    setTimeout(() => {
      this.dt.ajax.reload();
    }, 1);
  }

  aplicar() {
    if (this.dt) {
      const selectedRow = this.dt.row('.selected-row').nodes()[0];
      const rowData = this.dt.row(selectedRow).data();
      if (rowData) {
        this._someClickHandler(rowData);
        //this.modalRef.hide();
      }
    }
  }
}
