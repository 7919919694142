<app-spinner [loading]="loading"></app-spinner>
<form [formGroup]="form" autocomplete="off">
  <div class="row">
    <div class="col-md-6 mb-1">
      <label for="i-tipo-Identificacion" class="form-label">Tipo *</label>
      <app-select-catalog
        [size]="'sm'"
        [ngClass]="{
          'is-invalid': submitted && f.tipoIdentificacion.errors
        }"
        #cmbTipoIdentificacion
        (changeValue)="changeTipoIdentificacion($event)"
      ></app-select-catalog>
      <div
        *ngIf="submitted && f.tipoIdentificacion.errors"
        class="invalid-feedback"
      >
        <span *ngIf="f.tipoIdentificacion.errors.required"
          >Tipo de identificacion es requerido</span
        >
      </div>
    </div>
    <div class="col-md-6 mb-1">
      <label for="i-identificacion" class="form-label">Identificacion *</label>
      <input
        numbersOnly
        [allowDecimals]="false"
        type="text"
        [ngClass]="{
          'is-invalid': submitted && f.identificacion.errors
        }"
        toUppercase
        formControlName="identificacion"
        class="form-control fomr form-control-sm"
        placeholder="N: Ruc/Cedula/Pasaporte"
        id="i-identificacion"
        [maxlength]="maxlengthIdentificacion"
      />
      <div
        *ngIf="submitted && f.identificacion.errors"
        class="invalid-feedback"
      >
        <span *ngIf="f.identificacion.errors.required"
          >Identificacion es requerido</span
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mb-1">
      <label for="i-razon-social" class="form-label">Razon Social *</label>
      <input
        type="text"
        [ngClass]="{
          'is-invalid': submitted && f.razonSocial.errors
        }"
        toUppercase
        formControlName="razonSocial"
        class="form-control form-control-sm"
        placeholder="Nombre/Razon Social"
        id="i-razon-social"
        maxlength="200"
      />
      <div *ngIf="submitted && f.razonSocial.errors" class="invalid-feedback">
        <span *ngIf="f.razonSocial.errors.required"
          >Razon social es requerido</span
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 mb-1">
      <label for="i-telefono" class="form-label">Telefono *</label>
      <input
        type="text"
        numbersOnly
        [allowDecimals]="false"
        [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }"
        toUppercase
        formControlName="telefono"
        class="form-control form-control-sm"
        placeholder="Telefono: 032222222"
        id="i-telefono"
        maxlength="15"
      />
      <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback">
        <span *ngIf="f.telefono.errors.required">Telefono es requerido</span>
      </div>
    </div>
    <div class="col-md-6 mb-1">
      <label for="i-movil" class="form-label">Celular </label>
      <input
        numbersOnly
        [allowDecimals]="false"
        type="text"
        [ngClass]="{ 'is-invalid': submitted && f.movil.errors }"
        toUppercase
        formControlName="movil"
        class="form-control form-control-sm"
        placeholder="Celular: 0999999999"
        id="i-movil"
        maxlength="15"
      />
      <div *ngIf="submitted && f.movil.errors" class="invalid-feedback">
        <span *ngIf="f.movil.errors.required">Movil es requerido</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mb-1">
      <label for="i-direccion" class="form-label">Direccion *</label>
      <input
        type="text"
        [ngClass]="{ 'is-invalid': submitted && f.direccion.errors }"
        toUppercase
        formControlName="direccion"
        class="form-control form-control-sm"
        placeholder="Direccion"
        id="i-direccion"
        maxlength="250"
      />
      <div *ngIf="submitted && f.direccion.errors" class="invalid-feedback">
        <span *ngIf="f.direccion.errors.required">Direccion es requerido</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mb-1">
      <label for="f-mail-input" class="form-label">Correo electronico *</label>
      <input
        type="text"
        [ngClass]="{ 'is-invalid': submitted && f.correo.errors }"
        formControlName="correo"
        class="form-control form-control-sm"
        placeholder="Mail: info@mail.com"
        id="f-mail-input"
        maxlength="100"
      />
      <div *ngIf="submitted && f.correo.errors" class="invalid-feedback">
        <span *ngIf="f.correo.errors.required">Correo es requerido</span>
        <span *ngIf="f.correo.errors.email"
          >Este valor debe ser un correo electrónico válido</span
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 mb-1">
      <label for="i-tipo-pro-reembolso" class="form-label"
        >Tipo proveedor para reembolzo *</label
      >
      <app-select-catalog
        [size]="'sm'"
        [ngClass]="{
          'is-invalid': submitted && f.tipoProReembolso.errors
        }"
        #cmbTipoProReembolso
        (changeValue)="changeTipoRembolso($event)"
      ></app-select-catalog>
      <div
        *ngIf="submitted && f.tipoProReembolso.errors"
        class="invalid-feedback"
      >
        <span *ngIf="f.tipoProReembolso.errors.required"
          >Tipo de Reembolso es requerido</span
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <div class="mb-1">
        <label for="i-cont-esp" class="form-label">Con. Especial</label>
        <div class="form-check form-switch form-switch-md mt-1">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="ckeckContribuyenteEspecial"
          />
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="mb-1">
        <label for="i-extrajero" class="form-label">Extranjero</label>
        <div class="form-check form-switch form-switch-md mt-1">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="ckeckExtranjero"
          />
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="mb-1">
        <label for="i-nombre" class="form-label">Cliente</label>
        <div class="form-check form-switch form-switch-md mt-1">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="ckeckCliente"
          />
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="mb-1">
        <label for="i-nombre" class="form-label">Proveedor</label>
        <div class="form-check form-switch form-switch-md mt-1">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="ckeckProveedor"
          />
        </div>
      </div>
    </div>
  </div>
</form>
