import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import {
  LoginResponse,
  Menu,
} from '../interfaces';
import {
  Observable,
  catchError,
  map,
  switchMap,
  throwError,
} from 'rxjs';
import { sha512 } from 'js-sha512';
import { AuthUtilService } from './auth-util.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private authUtils: AuthUtilService,

  ) {
    this.authUtils.checkAuthStatus().subscribe();
  }

  public currentUser = computed(() => this.authUtils.currentUser);
  public authStatus = computed(() => this.authUtils.authStatus);


  login(username: string, password: string): Observable<boolean> {
    const strpassword = sha512.create().update(password).hex();

    return this.http
      .post<LoginResponse>('seguridad/signin', {
        username,
        password: strpassword,
      })
      .pipe(
        switchMap(({ user, token }) => {
          this.authUtils.cookie.setToken(token);
          return this.http
            .get<Menu[]>('seguridad/menus')
            .pipe(
              map((menus) => {
                return this.authUtils.setAuthentication(user, menus);
              }),
              catchError((err) => {
                this.authUtils.logout();
                return throwError(() => err); //of(false); // Puedes cambiar esto según tus necesidades
              })
            );
        }),
        catchError((err) => {
          this.authUtils.logout();
          return throwError(() => err);
        })
      );
  }

  setCurrentUrl(url:string):void{
    this.authUtils.cookie.setCurrentUrl(url);
  }

  getCurrentUrl():string{
    return this.authUtils.cookie.getCurrentUrl()|| "/";
  }

  logout() {
    this.authUtils.logout();
  }

  
}
