import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent, ViewerComponent } from '@components/index';
import { PageButtonsComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-formula-list',
  standalone: true,
  imports: [DatatableComponent, PageButtonsComponent],
  templateUrl: './formula-list.component.html',
  styleUrl: './formula-list.component.scss',
})
export class FormulaListComponent implements OnInit {
  private router=inject(Router);

  botones: PageButton[];
  title: string = 'Listar Formulas';
  optionModel = {};
  

  constructor(private modalViewer: BsModalService) {}

  ngOnInit(): void {
    this._initBotones();
    this._initDatatable();
  }

  private _initBotones() {
    this.botones = [
      {
        label: 'agregar',
        icon: 'bx bx-plus',
        cssClass: 'btn-success',
        actionType: () => this._add(),
      },
    ];
  }

  private _initDatatable() {
    var columns: any = [
      {
        className: 'col--opt-2 no-sort',
        title: '...',
        buttons: [
          {
            icon: 'fas fa-pencil-alt',
            onclick:(row:any)=>this._edit(row.id)
          },
          {            
            icon:"fas fa-print",            
            onclick:(row:any)=> this._print(row.id)
          },
          {
            className: 'btn-outline-danger',
            icon: 'far fa-trash-alt',
            onclick: (row: any) => {},
          },
        ],
      },
      {
        title: 'Nombre',
        data: 'nombre',
      },
      {
        title: 'Acabado',
        data: 'acabado',
      },
      {
        title: 'Codigo',
        data: 'codigo',
      },
      {
        title: 'Fecha Creacion',
        data: 'fecha_creacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
      {
        title: 'Fecha Modificacion',
        data: 'fecha_modificacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
    ];

    this.optionModel = {
      filtering: false,
      searching: true,
      scrollX: true,
      serverSide: true,
      columns: columns,
      url: 'formula/listar',
    };
  }

  private _print(id:string){
    this.modalViewer.show(ViewerComponent, {
      class: 'modal-dialog-centered modal-xl',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState:{
        url:`formula/reporte/${id}`
      }
    });   
  
  }

  private _edit(id:string){
    this.router.navigate([`administration/formula/edit/${id}`]);
  }

  private _add() {
    this.router.navigate(['administration/formula/add']); 
  }
}
