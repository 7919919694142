<form [formGroup]="form" autocomplete="off">
    <app-spinner [loading]="loading"></app-spinner>
    <div class="p-3 mb-0">
      <div class="row mb-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Ruc:</label>
        </div>
        <div class="col-md-7">
          <input
            numbersOnly
            [allowDecimals]="false"
            class="form-control form-control-sm"
            formControlName="ruc"
            readonly="true"
          />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Razón social:</label>
        </div>
        <div class="col-md-7">
          <input
            class="form-control form-control-sm"
            formControlName="razonSocial"
          />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Nombre comercial:</label>
        </div>
        <div class="col-md-7">
          <input
            class="form-control form-control-sm"
            formControlName="nombreComercial"
          />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Número de establecimiento:</label>
        </div>
        <div class="col-md-7">
          <div class="input-group input-group-sm">
            <input
              numbersOnly
              [allowDecimals]="false"
              class="form-control form-control-sm"
              formControlName="numeroEstablecimiento"
            />
            <button class="btn" type="button" (click)="addorEditEstablecimiento()"><i class="bx bx-edit-alt"></i></button>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Obligado a llevar contabilidad:</label>
        </div>
        <div class="col-md-7">
          <div class="form-check form-switch mb-0">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="obligadoLlevarContabillidad"
            />
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Contribuyente especial:</label>
        </div>
        <div class="col-md-1">
          <div class="form-check form-switch mb-0">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="contribuyenteEspecial"
            />
          </div>
        </div>
        @if(form.value.contribuyenteEspecial){
        <div class="col-md-2">
          <input
            type="text"
            maxlength="13"
            class="form-control form-control-sm"
            placeholder="Numero"
          />
        </div>
        }
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Exportador:</label>
        </div>
        <div class="col-md-7">
          <div class="form-check form-switch mb-0">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="exportador"
            />
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Contribuyente régimen RIMPE:</label>
        </div>
        <div class="col-md-7">
          <div class="form-check form-switch m-0">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="contribuyenteRimpe"
            />
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Agente de retención:</label>
        </div>
        <div class="col-md-7">
          <div class="form-check form-switch m-0">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="agenteRetencion"
            />
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Ciudad:</label>
        </div>
        <div class="col-md-7">
          <input class="form-control form-control-sm" formControlName="ciudad" />
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Telefono:</label>
        </div>
        <div class="col-md-7">
          <input
            numbersOnly
            [allowDecimals]="false"
            class="form-control form-control-sm"
            formControlName="telefono"
          />
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Dirección:</label>
        </div>
        <div class="col-md-7">
          <textarea
            class="form-control form-control-sm"
            rows="2"
            formControlName="direccion"
          ></textarea>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Actividad económica:</label>
        </div>
        <div class="col-md-7">
          <input
            class="form-control form-control-sm"
            formControlName="actividadEconomica"
          />
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label for="f-nombre-input">Num. Decimales:</label>
        </div>
        <div class="col-md-7">
          <input
            numbersOnly
            [allowDecimals]="false"
            class="form-control form-control-sm"
            formControlName="numeroDecimal"
          />
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-3">
          <label class="mb-0" for="f-nombre-input">Logo de la empresa:</label
          ><br />
          <small>(Ancho y alto máximo:500x300px)</small>
          <small>Formato permitido jpg, jpeg, png</small>
        </div>
        <div class="col-md-7"></div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12">
      <button
        type="button"
        class="btn-info btn btn-primary btn-sm"
        (click)="guardar()"
      >
        <i class="bx bx-save font-size-16 align-middle me-2"></i>
        guardar
      </button>
    </div>
  </div>
  
