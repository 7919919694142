import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { BsSidebarRef } from '../../../../core/models/sidebar-ref';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SelectCatalogComponent } from '@components/index';
import { HttpClient } from '@angular/common/http';
import { SpinnerComponent } from '@shared/ui';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-catalog-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SelectCatalogComponent,
    SpinnerComponent,
  ],
  templateUrl: './catalog-edit.component.html',
  styleUrl: './catalog-edit.component.scss',
})
export class CatalogEditComponent implements OnInit {
  private formBuilder = inject(FormBuilder);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  form: FormGroup;
  submitted: boolean = false;
  loading: boolean = true;
  title: string = 'Nuevo';

  settings: any;
  data: string;

  emitter: EventEmitter<any> = new EventEmitter();

  private listaCatalogosPadres: Array<any> = [];
  private catalogoId: string;

  @ViewChild('cmbTipoCatalogoDependencia') cmbTipoCatalogoDependencia: any;

  constructor(private sidebar: BsSidebarRef) {}

  ngOnInit(): void {
    const { settings } = this.sidebar;

    //console.log('sidebar model -> ',this.sidebar, settings);

    if (settings?.id) {
      this.title = 'Actualizar';
      this.catalogoId = settings.id;
      this._obtener(this.catalogoId);
    } else {
      this._obtener();
    }

    this.form = this.formBuilder.group({
      nemonico: ['', [Validators.required]],
      descripcion: ['', [Validators.required]],
      tipoCatalogoDependencia: ['', null],
      checkDependenciaCatalogo: [false, null],
      checkCatalogoInterno: [false, null],
      checkRequiereCodigoDetalle: [false, null],
      checkRequiereValorDetalle: [false, null],
    });
  }

  get f() {
    return this.form.controls;
  }

  get tipoCatalogoDependencia() {
    return this.form.get('tipoCatalogoDependencia');
  }

  changeCheckbox(event:any){
    const {name,checked} = event.target;
    this.form.patchValue({
      [name]: checked,
    });
  }

  public changeTipoCatalogoDependencia(evt: any) {}

  public changeAplicaDependencia(evt: any) {
    const checked = evt.target.checked;

    this.form.patchValue({
      checkDependenciaCatalogo: checked,
    });

    if (checked) {
      this._loadCatalogoPadre();
    } else {
      this.tipoCatalogoDependencia?.clearValidators();
      this.tipoCatalogoDependencia?.updateValueAndValidity();
    }
  }

  private _obtener(id: string | undefined = undefined) {
    this.http.get(id ? `catalogo/${id}` : 'catalogo').subscribe({
      next: (data: any) => {
        //console.log(data);
        let aplicaDependenciaCatalogo = false;

        if (data.model) {
          const {nemonico,descripcion,tipoCatalogoPadre,dependiente,aplicaCatalogoInterno,requiereCodigoDetalle,requiereValorDetalle} = data.model;
          this.form.patchValue({
            nemonico: nemonico,
            descripcion: descripcion,
            tipoCatalogoDependencia: tipoCatalogoPadre,
            checkDependenciaCatalogo: dependiente,
            checkCatalogoInterno:aplicaCatalogoInterno,
            checkRequiereCodigoDetalle:requiereCodigoDetalle,
            checkRequiereValorDetalle:requiereValorDetalle
          });

          aplicaDependenciaCatalogo = dependiente;
        }

        if (data.catalogos && data.catalogos.length > 0) {
          this.listaCatalogosPadres = data.catalogos;
          if (aplicaDependenciaCatalogo) {
            this._loadCatalogoPadre();
          }
        }
        this.loading = false;
      },
      error: (error) => (this.loading = false),
    });
  }

  private _loadCatalogoPadre() {
    var that = this;

    setTimeout(function () {
      const _f = that.form.value;

      if (_f.tipoCatalogoDependencia) {
        that.cmbTipoCatalogoDependencia.setSelectedItem(
          that.listaCatalogosPadres.find(
            (it: any) => it.id === _f.tipoCatalogoDependencia
          )
        );
      }

      that.cmbTipoCatalogoDependencia.setItems(that.listaCatalogosPadres);

      that.submitted = false;
      that.tipoCatalogoDependencia?.clearValidators();
      that.tipoCatalogoDependencia?.setValidators([Validators.required]);
      that.tipoCatalogoDependencia?.updateValueAndValidity();
    }, 1);
  }

  private _actualizar(model: any) {
    this.loading = true;
    this.http.put('catalogo', model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.form.reset();
          this.submitted = false;

          this.sidebar.close();
          this.msg.growl.success(`Catalogo actualizado con éxito`);
          this.emitter.emit(true);
        }
      },
      error: (error) => (this.loading = false),
    });
  }

  private _registrar(model: any) {
    this.loading = true;
    this.http.post('catalogo', model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.form.reset();
          this.submitted = false;

          this.sidebar.close();
          this.msg.growl.success(`Catalogo registrado con éxito`);
          this.emitter.emit(true);
        }
      },
      error: (error) => (this.loading = false),
    });
  }

  public guardar() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const _f = this.form.value;
    if (this.catalogoId) {
      this._actualizar({
        id: this.catalogoId,
        nemonico: _f.nemonico,
        descripcion: _f.descripcion,
        aplicaDependencia: _f.checkDependenciaCatalogo,
        idCatalogoPadre: _f.tipoCatalogoDependencia,
        aplicaCatalogoInterno:_f.checkCatalogoInterno,
        aplicaCodigoDetalle:_f.checkRequiereCodigoDetalle,
        aplicaValorDetalle:_f.checkRequiereValorDetalle,
      });
    } else {
      this._registrar({
        nemonico: _f.nemonico,
        descripcion: _f.descripcion,
        aplicaDependencia: _f.checkDependenciaCatalogo,
        idCatalogoPadre: _f.tipoCatalogoDependencia,
        aplicaCatalogoInterno:_f.checkCatalogoInterno,
        aplicaCodigoDetalle:_f.checkRequiereCodigoDetalle,
        aplicaValorDetalle:_f.checkRequiereValorDetalle,
      });
    }
  }
}
