import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { PersonEditViewComponent } from '../person-edit-view/person-edit-view.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-person-edit-modal',
  standalone: true,
  imports: [
    CommonModule,
    PersonEditViewComponent
  ],
  templateUrl: './person-edit-modal.component.html',
  styleUrl: './person-edit-modal.component.scss'
})
export class PersonEditModalComponent implements OnInit{
  title:string="Agregar persona"
  
  
  @Input() editMode: boolean;
  @Input() personId: string|null=null;
  
  @ViewChild('cp_personview') personView: any;
  onSuccess: EventEmitter<any> = new EventEmitter();

  constructor(private modalRef: BsModalRef){}

  ngOnInit(): void {
    console.log('ngOnInit',this.editMode, this.personId);
    if(this.personId!==null) this.title="Editar persona";
  }

  close(){
    if(this.modalRef) this.modalRef.hide();
  }

  guardar(){
    if(this.personView){
      this.personView.guardar();
    }
  }

  onSaveSuccesss(){
    this.close();
    this.onSuccess.emit();
  }
}
