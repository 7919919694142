import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@components/index';
import { AlertService } from '@services/alert.service';
import { PageButtonsComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import moment from 'moment';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [DatatableComponent, PageButtonsComponent],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
})
export class UserListComponent implements OnInit {
  private router=inject(Router);
  private msg = inject(AlertService);
  private http = inject(HttpClient);

  botones: PageButton[];
  title: string = 'Listar Usuarios';
  

  @ViewChild(DatatableComponent) dt: DatatableComponent;
  optionModel = {};

  ngOnInit(): void {
    this._initBotones();
    this._initDatatable();
  }

  private _initBotones() {
    this.botones = [
      {
        label: 'agregar',
        icon: 'bx bx-plus',
        cssClass: 'btn-success',
        actionType: () => this._add(),
      },
    ];
  }

  private _initDatatable() {
    var columns: any = [
      {
        className: 'col--opt-2 no-sort',
        title: '...',
        buttons: [
          {
            icon: 'fas fa-pencil-alt',
            onclick: (row: any) => this._edit(row.id),
          },
          {
            className: 'btn-outline-danger',
            icon: 'far fa-trash-alt',
            onclick: (row: any) => this._delete(row),
          },
        ],
      },
      {
        title: 'Nombre',
        data: 'nombre',
      },
      {
        title: 'Correo',
        data: 'correo',
      },
      {
        title: 'Usuario',
        data: 'usuario',
      },
      {
        title: 'Fecha Creacion',
        data: 'fecha_creacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
      {
        title: 'Fecha Modificacion',
        data: 'fecha_modificacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
    ];

    this.optionModel = {
      filtering: false,
      searching: true,

      serverSide: true,
      columns: columns,
      url: 'usuario/listar',
    };
  }

  private _add() {
    this.router.navigate([`administration/user/add`]);
  }

  private _edit(id: any) {
    this.router.navigate([`administration/user/edit/${id}`]);
  }

  private _delete(data: any) {
    this.msg.confirm(`<p>¿Estás seguro de que deseas eliminar al usuario <strong>${data.nombre}</strong>?</p><p>Esta acción es irreversible y Esta acción eliminará todas las sesiones asociadas a este usuario.</p>`)
    .then(({ value }) => {
      if (value) {
        this.http.delete(`usuario/${data.id}`).subscribe((data:any)=>{
          if(data && data.exito){
            this.msg.growl.success(`Usuario ${data.nombre} eliminado con éxito`);
            this.dt.reload();
          }      
        });
      }
    });
  }
}
