import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent, ViewerComponent } from '@components/index';
import { PageButtonsComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [DatatableComponent,PageButtonsComponent],
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.scss'
})
export class OrderListComponent implements OnInit {
  
  private router=inject(Router);

  botones: PageButton[];
  title: string = 'Listar Pedidos';
  optionModel = {};

  constructor(private modalViewer: BsModalService) {}

  ngOnInit(): void {
    this._initBotones();
    this._initDatatable();
  }

  private _initBotones(){
    this.botones = [
      {
        label:"agregar",
        icon:"bx bx-plus",
        cssClass:"btn-success",
        actionType:()=>this._add()
      },
    ]
  }

  private _initDatatable(){
    var columns:any = [
      { 
        className:'col--opt-2 no-sort', 
        title:'...', 
        buttons:[
          
          {
            icon:"fas fa-pencil-alt",            
            onclick:(row:any)=>this._edit(row.id)
          },
          {            
            icon:"fas fa-print",            
            onclick:(row:any)=> this._print(row.id)
          },
          {            
            className: 'btn-outline-danger',
            icon:"far fa-trash-alt",            
            onclick:(row:any)=>{              
            }
          },
          
        ]              
      },
      {
        title: 'Numero',
        data: 'numero'
      },
      {
        title:'Estado',
        data: 'estado',
        orderable: false,
        searchable: false,
        render:(data:any,type:any)=>{
          var porc = (data*100)/4;
          return type==='display'? `<div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style="width: ${porc}%;"
            aria-valuenow="${porc}" aria-valuemin="0" aria-valuemax="40">
          </div>
        </div>`:'';
        }
      },      
      {
        title:'Identificacion',
        data: 'identificacion'
      }, 
      {
        title:'Cliente',
        data: 'razonSocial'
      }, 
      {
        title: 'Fecha Emision',
        data: 'fechaEmision',
        render:(data: any)=>{
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm')
          return local;
        }
      },    
      {
        title: 'Fecha Entrega',
        data: 'fechaEntrega',
        render:(data: any)=>{
          var local='-'
          if(data){
            var stillUtc = moment.utc(data).toDate();
            local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm')
          }
          return local;
        }
      },     
      {
        title: 'Fecha Validez',
        data: 'fechaValidez',
        render:(data: any)=>{
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm')
          return local;
        }
      },    
      
               
      
    ]

    this.optionModel ={      
      filtering:false,
      searching:true,
      
      serverSide:true,
      columns: columns,
      url:"pedido/listar",  
    };
  }

  private _print(id:string){

    this.modalViewer.show(ViewerComponent, {
      class: 'modal-dialog-centered modal-xl',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState:{
        url:`pedido/reporte/${id}`
      }
    });   
  
  }

  private _add(){
    this.router.navigate(['inventory/order/add']); 
  }

  private _edit(id:string){
    this.router.navigate([`inventory/order/edit/${id}`]); 
  }

}
