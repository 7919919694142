import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputFileComponent, SelectCatalogComponent } from '@components/index';
import { AlertService } from '@services/alert.service';
import { SpinnerComponent } from '@shared/ui';
import moment from 'moment';

@Component({
  selector: 'app-company-factura-electronica',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputFileComponent,
    SpinnerComponent,
    SelectCatalogComponent,
  ],
  templateUrl: './company-factura-electronica.component.html',
  styleUrl: './company-factura-electronica.component.scss',
})
export class CompanyFacturaElectronicaComponent implements OnInit {
  private msg = inject(AlertService);
  private http = inject(HttpClient);
  private formBuilder = inject(FormBuilder);

  form: FormGroup;
  submitted: boolean;
  loading: boolean;
  firmaSubida: boolean;
  editarClave: boolean = false;
  nombreArchivo: string;

  private catalogoTipoAmbiente: any[] = [];

  @ViewChild('firmaElectronica') firmaElectronica: any;
  @ViewChild('cmbTipoAmbiente') cmbTipoAmbiente: any;

  ngOnInit(): void {
    //console.log('CompanyFacturaElectronicaComponent implemented.');
    this.form = this.formBuilder.group(
      {
        firmaElectronica: [null, [Validators.required]],
        firmaClave: ['', [Validators.required]],
        firmaClaveConfirmacion: ['', [Validators.required]],
        fechaCaducidad: ['', null],
        tipoAmbiente: [null, null],
      },
      { validators: this.passwordMatchValidator }
    );

    this._obtener();
  }

  get f() {
    return this.form.controls;
  }

  private _init() {
    this.form.patchValue({
      firmaElectronica: null,
      firmaClave: '',
      firmaClaveConfirmacion: '',
      fechaCaducidad: '',
      tipoAmbiente: null,
    });
  }

  private passwordMatchValidator(group: FormGroup): Validators | null {
    const passwordControl = group.get('firmaClave');
    const confirmPasswordControl = group.get('firmaClaveConfirmacion');

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    const password = passwordControl.value;
    const confirmPassword = confirmPasswordControl.value;

    if (password.length > 0 && confirmPassword.length > 0) {
      if (password !== confirmPassword) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      }
    }

    return null;
  }

  private _registrar(model: any) {
    this.loading = true;
    this.http
      .post('company/configuration/electronicSignature', model)
      .subscribe({
        next: (data: any) => {
          const { exito, mensaje } = data;
          if (!exito) this.msg.error(mensaje);
          else {
            this.msg.growl.success('Firma electronica subida');
            //this._init();
            this._obtener();
          }
          //console.log("guardado", data);
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          console.log(err);
          //this.msg.error(err)
        },
      });
  }

  private _obtener() {
    this.loading = true;

    this.http.get('company/configuration/firmaElectronica').subscribe({
      next: (data: any) => {
        const { model, catalogos } = data;

        if (model) {
          this.nombreArchivo = model.nombreArchivo;
          this.firmaElectronica.fileName = this.nombreArchivo;
          this.firmaSubida = model.tokenSubido;

          if (this.firmaSubida) {
            this.clearValidationFirmaElectronica();
          }

          var stillUtc = moment.utc(model.fechaCaducidad).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');

          this._init();
          this.form.patchValue({ fechaCaducidad: local });
        }

        if (catalogos && catalogos.TP_FACTELEC_AMB) {
          const ctg = (this.catalogoTipoAmbiente = catalogos.TP_FACTELEC_AMB);
          if (model && model.tipoAmbiente) {
            this.cmbTipoAmbiente.setSelectedItem(
              ctg.find((it: any) => it.id === model.tipoAmbiente)
            );
          }
          this.cmbTipoAmbiente.setItems(ctg);
        }

        this.loading = false;
      },
      error: (error) => (this.loading = false),
    });
  }

  private _editar(model: any) {
    this.loading = true;
    this.http
      .put('company/configuration/electronicSignature', model)
      .subscribe({
        next: (data: any) => {
          const { exito, mensaje } = data;
          if (!exito) this.msg.error(mensaje);
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          console.log(err);
        },
      });
  }

  onEditarClave() {
    this.editarClave = true;
    this.submitted = false;
    this.addValidationEditFirmaElectronica();
  }

  onCancelarEditarClave() {
    this.editarClave = false;
    this.submitted = false;
    this.form.patchValue({
      firmaClave: '',
      firmaClaveConfirmacion: '',
    });
    this.clearValidationEditFirmaElectronica();
  }

  changeTipoAmbiente(event: any) {
    this.form.patchValue({
      tipoAmbiente: event.value,
    });
  }

  private clearValidationFirmaElectronica() {
    this.form.controls['firmaElectronica'].clearValidators();
    this.form.controls['firmaClave'].clearValidators();
    this.form.controls['firmaClaveConfirmacion'].clearValidators();

    this.form.controls['firmaElectronica'].updateValueAndValidity();
    this.form.controls['firmaClave'].updateValueAndValidity();
    this.form.controls['firmaClaveConfirmacion'].updateValueAndValidity();
  }

  private clearValidationEditFirmaElectronica() {
    this.form.controls['firmaClave'].clearValidators();
    this.form.controls['firmaClaveConfirmacion'].clearValidators();

    this.form.controls['firmaClave'].updateValueAndValidity();
    this.form.controls['firmaClaveConfirmacion'].updateValueAndValidity();
  }

  private addValidationEditFirmaElectronica() {
    this.form.controls['firmaClave'].setValidators([Validators.required]);
    this.form.controls['firmaClaveConfirmacion'].setValidators([
      Validators.required,
    ]);

    this.form.controls['firmaClave'].updateValueAndValidity();
    this.form.controls['firmaClaveConfirmacion'].updateValueAndValidity();
  }

  onChangeFile(file: any) {
    //console.log('change f',file);
    this.firmaSubida = false;
    this.form.controls['firmaElectronica'].setValidators([Validators.required]);
    this.form.controls['firmaElectronica'].updateValueAndValidity();

    this.form.patchValue({ firmaElectronica: file });
    if (file) {
      this.editarClave = false;
      this.addValidationEditFirmaElectronica();
    }
  }

  guardar() {
    console.log(this.editarClave);

    this.submitted = true;
    if (this.form.invalid) {
      if (this.f.firmaClaveConfirmacion.errors?.passwordMismatch)
        this.msg.growl.error('Las contraseñas no coinciden.');
      return;
    }

    const frm = this.form.value;
    let model: any = {
      tipoAmbiente: frm.tipoAmbiente,
    };

    //console.log(this.editarClave);

    if (this.editarClave) {
      //console.log(model);
      model = {
        ...model,
        password: frm.firmaClave,
        passwordConfirm: frm.firmaClaveConfirmacion,
      };
      
      console.log(model);
      this._editar(model);   

    } else if (!this.firmaSubida && frm.firmaElectronica) {

      model = {
        ...model,
        password: frm.firmaClave,
        passwordConfirm: frm.firmaClaveConfirmacion,
      };
      
      const formData = new FormData();
      formData.append(
        'firmaElectronica',
        frm.firmaElectronica
      );

      formData.append(
        'model',
        new Blob(
          [
            JSON.stringify(model),
          ],
          {
            type: 'application/json',
          }
        )
      );

      console.log('subir nuevamente token',model);
      this._registrar(formData); 

    } else {
      console.log(model);
      this._editar(model);      
    }

    /*if (!this.editarClave) {
      const frm = this.form.value;

      if (!frm.firmaElectronica) {
        this.msg.growl.error('Se requiere que cargue una firma electronica');
        return;
      }

      const formData = new FormData();
      formData.append(
        'firmaElectronica',
        !this.firmaSubida ? frm.firmaElectronica : null
      );

      formData.append(
        'model',
        new Blob(
          [
            JSON.stringify({
              password: frm.firmaClave,
              passwordConfirm: frm.firmaClaveConfirmacion,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      );

      this._registrar(formData);
    } else {
      

      if (this.editarClave) {
        model = {
          ...model,
          password: frm.firmaClave,
          passwordConfirm: frm.firmaClaveConfirmacion,
        };
        
      } 
      console.log(model);

      //this._editar(model);
    }*/
  }
}
