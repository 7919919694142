import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { DatatableComponent } from '@components/index';
import moment from 'moment';
import { PageButtonsComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [DatatableComponent, PageButtonsComponent],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss',
})
export class ProductListComponent implements OnInit {
  private router = inject(Router);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  botones: PageButton[];
  title: string = 'Listar Productos';
  optionModel = {};

  @ViewChild(DatatableComponent) dt: DatatableComponent;

  ngOnInit(): void {
    this._initBotones();
    this._initDatatable();
  }

  private _initBotones() {
    this.botones = [
      {
        label: 'agregar',
        icon: 'bx bx-plus',
        cssClass: 'btn-success',
        actionType: () => this._add(),
      },
    ];
  }

  private _initDatatable() {
    var columns: any = [
      {
        className: 'col--opt-2 no-sort',
        title: '...',
        buttons: [
          {
            icon: 'fas fa-pencil-alt',
            title: 'editar',
            onclick: (row: any) => this._edit(row.id),
          },
          {
            className: 'btn-outline-danger',
            title: 'eliminar',
            icon: 'far fa-trash-alt',
            onclick: (row: any) => {
              this.msg
                .confirm(
                  `<p>¿Estás seguro de que deseas eliminar la producto <strong>${row.nombre}</strong>?</p><p>Esta acción es irreversible.</p>`
                )
                .then((result) => {
                  if (result.value) {
                    this._delete(row.id, row.nombre);
                  }
                });
            },
          },
        ],
      },
      {
        title: 'Tipo',
        data: 'tipoProducto',
      },
      {
        title: 'Nombre',
        data: 'nombre',
      },
      {
        title: 'Codigo',
        data: 'codigo',
      },
      {
        title: 'P.Terminado',
        data: 'productoTerminado',
        render: (data: any) => {
          return data ? 'SI' : 'NO';
        },
      },
      {
        title: 'Fecha Creacion',
        data: 'fecha_creacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
      {
        title: 'Fecha Modificacion',
        data: 'fecha_modificacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
    ];

    this.optionModel = {
      filtering: false,
      searching: true,

      serverSide: true,
      columns: columns,
      url: 'producto/listar',
      //onAdd:()=>this._registrar()
    };
  }

  private _add() {
    this.router.navigate([`inventory/product/add`]);
  }

  private _edit(id: string) {
    this.router.navigate([`inventory/product/edit/${id}`]);
  }

  private _delete(id: string, name: string) {
    this.http.delete(`producto/${id}`).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.msg.growl.success(`Producto [${name}] eliminado con éxito`);
          this.dt.reload();
        }
      },
      error: (err) => {
        this.msg.error(err);
      }
    });
  }
}
