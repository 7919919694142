<h5 class="m-0">Actividad</h5>
<h6 cslass="mt-4 mb-3">{{title}}</h6>
<hr />
<form [formGroup]="form" autocomplete="off">
    <div class="row">
        <div class="col-md-12">
          <div class="mb-1">
            <label for="i-nombre" class="form-label">Nombre *</label>
            <input
              [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"
              class="form-control form-control-sm"
              autocomplete="off"
              formControlName="nombre"
              type="text"
              id="i-nombre"
            />
            <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
              <span *ngIf="f.nombre.errors.required"
                >Nombre de actividad es requerido</span
              >
            </div>
          </div>
        </div>
      </div>
</form>
<hr />

<button
  type="button"
  class="btn btn-primary btn-sm btn-info"
  (click)="guardar()"
>
  <i class="bx bx-save font-size-16 align-middle me-2"></i>
  Guardar
</button>