import { Component, OnInit } from '@angular/core';
import { ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { jobViewChart, ApplicationChart, ApprovedChart, RejectedChart } from './data';

import { ChartType } from './jobs.model';

@Component({
  selector: 'app-default',
  standalone: true,
  imports: [NgApexchartsModule],
  templateUrl: './default.component.html',
  styleUrl: './default.component.scss'
})
export class DefaultComponent implements OnInit {
  jobViewChart: ChartType;
  ApplicationChart: ChartType;
  ApprovedChart: ChartType;
  RejectedChart: ChartType;


  ngOnInit(): void {
    this._fetchData();
  }

  private _fetchData() {
    this.jobViewChart = jobViewChart;
    this.ApplicationChart = ApplicationChart;
    this.ApprovedChart = ApprovedChart;
    this.RejectedChart = RejectedChart;
    
  }

}
