<div class="container-fluid">
  <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
  <div class="row">
    <div class="col-12">
      <app-spinner [loading]="loading"></app-spinner>
      <div class="row">
        <div class="col-12">
          <div class="card mb-0">
            <div class="card-body card-host">
              <div class="row">
                <div class="col-12">
                  <form [formGroup]="form" autocomplete="off">
                    <div class="row">
                      <label class="col-md-2 col-form-label text-end"
                        >Numero:</label
                      >
                      <div class="col-md-3">
                        <input
                          class="form-control form-control-sm"
                          formControlName="codigo"
                          type="text"
                          readonly
                        />
                      </div>
                      <label class="col-md-2 col-form-label text-end"
                        >Fecha Validez:</label
                      >
                      <div class="col-md-3">
                        <app-datepicker
                          [minDate]="minDate"
                          [ngClass]="{
                            'is-invalid': submitted && f.fechaValidez.errors
                          }"
                          formControlName="fechaValidez"
                          #fechaValidez
                        ></app-datepicker>
                      </div>
                    </div>
                    <div class="row">
                      <label class="col-md-2 col-form-label text-end"
                        >Creado Por:</label
                      >
                      <div class="col-md-3">
                        <input
                          class="form-control form-control-sm"
                          formControlName="responsable"
                          type="text"
                          readonly
                        />
                      </div>
                      <label class="col-md-2 col-form-label text-end"
                        >Fecha Entrega:</label
                      >
                      <div class="col-md-3">
                        <app-datepicker
                          [minDate]="minDate"
                          [ngClass]="{
                            'is-invalid': submitted && f.fechaEntrega.errors
                          }"
                          formControlName="fechaEntrega"
                          #fechaEntrega
                        ></app-datepicker>
                      </div>
                    </div>
                    <div class="row">
                      <label class="col-md-2 col-form-label text-end"
                        >Cliente:</label
                      >
                      <div class="col-md-8">
                        <app-input-search-dual
                          [config]="configClient"
                          [ngClass]="{
                            'is-invalid': submitted && f.cliente.errors
                          }"
                          formControlName="cliente"
                          #searchPersona
                        ></app-input-search-dual>
                      </div>
                    </div>
                    <div class="row">
                      <label class="col-md-2 col-form-label text-end"
                        >Observacion:</label
                      >
                      <div class="col-md-8">
                        <textarea
                          class="form-control form-control-sm"
                          formControlName="observacion"
                          type="text"
                          [readOnly]="bloquear"
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <div class="card mb-0">
            <div class="card-body m-0">
              <app-datatable-editor
                #dtpedidos
                [options]="optionModel"
              ></app-datatable-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
