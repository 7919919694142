import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SelectCatalogComponent, WorkflowDiagramComponent } from '@components/index';
import { RightsidebarService } from '@services/rightsidebar.service';
import { PageButtonsComponent, SpinnerComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { FlowBlockComponent } from '../flow-block/flow-block.component';
import { WfActivityModel, WorkFlowModel } from '@components/workflow-diagram/workflow-diagram.model';
import { FlowTransitionComponent } from '../flow-transition/flow-transition.component';

@Component({
  selector: 'app-flow-edit',
  standalone: true,
  imports: [
    CommonModule,
    PageButtonsComponent,
    SpinnerComponent,
    WorkflowDiagramComponent
  ],
  templateUrl: './flow-edit.component.html',
  styleUrl: './flow-edit.component.scss'
})
export class FlowEditComponent implements OnInit{

  private rightsidebar = inject(RightsidebarService);
  private router=inject(Router);
  private formBuilder = inject(FormBuilder);

  form: FormGroup;

  title: string = 'Nuevo Flujo';
  botones: PageButton[];
  loading: boolean;
  submitted: boolean;

  @ViewChild('workflow') workflow:any;

  constructor(){}
  
  ngOnInit(): void {

    this._initBotones();

    this.form = this.formBuilder.group({
      
    });
  }

  get f() {
    return this.form.controls;
  }

  private _initBotones(){
    this.botones = [
      {
        label: 'cancelar',
        icon: 'bx bx-block',
        cssClass: 'btn-warning',
        actionType:()=> this._cancelar()
      },
    
      {
        label: 'guardar',
        icon: 'bx bx-save',
        cssClass: 'btn-info',
        actionType:()=> this._guardar()
      }
    ];
  }

  

  private _cancelar(){
    this.router.navigate([`administration/flow/list`]);
  }
  private _guardar(){
    const grafo = this.workflow.getGrafo();
    console.log(grafo);

  }

  addTransition(model:any = null){
    const arr = this.workflow.getNodesNotLine();
    if(arr && arr.length>0){
      const sidebar = this.rightsidebar.show(FlowTransitionComponent, {
        settings:{
          nodes:arr,
          model
        }
      });
      if(sidebar.componentInstance){
        sidebar.componentInstance.emitter.subscribe((data:WfActivityModel) => {
          const {action} = data;
          if(data && action==='create'){
            this.workflow.add(data);
          }else if(data && action==='update'){
            this.workflow.update(data);
          }
        });
      }
    }
  }

  addBlock(model:any = null){
    const sidebar = this.rightsidebar.show(FlowBlockComponent, {
      settings:{
        model
      }
    });

    if (sidebar.componentInstance) {
      sidebar.componentInstance.emitter.subscribe((data:WfActivityModel) => {
        const {action} = data;
        if(data && action==='create'){
          this.workflow.add(data);
        }else if(data && action==='update'){
          this.workflow.update(data);
        }
      });
    }
  }

  nodedblclick(model: WorkFlowModel) {
    const {type,data} = model;
    if(type==='step'){
      this.addBlock(data);
    }else if(type==='flow'){
      this.addTransition(data);
    }
  }
}
