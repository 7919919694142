import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor(private http: HttpClient) {}

  download(apiUrl: string, model:any): Observable<void> {
    return this.http
      .post(apiUrl, model,{ responseType: 'blob' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Error al descargar el archivo Excel:', error);
          return throwError(
            'Error al descargar el archivo Excel. Por favor, inténtalo de nuevo más tarde.'
          );
        })
      )
      .pipe(
        catchError((error: any) => {
          console.error('Error al procesar el archivo Excel:', error);
          return throwError(
            'Error al procesar el archivo Excel. Por favor, inténtalo de nuevo más tarde.'
          );
        }),
        switchMap((data: Blob) => {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'data.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
          return of();
        })
      );
  }
}
