import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import {
  CellEditorModel,
  ColumnsEditorModel,
  DatatableEditorModel,
  ItemEditorModel,
  MenuItemEditorModel,
  SearchEditorModel,
} from './datatable-editor.model';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { CommonModule } from '@angular/common';
import { UUID } from 'angular2-uuid';
import { DatatableExtensionService } from '@shared/extension/datatable-extension.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalSearchComponent } from '..';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@services/alert.service';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-datatable-editor',
  standalone: true,
  imports: [DataTablesModule, CommonModule],
  templateUrl: './datatable-editor.component.html',
  styleUrl: './datatable-editor.component.scss',
})
export class DatatableEditorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  @Input() options: DatatableEditorModel;
  dtOptions: any = {};

  _disabled: boolean = false;
  _visibleAddRow: boolean = false;
  @ViewChild('btnadddetail') btnagregar: ElementRef;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  private dt: any;
  private catalogos: any = {};
  private inputFunctions: Map<string, Function> = new Map();

  constructor(
    private extension: DatatableExtensionService,
    private modalService: ModalService
  ) {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    const that = this;

    this.datatableElement.dtInstance.then((dtInstance: any) => {
      that.dt = dtInstance;

      $(dtInstance.tables().header()).addClass('table-light');
      $(dtInstance.table(0).body())
        .on('focus', '.custom-input', function (evt: any) {
          const valor = evt.target.value;
          const inputElement = $(evt.target);
          const isNumberFormat =
            inputElement.hasClass('tb-input-decimal') ||
            inputElement.hasClass('tb-input-number');
          if (parseFloat(valor) === 0.0 && isNumberFormat)
            $(evt.target).val('');
        })
        .on('keydown', '.tb-input-number', (evt: any) =>
          this.extension.onlyNumbers(evt)
        )
        .on('paste', '.tb-input-number', (evt: any) =>
          this.extension.onlyNumbers(evt)
        )
        .on('keydown', '.tb-input-decimal', (evt: any) =>
          this.extension.onlyDecimal(evt)
        )
        .on('change', '.custom-input', function () {
          const $row = $(this).closest('tr');
          const columnIndex: number = $(this).closest('td').index();
          const column: ColumnsEditorModel = that.options.columns![columnIndex];
          const dataFieldName = column.data;

          if (dataFieldName) {
            let newValue = $(this).val();
            const row = dtInstance.row($row);
            const rowData: any = row.data();
            const isNumberFormat =
              $(this).hasClass('tb-input-decimal') ||
              $(this).hasClass('tb-input-number');

            if (isNumberFormat && newValue && newValue.length === 0) {
              newValue = $(this).hasClass('tb-input-decimal') ? '0.00' : 0;
              $(this).val(newValue);
            }

            rowData[dataFieldName] = isNumberFormat
              ? Number(newValue || 0)
              : newValue;

            //rowData['subtotal']=100;
            //dtInstance.cell(0,5).data(100).draw();
            //row.data(rowData).draw();

            const callback = column.onChange;
            if (callback) callback(dtInstance, rowData, row.index());
          }
        })
        .on('click', '.custom-button-search', function (evt: any) {
          const $row = $(this).closest('tr');
          const row = dtInstance.row($row);
          const columnIndex: number = $(this).closest('td').index();
          const { value } = evt.target.parentNode.querySelector('input');

          that._openSearchModal(
            $(this).closest('td'),
            evt.target,
            columnIndex,
            row,
            value
          );
        })
        .on('click', '.custom-button', function (evt: any) {
          console.log('click botton...');
          const eventData = $(evt.target).attr('data-event');
          if (eventData) {
            const $row = $(this).closest('tr');
            const row = dtInstance.row($row);

            const fn = that.inputFunctions.get(eventData);
            if (fn) fn(row.index(), row.data());
          }
        })
        .on('keypress', '.custom-input-search', function (evt: any) {
          if (evt.keyCode == 13) {
            evt.preventDefault();
            const $row = $(this).closest('tr');
            const row = dtInstance.row($row);
            const columnIndex: number = $(this).closest('td').index();

            const texto = evt.target.value;
            const target = $(evt.target.parentNode).find('button');

            that._openSearchModal(
              $(this).closest('td'),
              target,
              columnIndex,
              row,
              texto
            );
          }
        })
        .on('blur', '.custom-input-search-change', function (evt: any) {
          const columnIndex: number = $(this).closest('td').index();
          const column: ColumnsEditorModel = that.options.columns![columnIndex];
          const dataFieldName = column.data;

          if (dataFieldName) {
            const $row = $(this).closest('tr');
            const row = dtInstance.row($row);
            const rowData: any = row.data();
            const cell = rowData[dataFieldName];
            const texto = $(this).val();
            const config: any = column.cell?.searching;
            const oldTexto = cell[config.codeName || 'codigo'];

            console.log(
              dataFieldName,
              rowData,
              cell[config.codeName || 'codigo'],
              'texto actual',
              texto
            );

            if (oldTexto && oldTexto !== texto) {
              rowData[dataFieldName] = {};
              $(this).removeClass('custom-input-search-change');
              that._aplicarSearch(config, $(this).closest('td'), null);
            }
          }
        })
        .on('change', '.custom-select', function (evt: any) {
          const value = evt.target.value;
          const label = this.options[this.selectedIndex].text;

          const $row = $(this).closest('tr');
          const columnIndex: number = $(this).closest('td').index();
          const column: ColumnsEditorModel = that.options.columns![columnIndex];
          const dataFieldName = column.data;

          if (dataFieldName) {
            const row = dtInstance.row($row);
            const rowData: any = row.data();
            const cell = column.cell?.selecting;
            rowData[dataFieldName] = {
              [cell?.valueName || 'value']: value,
              [cell?.labelName || 'label']: label,
            };
          }
        });
    });
  }

  ngOnInit(): void {
    
    //console.log('detect',$.fn.dataTable.ext.type.detect.find((r:any)=>r.name==="num"));
    //$.fn.dataTable.ext.type.detect.unshift(() => false);
    //console.log($.fn.dataTable.ext.type.order['num-pre']);
    //console.log(($.fn.dataTable.ext.type as any).['num']);

    this._initDatatable();
    //console.log(this.options.newRow);
    this._visibleAddRow =
      this.options.newRow !== undefined ? this.options.newRow : true;
    //console.log(this._visibleAddRow);
  }

  get dataTable() {
    return this.dt;
  }

  get invalid(): boolean {
    
    const filters = this.options.columns
      .filter((it: any) => it.cell?.required ?? false)
      .map((it) => it.data);
    
      //console.log('filters',this.options.columns);

      if (filters && filters.length > 0) {
      const rows = this.dataTable.rows().data().toArray();

      const rowsRequiered = rows.filter((item: any) =>
        filters.some((prop: any) => {
          const propValue = item[prop];
          return (
            propValue === null ||
            propValue === '' ||
            (typeof propValue === 'object' &&
              propValue &&
              Object.keys(propValue).length === 0)
          );
        })
      );
      //console.log('rowsRequiered',rowsRequiered);
      return rowsRequiered.length > 0; //console.log(rowsRequiered);
    }

    return false;
  }

  set disabled(value: boolean) {
    this._disabled = value;

    this._visibleAddRow = !value;
    if (this.dataTable) {
      try {
        this.dataTable.column(0).visible(false);
      } catch (err) {}
      this.dataTable.rows().invalidate();
    }
  }

  private _initDatatable() {
    const columns = this.options.columns
      ? this.options.columns.map((c: ColumnsEditorModel) => {
          const auxtype = c.cell?.type;
          const cell =
            c.cell != null && auxtype === 'button'
              ? this._generateClickEvent(c.cell)
              : c.cell;
          //console.log(auxtype,cell)    
          return {
            title: c.title,
            data: c.data || null,
            width: c.width,
            render: (data: any, type: any, row: any, meta: any) => {
              return !auxtype
                ? data || ''
                : auxtype === 'input'
                ? this._renderInput(data, cell)
                : auxtype === 'search'
                ? this._renderSearch(data, cell)
                : auxtype === 'select'
                ? this._renderSelect(data, cell)
                : auxtype === 'button'
                ? this._renderButon(cell)
                : auxtype === 'checkbox'
                ? this._renderCheckbox(data, cell)
                : data || '';
            },
          };
        })
      : [];

    //console.log('columnas',columns);

    this.dtOptions = {
      data: [],
      columns,
      paging: false,
      searching: false,
      ordering: false,
      info: false,
      lengthChange: false,
    };
  }

  private _renderCheckbox(data: any, cell: CellEditorModel | undefined) {
    return `<div class="form-check">
      <input class="form-check-input" type="checkbox" value="">
    </div>`;
  }

  private _renderInput(data: any, cell: CellEditorModel | undefined) {
    return `<input type="text"  value="${data || ''}" ${
      this._disabled ? 'readonly' : ''
    }  class="form-control form-control-sm ${cell?.class ? cell.class : ''} ${
      cell?.readonly && cell.readonly === true ? 'input-readonly' : ''
    }  custom-input ${
      cell?.format ? `tb-input-${cell.format.toLowerCase()}` : ''
    }">`;
  }

  private _renderSearch(data: any, cell: CellEditorModel | undefined) {
    return `<div class="input-group input-group-sm">
    <input type="text" id="${UUID.UUID()}" ${
      this._disabled ? 'readonly' : ''
    } value="${
      data ? data[cell?.searching?.codeName || 'codigo'] || '' : ''
    }" class="form-control custom-input-search" autocomplete="off" style="width: ${
      cell?.width ? cell.width : 80
    }px; flex: none;" placeholder="" aria-label="" aria-describedby="button-addon2">
    <button class="btn btn-outline-secondary custom-button-search" ${
      this._disabled ? 'disabled' : ''
    } type="button" id="button-addon2"><span class="fa fa-search" role="status" aria-hidden="true"></span></button>
    <input type="text" value="${
      data ? data[cell?.searching?.valueName || 'nombre'] || '' : ''
    }" class="form-control custom-resul-search" readonly placeholder="">
  </div>`;
  }

  private _renderSelect(data: any, cell: CellEditorModel | undefined) {
    const items: string = this._generateItemCatalogo(cell, data);
    //console.log(this._disabled);
    return `<select ${
      this._disabled ? 'disabled' : ''
    } class="custom-select form-select form-select-sm" aria-label="...">
    <option value='' selected>...</option>
    ${items}
  </select>`;
  }

  private _renderButon(cell: CellEditorModel | undefined) {
    const items = this._generateItemMenu(cell);
    return items !== null ? items : '';
  }

  private _generateItemMenu(cell: CellEditorModel | undefined) {
    if (!cell) {
      console.error('aributo column.cell es requerido');
      return null;
    } else if (!cell.items) {
      console.error('aributo cell.items es requerido');
      return null;
    }

    const items = cell.items || [];
    return items.length > 0
      ? items
          .map((it: ItemEditorModel) => {
            return it.type === 'menu'
              ? this._generateMenuItem(it)
              : this._generateButon(it);
          })
          .join('')
      : null;
  }

  private _generateMenuItem(model: any) {
    //console.log(model);
    if (!model.items) {
      console.error('aributo Menu items es requerido');
      return '';
    }

    const items = model.items || [];
    return `<div class="btn-group dropdown"><button type="button" class="btn btn-outline-info btn-xs btn-rounded btn-tb btn-mnu-act dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i style="pointer-events: none;" class="fa-solid fa-fw fa-sm fas fa-ellipsis-v"></i>
  </button> <ul class="dropdown-menu"> ${
    items.length > 0
      ? items
          .map((itm: any | MenuItemEditorModel) => {
            return `<li><a class="dropdown-item custom-button" href="javascript: void(0)" view-action="menu" ${
              itm.event ? 'data-event="' + itm.event + '"' : ''
            }>${itm.title}</a></li>`;
          })
          .join('')
      : ''
  }</ul></div>`;
  }

  private _generateButon(model: any) {
    //console.log('_generateButon',model);
    return `<a class="btn btn-outline-danger btn-xs btn-rounded btn-tb custom-button" ${
      model.event ? 'data-event="' + model.event + '"' : ''
    }><i style="pointer-events: none;" class="fa-solid fa-fw fa-sm ${
      model.icon ? model.icon : ''
    }"></i></a>`;
  }

  private _generateClickEvent(model: CellEditorModel): any {
    if (!model.items || (model.items && model.items.length === 0)) return model;

    model.items.forEach((item: any | ItemEditorModel) => {
      if ((!item.type || item.type === 'button') && item.onclick) {
        const uuid = UUID.UUID();
        this.inputFunctions.set(uuid, item.onclick);
        item['event'] = uuid;
      } else if (item.type === 'menu' && item.items && item.items.length > 0) {
        item.items.forEach((subItem: any) => {
          if (subItem.onclick) {
            const uuid = UUID.UUID();
            this.inputFunctions.set(uuid, subItem.onclick);
            subItem['event'] = uuid;
          }
        });
      }
    });

    //console.log('_generateClickEvent', model);
    return model;
  }

  private _generateItemCatalogo(
    cell: CellEditorModel | undefined,
    data: any
  ): string {
    if (!cell) {
      console.error('aributo column.cell es requerido');
      return '';
    }

    if (!cell.selecting) {
      console.error('aributo column.cell.selecting es requerido');
      return '';
    }

    const value = cell.selecting.valueName || 'value';
    const label = cell.selecting.labelName || 'label';
    const auxItems = this.catalogos[cell.selecting.name] || [];

    //console.log(data, value);
    const items = auxItems.map((it: any) => {
      //console.log(it);
      return `<option value="${it[value]}" ${
        data && it[value] === data[value] ? 'selected' : ''
      } >${it[label]}</option>`;
    });
    //console.log( auxItems, items, value,label);
    return items.join('');
  }

  private _openSearchModal(
    td: any,
    target: any,
    columnIndex: number,
    row: any,
    search: string
  ) {
    const rowIndex = row.index();
    const column: ColumnsEditorModel = this.options.columns![columnIndex];
    const config = column.cell?.searching;
    const dataFieldName = column.data;

    if (!dataFieldName) {
      console.error('atributo column.data es requerido');
      return;
    }

    if (!config) {
      console.error('atributo cell.searching es requerido');
      return;
    }

    this._loadingSearch(target, true);

    const rowData: any = row.data();
    //const cell = rowData[dataFieldName!];

    var model: any = {
      page: 1,
      pageSize: 10,
      sortBy: '',
      search,
      sortDirection: '',
    };

    this.http.post(`${config.url}`, model).subscribe({
      next: (data: any) => {
        if (data) {
          const { rows, totalRows } = data;

          if (totalRows > 1) {
            const modalRef = this.modalService.show(ModalSearchComponent, {
              class: 'modal-dialog-centered modal-lg',
              initialState: {
                settings: {
                  search,
                  data: { rows, totalRows },
                  index: rowIndex,
                  title: config.title,
                  url: config.url,
                  columns: config.columns,
                  pageLength: 10,
                },
              },
            });
            if (modalRef.content) {
              modalRef.content.selection.subscribe(
                ({ index, data, action }: any) => {
                  rowData[dataFieldName!] = data;
                  this._aplicarSearch(config, td, data);
                }
              );
              modalRef.content.change.subscribe((data:any)=>{
                if(config.changeComponent){
                  const modalChange = this.modalService.show(config.changeComponent,{
                    class: 'modal-dialog-centered modal-lg',
                  });
                }
              });
            }
          } else if (totalRows == 1) {
            rowData[dataFieldName!] = rows[0];
            this._aplicarSearch(config, td, rows[0]);
          } else {
            this.msg.growl.error(
              'El criterio de busqueda no genero resultados'
            );
            rowData[dataFieldName!] = {};
            this._aplicarSearch(config, td, null);
          }
        }

        this._loadingSearch(target, false);
      },
      error: (error) => this._loadingSearch(target, false),
    });
  }

  private _loadingSearch(target: any, loading: boolean) {
    if (target) {
      if (loading)
        $(target)
          .find('span')
          .removeClass('fa fa-search')
          .addClass('spinner-border spinner-border-sm');
      else
        $(target)
          .find('span')
          .removeClass('spinner-border spinner-border-sm')
          .addClass('fa fa-search');
    }
  }

  private _aplicarSearch(config: SearchEditorModel, target: any, data: any) {
    const auxTarget = target.find('.custom-input-search');
    const currentValue = auxTarget.val();

    auxTarget.val(data !== null ? data[config.codeName] : currentValue);
    if (data != null) auxTarget.addClass('custom-input-search-change');
    target
      .find('.custom-resul-search')
      .val(data !== null ? data[config.valueName] : '');
  }

  setCatalogs(catalogos: any) {
    this.catalogos = catalogos;
  }

  getModel(): any[] {
    return this.dt.rows().data().toArray();
  }

  addNewRow(row: any = undefined): void {
    if (this.dt) {
      this.dt.row
        .add(
          row
            ? row
            : this.options.defaultModel
            ? { ...this.options.defaultModel }
            : {}
        )
        .draw(false); //{ ...this.options.defaultModel } || {}).draw(false);

      if (this._visibleAddRow && this.btnagregar) {
        const componentOffsetTop = this.btnagregar.nativeElement.offsetTop;
        const scrollPosition = componentOffsetTop; //+ 5;

        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
    }
  }

  insertBeforeNewRow(index: number, data: any = undefined): void {
    if (this.dt) {
      this.extension.addBeforeRowByPos(
        this.dt,
        data
          ? data
          : this.options.defaultModel
          ? { ...this.options.defaultModel }
          : {},
        index
      );
    }
  }

  insertAfterNewRow(index: number, data: any = undefined): void {
    if (this.dt) {
      this.extension.addAfterRowByPos(
        this.dt,
        data
          ? data
          : this.options.defaultModel
          ? { ...this.options.defaultModel }
          : {},
        index
      );
    }
  }

  deleteRow(index: number): void {
    if (this.dt) {
      //console.log('delte index', index);
      this.dt.row(index).remove().draw();
      this.dt.rows().invalidate();
    }
  }

  setItems(items: any[]) {
    if (this.dt) {
      //console.log('setitems',items);
      this.dt.clear().draw();
      this.dt.rows.add(items).draw();
      this.dt.rows().invalidate();
    }
  }

  setDisabled() {}
}
