import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageButtonsComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UserListComponent } from '../users/user-list/user-list.component';
import { CompanyFacturaElectronicaComponent } from './company-factura-electronica/company-factura-electronica.component';
import { CompanyCorreoListComponent } from './company-correo/company-correo-list/company-correo-list.component';
import { CompanyGeneralEditComponent } from './company-general/company-general-edit/company-general-edit.component';


@Component({
  selector: 'app-configuration',
  standalone: true,
  imports: [
    CommonModule,
    PageButtonsComponent,
    TabsModule,
    CompanyGeneralEditComponent,
    CompanyFacturaElectronicaComponent,
    CompanyCorreoListComponent,
    UserListComponent,
  ],
  templateUrl: './configuration.component.html',
  styleUrl: './configuration.component.scss',
})
export class ConfigurationComponent implements OnInit {
  title: string = 'Mi compañia';
  botones: PageButton[];

  activeTab:string|null="general"

  ngOnInit(): void {
    this._initBotones();
  }

  private _initBotones() {}

  changeTab(event:any){
    this.activeTab = event.id;
  }
}
