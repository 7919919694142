<div class="p-3 mb-0">
  <form [formGroup]="form" autocomplete="new-password">
    <app-spinner [loading]="loading"></app-spinner>
    <div class="row">
      <div class="col-md-3">
        <label class="mb-0" for="f-nombre-input">Ambiente:</label>
      </div>
      <div class="col-md-7">
        <app-select-catalog
                  [size]="'sm'"
                  [ngClass]="{
                    'is-invalid': submitted && f.tipoAmbiente.errors
                  }"
                  #cmbTipoAmbiente
                  (changeValue)="changeTipoAmbiente($event)"
                ></app-select-catalog>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-md-3">
        <label class="mb-0" for="f-nombre-input">Firma electrónica:</label>
        <p><small>(Firma electrónica de representante legal)</small></p>
      </div>
      <div class="col-md-7">
        <!--<input id="importFile" [ngClass]="{ 'is-invalid': submitted && f.firmaElectronica.errors }" class="form-control form-control-sm" #fileInput type="file" (change)="onChangeFile($event)">-->
        <app-input-file
          #firmaElectronica
          [allowedExtensions]="['p12']"
          [invalid]="submitted && f.firmaElectronica.errors"
          (changeFile)="onChangeFile($event)"
        ></app-input-file>
        @if(firmaSubida){<small>Firma Subida</small>}
      </div>
    </div>
    @if(firmaSubida){
    <div class="row mt-1">
      <div class="col-md-3">
        <label for="f-nombre-input">Fecha caducidad:</label>
      </div>
      <div class="col-md-5">
        <input
          type="text"
          [readOnly]="true"
          formControlName="fechaCaducidad"
          class="form-control form-control-sm"
        />
      </div>
    </div>
    }
    <div class="row mt-1">
      <div class="col-md-3">
        <label for="f-nombre-input">Clave firma electrónica:</label>
      </div>
      <div class="col-md-5">
        @if(!firmaSubida || editarClave){
        <div class="input-group input-group-sm">
          <div class="col-6">
            <input
              type="password"
              autocomplete="password"
              [ngClass]="{ 'is-invalid': submitted && f.firmaClave.errors }"
              formControlName="firmaClave"
              class="form-control form-control-sm"
            />
            <p><small>Ingrese clave</small></p>
          </div>
          <div class="col-6">
            <input
              type="password"
              autocomplete="password"
              [ngClass]="{
                'is-invalid': submitted && f.firmaClaveConfirmacion.errors
              }"
              formControlName="firmaClaveConfirmacion"
              class="form-control form-control-sm"
            />
            <p><small>Confirme clave</small></p>
          </div>
        </div>
        }@else{
        <div class="input-group-btn input-group input-group-sm">
          <button class="btn" type="button" (click)="onEditarClave()">
            <i class="bx bx-edit-alt"></i>
          </button>
          <label class="input-group-text form-control">Modificar Clave</label>
        </div>
        }
      </div>
    </div>
  </form>

  <div class="row mt-1">
    <div class="col-md-12">
      <div class="d-flex flex-wrap gap-2">
        <button
          type="button"
          class="btn-info btn btn-primary btn-sm"
          (click)="guardar()"
        >
          <i class="bx bx-save font-size-16 align-middle me-2"></i>
          guardar
        </button>
        @if(editarClave){<button
          type="button"
          class="btn-info btn btn-warning btn-sm"
          (click)="onCancelarEditarClave()"
        >
          <i class="bx bx-block font-size-16 align-middle me-2"></i>
          cancelar
        </button>}
      </div>
    </div>
  </div>
</div>
