import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckListModel } from '@components/check-list/checklist.model';
import { CheckListComponent, SelectCatalogComponent } from '@components/index';
import { NumbersOnlyDirective } from '@directives/numbers-only.directive';
import { AlertService } from '@services/alert.service';
import { SpinnerComponent } from '@shared/ui';

@Component({
  selector: 'app-product-edit-view',
  standalone: true,
  imports: [ 
    CommonModule,
    SpinnerComponent,
    ReactiveFormsModule,
    FormsModule,  
    SelectCatalogComponent,
    CheckListComponent,
    NumbersOnlyDirective
  ],
  templateUrl: './product-edit-view.component.html',
  styleUrl: './product-edit-view.component.scss'
})
export class ProductEditViewComponent implements OnInit {
  //private router= inject(Router);
  private formBuilder= inject(FormBuilder);
  private http=inject(HttpClient);
  private msg = inject(AlertService);

  loading: boolean;
  submitted:boolean;
  aplicaProductoServicio:boolean;
  itemsProceso:Array<CheckListModel> = [];

  private catalogoCasaQuimica:Array<any>=[];

  @Input() editMode:boolean=false;
  @Input() productoId:string|null=null;
  @Output() onSuccesss = new EventEmitter<any>();

  public form: FormGroup;
  @ViewChild('cmbCasaQuimica') cmbCasaQuimica: any;
  @ViewChild('cmbUnidadCalculo') cmbUnidadCalculo: any;
  @ViewChild('cmbTipoProcucto') cmbTipoProcucto: any;
  @ViewChild('ckbItemsProcesos') ckbItemsProcesos:any;

  constructor(){}
  
  ngOnInit(): void {

    this._obtener(this.productoId);

    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      costo: ['', [Validators.required]],
      codigo: ['', [Validators.required]],
      descripcion: [''],
      remplazo: [''],
      tipoCasaQuimica: [''],
      tipoUnidadCalculo: [''],
      tipoProducto: ['',[Validators.required]],
      ckeckProductoTerminado:[false]
    });
  }

  get f() { return this.form.controls; }

  private _obtener(id: any = null) {
    this.loading=true;
    this.http.get(id ? `producto/${id}` : 'producto').subscribe({
      next: (data: any) => {
        if (data) {
          if (data.model) {
            this.form.patchValue({
              ckeckProductoTerminado:data.model.aplicaProductoTerminado,
              codigo:data.model.codigo,
              nombre: data.model.nombre,
              costo: data.model.costo,
              remplazo: data.model.remplazo,
              descripcion: data.model.descripcion,
              tipoCasaQuimica: data.model.tipoCasaQuimica,
              tipoUnidadCalculo: data.model.tipoUnidadCalculo,
              tipoProducto:data.model.tipoProducto
            });
          }

          if(data.procesos){
            this.itemsProceso = data.procesos;
          }

          if (data.catalogos) {
            if (data.catalogos.TP_CASA_QUIMICA) {
              this.catalogoCasaQuimica = data.catalogos.TP_CASA_QUIMICA;
              if (data.model && data.model.tipoCasaQuimica) {
                this.cmbCasaQuimica.setSelectedItem(
                  this.catalogoCasaQuimica.find((it: any) => it.id === data.model.tipoCasaQuimica)
                );
              }
              this.cmbCasaQuimica.setItems(this.catalogoCasaQuimica);
            }

            if (data.catalogos.TP_UNIDAD_CALCULO) {
              var ctgs = data.catalogos.TP_UNIDAD_CALCULO;
              if (data.model && data.model.tipoUnidadCalculo) {
                this.cmbUnidadCalculo.setSelectedItem(
                  ctgs.find((it: any) => it.id === data.model.tipoUnidadCalculo)
                );
              }
              this.cmbUnidadCalculo.setItems(ctgs);
            }

            if (data.catalogos.TP_CATEGORIA_PRODUCTO) {
              var ctgs = data.catalogos.TP_CATEGORIA_PRODUCTO;
              if (data.model && data.model.tipoProducto) {
                this.cmbTipoProcucto.setSelectedItem(
                  ctgs.find((it: any) => it.id === data.model.tipoProducto)
                );
              }
              this.cmbTipoProcucto.setItems(ctgs);
            }
          }
        }
        this.loading = false;
      },
      error: (error) => (this.loading = false),
    });
  }

  guardar(){
    console.log(this.form.value);

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const _f = this.form.value;

    if (this.productoId) {
      this._actualizar({
        id: this.productoId,
        codigo:_f.codigo,
        nombre: _f.nombre,
        costo: _f.costo,
        tipoProducto :_f.tipoProducto,
        tipoCasaQuimica: (this.aplicaProductoServicio || _f.ckeckProductoTerminado) ?null: _f.tipoCasaQuimica,
        tipoUnidadCalculo: _f.tipoUnidadCalculo,
        descripcion: _f.descripcion,
        remplazo: _f.remplazo,
        aplicaProductoTerminado: !this.aplicaProductoServicio ? _f.ckeckProductoTerminado : false,
        procesos: !_f.ckeckProductoTerminado ? this.ckbItemsProcesos.getItems() : []
      });
    } else {
      this._registrar({
        codigo:_f.codigo,
        nombre: _f.nombre,
        costo: _f.costo,
        tipoProducto :_f.tipoProducto,
        tipoCasaQuimica: (this.aplicaProductoServicio || _f.ckeckProductoTerminado) ?null: _f.tipoCasaQuimica,
        tipoUnidadCalculo: _f.tipoUnidadCalculo,
        descripcion: _f.descripcion,
        remplazo: _f.remplazo,
        aplicaProductoTerminado: !this.aplicaProductoServicio ? _f.ckeckProductoTerminado : false,
        procesos: !_f.ckeckProductoTerminado ? this.ckbItemsProcesos.getItems() : []
      });
    }
  }

  private _actualizar(model: any) {
    this.loading = true;    
    this.http.put('producto', model).subscribe({
      next:(data: any) => {
      if (data && data.exito) {
        this.form.reset();
        this.submitted = false;
        //this.modal.close();

        this.msg.growl.success(`Producto actualizado con éxito`);
        this.onSuccesss.emit();
        //this.router.navigate(['inventory/product/list']);
      }
      this.loading = false;
    },
    error:(error) => (this.loading = false),
  });
  }

  private _registrar(model: any) {
    //console.log(model);
    this.loading = true;
    this.http.post('producto', model).subscribe({
      next: (data: any) => {
      if (data && data.exito) {
        this.form.reset();
        this.submitted = false;
        //this.modal.close();
        this.msg.growl.success(`Producto registrado con éxito`);
        this.onSuccesss.emit();
        //this.router.navigate(['inventory/product/list']);
      }
      this.loading = false
    },
    error:(error) => (this.loading = false),
  });
  }
  

  changeTipoProducto(evt:any){
    this.form.patchValue({
      tipoProducto: evt.value,
    });

    this.aplicaProductoServicio = evt.label==='SERVICIO'; 
    if(!this.aplicaProductoServicio){ 
      //console.log('aplicaProductoServicio');
      setTimeout(() => {

        if(this.form.value.ckeckProductoTerminado){
          this.cmbCasaQuimica.setDisabled(true)
          this.cmbCasaQuimica.setSelectedItem(null);
        }else{
          this.cmbCasaQuimica.setSelectedItem(
            this.catalogoCasaQuimica.find((it: any) => it.id === this.form.value.tipoCasaQuimica)
          );
        }        
  
        this.cmbCasaQuimica.setItems(this.catalogoCasaQuimica);
      }, 1);      
    }
  }

  changeCheckProductoTerminado(evt:any){
    this.cmbCasaQuimica.setDisabled(evt.target.checked);
    if(evt.target.checked){
      this.cmbCasaQuimica.setSelectedItem(null);
      this.ckbItemsProcesos.unChecked();
    }else{
      this.cmbCasaQuimica.setSelectedItem(
        this.catalogoCasaQuimica.find((it: any) => it.id === this.form.value.tipoCasaQuimica)
      );
    }
  }

  changeTipoCasaQuimica(evt:any){
    this.form.patchValue({
      tipoCasaQuimica: evt.value,
    });
  }

  changeTipoUnidadCalculo(evt:any){
    this.form.patchValue({
      tipoUnidadCalculo: evt.value,
    });
  }

}
