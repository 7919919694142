import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { DatatableComponent } from '@components/index';
import { RightsidebarService } from '@services/rightsidebar.service';
import { PageButtonsComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { CatalogDetailEditComponent } from '../catalog-detail-edit/catalog-detail-edit.component';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-catalog-detail-list',
  standalone: true,
  imports: [DatatableComponent, PageButtonsComponent],
  templateUrl: './catalog-detail-list.component.html',
  styleUrl: './catalog-detail-list.component.scss',
})
export class CatalogDetailListComponent implements OnInit {
  private rightsidebar = inject(RightsidebarService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  optionModel = {};
  title: string = 'Listar Catalogos(Detalles) del sistema';
  botones: PageButton[];
  loading: boolean = false;
  private catalogoId: string;

  private model: any;

  @ViewChild(DatatableComponent) dt: DatatableComponent;

  constructor() {
    this.model = this.router.getCurrentNavigation()?.extras.state?.catalog;
  }

  ngOnInit(): void {
    this._initBotones();
    //console.log(this.model);

    this.route.params.subscribe((param) => {
      if (param.id) {
        this.catalogoId = param.id;
        this._initDatatable();
      }
    });
  }

  private _initBotones() {
    this.botones = [
      {
        label: 'cancelar',
        icon: 'bx bx-block',
        cssClass: 'btn-warning',
        actionType: () => this._cancelar(),
      },
      {
        label: 'agregar',
        icon: 'bx bx-plus',
        cssClass: 'btn-success',
        actionType: () => this._add(),
      },
    ];
  }

  private _initDatatable() {
    var columns: any = [
      {
        className: 'col--opt-2 no-sort',
        title: '...',
        buttons: [
          {
            icon: 'fas fa-pencil-alt',
            title: 'editar',
            onclick: (row: any) => {
              this._edit(row.id);
            },
          },
          {
            className: 'btn-outline-danger',
            title: 'eliminar',
            icon: 'far fa-trash-alt',
            onclick: (row: any) => {
              this._delete(row);
            },
          },
        ],
      },
      {
        title: 'Nombre',
        data: 'nombre',
      },
      {
        title: 'Codigo',
        data: 'codigo',
        //visible:false
      },
      {
        title: 'Valor',
        data: 'valor',
      },
      {
        title: 'Padre',
        data: 'nombreCatalogoPadre',
      },
      {
        title: 'Fecha Creacion',
        data: 'fecha_creacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
      {
        title: 'Fecha Modificacion',
        data: 'fecha_modificacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
    ];

    this.optionModel = {
      filtering: false,
      searching: true,

      serverSide: true,
      columns: columns,
      url: `catalogo/${this.catalogoId}/listar`,
    };
  }

  private _cancelar() {
    this.router.navigate(['administration/catalog']);
  }

  private _delete(data: any) {
    this.msg
      .confirm(
        `<p>¿Estás seguro de que deseas eliminar el catalogo <strong>${data.nombre}</strong>?</p><p>Esta acción es irreversible.</p>`
      )
      .then(({ value }) => {
        if (value) {
          this.http.delete(`catalogo/detalle/${data.id}`).subscribe({
            next: (data: any) => {
              if (data && data.exito) {
                this.msg.growl.success(
                  `Catalogo ${data.nombre} eliminado con éxito`
                );
                this.dt.reload();
              }
            },
            error: (err) => {
              this.msg.error(err);
            },
          });
        }
      });
  }

  private _add() {
    const sidebar = this.rightsidebar.show(CatalogDetailEditComponent, {
      settings: {
        idCatalogo: this.catalogoId,
      },
    });
    if (sidebar.componentInstance) {
      sidebar.componentInstance.emitter.subscribe(() => {
        //console.log("guardar")
        this.dt.reload();
      });
    }
  }

  private _edit(id: string) {
    const sidebar = this.rightsidebar.show(CatalogDetailEditComponent, {
      settings: {
        idCatalogo: this.catalogoId,
        idCatalogoDt: id,
      },
    });

    if (sidebar.componentInstance) {
      sidebar.componentInstance.emitter.subscribe(() => {
        //console.log("guardar")
        this.dt.reload();
      });
    }
  }
}
