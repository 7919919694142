import {
  DragDropModule,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { RightsidebarService } from '@services/rightsidebar.service';
import { PageButtonsComponent, SpinnerComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TaskEditComponent } from '../task-edit/task-edit.component';
import { SelectCatalogComponent, ViewerComponent } from '@components/index';
import { AlertService } from '@services/alert.service';
import { AgeService } from '@services/age.service';
import { Observable, of, interval, timer } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-task-board',
  standalone: true,
  imports: [
    CommonModule,
    PageButtonsComponent,
    BsDropdownModule,
    DragDropModule,
    SelectCatalogComponent,
    SpinnerComponent,
  ],
  templateUrl: './task-board.component.html',
  styleUrl: './task-board.component.scss',
})
export class TaskBoardComponent implements OnInit {
  private http = inject(HttpClient);
  private msg = inject(AlertService);
  private rightsidebar = inject(RightsidebarService);

  botones: PageButton[];
  title: string = 'Planificacion por Proceso';
  private procesoSeleccionado: string;
  loading: boolean = true;
  activaArchivar: boolean = false;

  pendientesList: any[] = [];
  pendientesId: string;

  enProcesoList: any[] = [];
  enProcesoId: string;

  finalizadoList: any[] = [];
  finalizadoId: string;

  @ViewChild('cmbTipoProceso') cmbTipoProceso: any;

  constructor(public age: AgeService,private modalViewer: BsModalService) {}

  ngOnInit(): void {
    //this.age.setTiny(true);
    this._obtener();
  }

  private calculateAge(tarea: any): Observable<string> {
    //tarea.semaforo = "success"
    return tarea?.fechaTarea === ''
      ? of('')
      : interval(1000).pipe(
          startWith(0),
          switchMap(() => {
            const fecha = tarea.fechaTarea;
            const inputDate = moment(fecha, 'DD/MM/YYYY HH:mm');
            const now = moment();

            const diffMinutes = now.diff(inputDate, 'minutes');
            if (diffMinutes >= 30) {
              tarea.semaforo = 'danger';
            } else if (diffMinutes >= 15) {
              tarea.semaforo = 'warning';
            } else {
              tarea.semaforo = 'success';
            }

            return of(this.age.calculateAge(fecha));
          })
        );
  }

  private _initTimeService(task: any[]) {
    task.forEach((t) => {
      (t.semaforo = null), (t.fecha = this.calculateAge(t));
    });
  }

  private _obtener() {
    this.http
      .get(
        `planificacion/produccion/board${
          this.procesoSeleccionado ? `/${this.procesoSeleccionado}` : ''
        }`
      )
      .subscribe({
        next: (data: any) => {
          const { exito, model, catalogos } = data;

          //console.log(model);

          if (model) {
            this.procesoSeleccionado = model.procesoId;

            this.pendientesList = model.pendientes.items;
            this._initTimeService(this.pendientesList);
            this.pendientesId = model.pendientes.id;

            this.enProcesoList = model.enProcesos.items;
            this._initTimeService(this.enProcesoList);
            this.enProcesoId = model.enProcesos.id;

            this.finalizadoList = model.finalizados.items;
            this.finalizadoId = model.finalizados.id;
          }

          if (catalogos) {
            const { TP_PROCESO_PRODUCCION } = catalogos;
            if (TP_PROCESO_PRODUCCION) {
              this.cmbTipoProceso.setItems(TP_PROCESO_PRODUCCION);

              if (model.procesoId) {
                this.cmbTipoProceso.setSelectedItem(
                  TP_PROCESO_PRODUCCION.find(
                    (it: any) => it.id === model.procesoId
                  )
                );
              }
            }
          }

          this.loading = false;
        },
        error: (error) => (this.loading = false),
      });
  }

  changeProceso(item: any) {
    this.procesoSeleccionado = item.value;
    //console.log('changeProceso',item);
    this.activaArchivar = item.label === 'EMPAQUETADO';
    this.loading = true;
    this._obtener();
  }

  drop(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      const { id, data } = event.container;
      const aux =
        id === 'pendientesList'
          ? 1
          : id === 'enProcesoList'
          ? 2
          : id === 'finalizadoList'
          ? 3
          : null;
      const customModel = data[event.currentIndex];
      const tareaId = customModel.id;
      const responsables = customModel.responsables;

      if (aux) {
        if (aux === 2) {
          const numBandas = customModel.numeroBandas || null;

          if ((responsables || []).length === 0 || numBandas === null)
            this._openEditTask(tareaId, this.enProcesoId, aux);
          else {
            this._actualizar(tareaId, this.enProcesoId, responsables);
          }
        } else if (aux === 3) {
          const numBandas = customModel.numeroBandas || null;
          const numBandasProd = customModel.numeroBandasEntregadas || null;

          if (
            (responsables || []).length === 0 ||
            numBandasProd === null ||
            numBandas === null
          )
            this._openEditTask(tareaId, this.finalizadoId, aux);
          else {
            this._actualizar(tareaId, this.finalizadoId, responsables);
          }
        } else {
          this._actualizar(
            tareaId,
            id === 'pendientesList'
              ? this.pendientesId
              : id === 'finalizadoList'
              ? this.finalizadoId
              : null,
            responsables
          );
        }
      }
    }
  }

  archivarTask(task: any) {
    this.msg.confirm(`¿Desea archivar la tarea.`).then(({ value }) => {
      if (value) {
        this.http
          .put(`planificacion/produccion/task/${task.id}`, null)
          .subscribe({
            next: (data: any) => {
              if (data && data.exito) {
                this.msg.success(`Tarea archivada con éxito`);
              }
              this.loading = false;
              this._obtener();
            },
            error: (error) => (this.loading = false),
          });
      }
    });
  }

  editTask(task: any, id: string | null) {
    const aux =
      id === 'pendientesList'
        ? 1
        : id === 'enProcesoList'
        ? 2
        : id === 'finalizadoList'
        ? 3
        : null;
    this._openEditTask(
      task.id,
      id === 'pendientesList'
        ? this.pendientesId
        : id === 'finalizadoList'
        ? this.finalizadoId
        : id === 'enProcesoList'
        ? this.enProcesoId
        : null,
      aux,
      true
    );
  }

  generarReporteProceso(id: string) {
    const aux:any =
      id === 'pendientesList'
        ? this.pendientesList.length
        : id === 'enProcesoList'
        ? this.enProcesoList.length
        : id === 'finalizadoList'
        ? this.finalizadoList.length
        : null;
    //console.log(aux);
    if (aux === null || (aux !==null && aux === 0))
    {  this.msg.error(
        `El reporte no puede generarse porque la lista de ${
          id === 'pendientesList'
            ? 'pendientes'
            : id === 'enProcesoList'
            ? 'en proceso'
            : id === 'finalizadoList'
            ? 'finalizados'
            : ''
        } está vacía. Por favor, revisa y agrega los elementos necesarios antes de intentar generar el reporte nuevamente`
      );
      return;
    }

    const idEstado:any = id === 'pendientesList'
        ? this.pendientesId
        : id === 'finalizadoList'
        ? this.finalizadoId
        : id === 'enProcesoList'
        ? this.enProcesoId
        : null;

    this.modalViewer.show(ViewerComponent, {
      class: 'modal-dialog-centered modal-xl',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState:{
        url:`planificacion/produccion/reporte/${this.procesoSeleccionado}/${idEstado}`
      }
    });   
  }

  generarReporteProcesoTarera(id: string,idtask:string) {
    
    console.log(id,idtask);

    const idEstado:any = id === 'pendientesList'
        ? this.pendientesId
        : id === 'finalizadoList'
        ? this.finalizadoId
        : id === 'enProcesoList'
        ? this.enProcesoId
        : null;
        
    if(idEstado){
      this.modalViewer.show(ViewerComponent, {
        class: 'modal-dialog-centered modal-xl',
        ignoreBackdropClick: true,
        keyboard: false,
        initialState:{
          url:`planificacion/produccion/reporte/tarea/${this.procesoSeleccionado}/${idEstado}/${idtask}`
        }
      }); 
    }    
  }

  private _openEditTask(
    tareaId: string,
    estadoId: string | null,
    aux: number | null,
    autoClose: boolean = false
  ) {
    if (estadoId === null) return;

    var sidebar = this.rightsidebar.show(TaskEditComponent, {
      autoClose,
      settings: {
        id: tareaId,
        aux,
        proceso: this.procesoSeleccionado,
        estado: estadoId,
      },
    });

    if (sidebar.componentInstance) {
      sidebar.componentInstance.emitter.subscribe((data: any) => {
        const { reload } = data;
        if (reload) {
          console.log(reload);
          this._obtener();
        }
      });
    }
  }

  private _actualizar(
    tareaId: string,
    estadoId: string | null,
    responsables: any[] | null
  ) {
    //console.log('actualizar',responsables);
    if (estadoId === null) return;
    this.loading = true;

    const model = {
      tarea: tareaId,
      estado: estadoId,
      proceso: this.procesoSeleccionado,
      responsables: responsables ? responsables.map((it: any) => it.id) : null,
    };

    this.http.put(`planificacion/produccion/task`, model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.msg.success(`Estado de tarea actualiza con éxito`);
        }
        this.loading = false;
        this._obtener();
      },
      error: (error) => (this.loading = false),
    });
  }
}
