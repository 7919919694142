import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-input-file',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-file.component.html',
  styleUrl: './input-file.component.scss'
})
export class InputFileComponent implements OnInit {

  private msg = inject(AlertService);

  @Output() changeFile: EventEmitter<File|null> = new EventEmitter();
  @Input() allowedExtensions: string[] = [];
  
  @Input() invalid: boolean | ValidationErrors | null = false;


  @ViewChild('fileInput') fileInput: ElementRef;


  buttonText: string; 
  private defauldLabel = 'Sin archivos seleccionados';

  constructor(){}

  ngOnInit(): void {
    this.buttonText = this.defauldLabel;
  }
 

  onFileSelected(event:any){
    const files = event.target.files;
    //console.log(files);
    const file = files[0];
    if (file) {
      if (this._isValidFile(file)) {
        this.buttonText = file.name;
        this.changeFile.emit(file);
      } else {
        this.buttonText = this.defauldLabel;
        if (this.allowedExtensions.length > 0) {
          this.msg.growl.error(`Please select a file with one of the following extensions: ${this.allowedExtensions.join(', ')}`);
        } else {
          this.msg.growl.error('Please select a valid file.');
        }
      }
    } else {
      this.changeFile.emit(null);
      this.buttonText = this.defauldLabel;
    }    
  }
  
  chooseFile(){
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.click();
    }
  }

  set fileName(name:string){
    this.buttonText = name;
  }

  private _isValidFile(file: File): boolean {
    if (this.allowedExtensions.length === 0) {
      return true;
    }
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return this.allowedExtensions.includes(fileExtension!);
  }
}
