<div class="mb-2">
  <ul class="list-group list-group-flush">
    <li class="list-group-item py-1 px-1">
      <div class="d-flex">
        <div class="flex-shrink-0 me-2">
          <div class="avatar-xs">
            <div class="avatar-title rounded-circle bg-light text-primary">
              <img [src]="urlUsuario" alt="" class="avatar-xs rounded-circle" />
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <textarea
            class="form-control form-control-sm mb-1 no-resize"
            autocomplete="off"
            type="text"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (input)="onInput()"
            [rows]="isFocused ? 3 : 1"
            [(ngModel)]="comentario"
            placeholder="Escribe un comentario"
            id="i-comentario"
            maxlength="500"
          ></textarea>
          <button
            *ngIf="isFocused"
            [disabled]="!comentario.trim()"
            (click)="onSave()"
            type="button"
            disabled
            class="btn btn-sm btn-info"
          >
            Guardar
          </button>
        </div>
      </div>
    </li>
  </ul>
</div>
<ngx-simplebar style="max-height: 376px">
  <ul class="list-group list-group-flush">
    <li *ngFor="let item of items" class="list-group-item py-1 px-1">
      <div class="d-flex">
        <div class="flex-shrink-0 me-2">
          <div class="avatar-xs">
            <div class="avatar-title rounded-circle bg-light text-primary">
              <img [src]="item.url" alt="" class="avatar-xs rounded-circle" />
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <h5 class="font-size-14 mb-1">{{ item.usuario }}</h5>
          <h6 class="text-muted font-size-10 mb-1">
            {{ item.fecha }} @if(item.editado){<span>(Editado)</span>}
          </h6>
          @if(!item.activaEditar){
          <p class="text-muted mb-1">{{ item.comentario }}</p>
          @if(puedeEditar(item.idResponsable)){
          <div class="font-size-10">
            <i class="mdi mdi-arrow-right" style="margin-right: 3px"></i
            ><a
              href="javascript: void(0);"
              class="text-decoration-underline text-reset"
              (click)="editarComentario(item.id)"
              >Editar</a
            >
            •
            <a
              href="javascript: void(0);"
              class="text-decoration-underline text-reset"
              (click)="eliminarComentario(item.id)"
              >Eliminar</a
            >
          </div>
          } } @else {
          <textarea
            class="form-control form-control-sm mb-1 no-resize"
            autocomplete="off"
            type="text"
            [rows]="3"
            maxlength="500"
            [(ngModel)]="item.comentario"
            placeholder="Escribe un comentario"
          ></textarea>
          <div class="btn-toolbar">
            <button
              [disabled]="
                !item.comentario.trim() ||
                item.auxComentario === item.comentario
              "
              (click)="onUpdate(item)"
              type="button"
              disabled
              class="btn btn-sm btn-info me-1"
            >
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              (click)="descartarEditar(item.id)"
            >
              Descartar
            </button>
          </div>

          }
        </div>
      </div>
    </li>
  </ul>
</ngx-simplebar>
