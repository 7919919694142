import { Component, Input } from '@angular/core';
import { CheckListModel } from './checklist.model';

@Component({
  selector: 'app-check-list',
  standalone: true,
  imports: [],
  templateUrl: './check-list.component.html',
  styleUrl: './check-list.component.scss'
})
export class CheckListComponent {

  @Input() items: Array<CheckListModel> = [];


  changeCheckValue(item: any) {
    if (item.target.name) { 
      var it:any =this.items.find(r=>r.id==item.target.name);
      if(it!=null)it.checked = item.target.checked;    
    }
  }

  getItems(){
    return this.items;
  }

  unChecked(){
    this.items.forEach(it => it.checked = false);
  }

}
