
<div class="container-fluid p-0">
    <div class="row g-0">

        <div class="col-xl-12">
            <div class="auth-full-bg pt-lg-5 p-4">
                <div class="w-100">
                    <div class="bg-overlay"></div>
                    <div class="row justify-content-center">                      
                        <div class="col-md-8 col-lg-6 col-xl-5">
                          <div class="card overflow-hidden">
                            <app-spinner [loading]="loading"></app-spinner>
                            <div class="bg-primary-subtle-1">
                              <div class="row">
                                <div class="col-7">
                                  <div class="text-primary p-4">
                                    <h5 class="text-primary">¡Bienvenido de nuevo!</h5>
                                    <p>Inicia sesión para continuar.</p>
                                  </div>
                                </div>
                                <div class="col-5 align-self-end">
                                  <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                                </div>
                              </div>
                            </div>
                            <div class="card-body pt-0">
                              <div>
                                <a routerLink="/">
                                  <div class="avatar-md profile-user-wid mb-4">
                                    <span class="avatar-title rounded-circle bg-light">
                                      <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                                    </span>
                                  </div>
                                </a>
                              </div>
                              <div class="p-2">
                                <form class="form-horizontal" [formGroup]="loginForm">
                                  <div class="mb-3">
                                    <label for="user">Usuario</label>
                                    <input type="text" formControlName="user" class="form-control" id="user" placeholder="Ingresar usuario" [ngClass]="{ 'is-invalid': submitted && f.user.errors }" />
                                  </div>
                  
                                  <div class="mb-3">
                                    <label for="password">Contraseña</label>
                                    <div class="input-group auth-pass-inputgroup">
                                        <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="password" placeholder="Ingresar contraseña" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">

                                        <button class="btn btn-light ms-0" type="button" id="password-addon">
                                          <i class="mdi mdi-eye-outline" [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType}" (click)="toggleFieldTextType()"></i></button>
                                    
                                    </div>
                                </div>
                  
                                  <div id="elmLoader" class="text-center d-none">
                                    <div class="spinner-border text-primary avatar-sm" role="status">
                                      <span class="visually-hidden">Loading...</span>
                                    </div>
                                  </div>
                  
                                  <div class="mt-3 d-grid">
                                    <button class="btn btn-primary" type="button" (click)="onSubmit()">Log In</button>
                                  </div>
                    
                                </form>
                              </div>
                  
                            </div>
                          </div>
                          <div class="mt-5 text-center">
                            <p>© {{year}} Curtiembre Quisapincha, derechos reservados</p>
                          </div>
                  
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>                





