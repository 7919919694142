import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  DatatableComponent,
  DatatableEditorComponent,
  DatepickerComponent,
  InputSearchDualComponent,
  ViewerComponent,
} from '@components/index';
import { PageButtonsComponent, SpinnerComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import moment from 'moment';
import { AuthService } from '../../../../account/services/auth.service';
import { InputSearchDualModel } from '@components/input-search-dual/input-search-dual.model';
import {
  ColumnsEditorModel,
  DatatableEditorModel,
} from '@components/datatable-editor/datatable-editor.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProductEditModalComponent } from '@pages/inventory/products/product-edit/product-edit-modal/product-edit-modal.component';
import { PersonEditModalComponent } from '@pages/persons/person-edit/person-edit-modal/person-edit-modal.component';

@Component({
  selector: 'app-order-edit',
  standalone: true,
  imports: [
    CommonModule,
    DatatableComponent,
    PageButtonsComponent,
    SpinnerComponent,
    ReactiveFormsModule,
    FormsModule,
    DatepickerComponent,
    InputSearchDualComponent,
    DatatableEditorComponent,
  ],
  templateUrl: './order-edit.component.html',
  styleUrl: './order-edit.component.scss',
})
export class OrderEditComponent implements OnInit {
  private http = inject(HttpClient);
  private formBuilder = inject(FormBuilder);
  private auth = inject(AuthService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private msg = inject(AlertService);

  botones: PageButton[];
  title: string = 'Agregar';
  loading: boolean;
  submitted: boolean;
  bloquear: boolean = true;
  minDate: Date | undefined;
  configClient: InputSearchDualModel;
  optionModel: DatatableEditorModel;

  private pedidoId: string;

  @ViewChild('dtpedidos') dtpedidos: any;
  @ViewChild('fechaEntrega') fechaEntrega: any;
  @ViewChild('fechaValidez') fechaValidez: any;
  @ViewChild('searchPersona') searchPersona: any;

  public form: FormGroup;

  constructor(private modalViewer: BsModalService) {}

  ngOnInit(): void {
    this._initBotones();
    this._initDatatable();
    this._initSearchClient();

    this.route.params.subscribe((param) => {
      if (param.id) {
        this.pedidoId = param.id;
        this.title = 'Editar';
        this._obtener();
        this.botones[2].visible = this.pedidoId ? true : false;
      } else {
        this._obtener();
        this.bloquear = false;
      }
    });

    const dFechaValidez = moment().add(30, 'days');
    this.form = this.formBuilder.group({
      codigo: [''],
      cliente: [null, [Validators.required]],
      observacion: [''],
      responsable: [this.auth.currentUser()?.userName, [Validators.required]],
      fechaValidez: [dFechaValidez.toDate(), [Validators.required]],
      fechaEntrega: [new Date(), [Validators.required]],
    });
    this.minDate = new Date();
  }

  get f() {
    return this.form.controls;
  }

  private _initBotones() {
    this.botones = [
      {
        label: 'cancelar',
        icon: 'bx bx-block',
        cssClass: 'btn-warning',
        actionType: () => this._cancelar(),
      },
      {
        label: 'aprobar',
        icon: 'bx bx-check',
        cssClass: 'btn-info',
        visible: false,
        actionType: () => this._aprobar(),
      },
      {
        label: 'imprimir',
        icon: 'bx bx-printer',
        cssClass: 'btn-info',
        visible: false,
        actionType: () => this._print(),
      },
      {
        label: 'guardar',
        icon: 'bx bx-save',
        cssClass: 'btn-info',
        //disabled:true,
        actionType: () => this._guardar(),
      },
    ];
  }

  private _initDatatable() {
    this.optionModel = {
      defaultModel: {
        cantidad: 1,
        precio: '0.00',
        producto: {},
        observacion: '',
        unidad: '',
        subtotal: '0.00',
      },
      columns: this._initColumnsEditor(),
    };
  }

  private _initColumnsEditor(): ColumnsEditorModel[] {
    return [
      {
        title: '...',
        width: 60,
        cell: {
          type: 'button',
          items: [
            {
              class: '',
              type: 'menu',
              items: [
                {
                  title: 'Insertar encima',
                  onclick: (rowIndex: number) =>
                    this._insertBeforeNewRow(rowIndex),
                },
                {
                  title: 'Insertar debajo',
                  onclick: (rowIndex: number) =>
                    this._insertAfterNewRow(rowIndex),
                },
              ],
            },
            {
              class: '',
              icon: 'far fa-trash-alt',
              onclick: (rowIndex: number) => this._deletePedidoRow(rowIndex),
            },
          ],
        },
      },
      {
        title: 'Cant.',
        data: 'cantidad',
        width: 60,
        cell: {
          type: 'input',
          format: 'number',
          required: true,
        },
        onChange: this._inputChange,
      },
      {
        title: 'Producto',
        data: 'producto',
        cell: {
          type: 'search',
          required: true,
          searching: {
            title: 'Buscador de productos terminados',
            url: 'producto/terminado/search',
            codeName: 'codigo',
            valueName: 'nombre',
            changeComponent: ProductEditModalComponent,
            columns: [
              {
                title: 'Codigo',
                data: 'codigo',
                width: '90px',
              },
              {
                title: 'Nombre',
                data: 'nombre',
              },
            ],
          },
        },
      },
      {
        title: 'Precio',
        data: 'precio',
        width: 60,
        cell: {
          type: 'input',
          required: true,
          class: 'text-end',
          format: 'decimal',
        },
        onChange: this._inputChange,
      },
      {
        title: 'Observacion',
        data: 'observacion',
        width: 250,
        cell: {
          type: 'input',
        },
      },
      {
        title: 'Und',
        data: 'unidad',
        width: 55,
        cell: {
          type: 'select',
          required: true,
          selecting: {
            name: 'TP_UNIDAD_CALCULO',
            valueName: 'id',
            labelName: 'codigo',
          },
        },
      },
      {
        title: 'Subtotal',
        data: 'subtotal',
        width: 80,
        cell: {
          type: 'input',
          class: 'text-end',
          readonly: true,
        },
        //type: 'input',
        //readonly: true,
        //class: 'text-end',
      },
    ];
  }

  private _inputChange(tabla: any, data: any, rowIndex: number) {
    const subtotal = (Number(data.precio) || 0.0) * data.cantidad;
    data.subtotal = subtotal.toFixed(2);
    const celda = tabla.cell({ row: rowIndex, column: 6 });
    const inputElement = celda.node().querySelector('input');
    inputElement.value = subtotal.toFixed(2);
  }

  private _deletePedidoRow(rowIndex: number) {
    //console.log('delete')
    this.msg
      .confirm(`¿Desea eliminar la fila [${rowIndex + 1}]?`)
      .then((resul) => {
        if (resul.value) {
          this.dtpedidos.deleteRow(rowIndex);
        }
      });
  }

  private _insertBeforeNewRow(rowIndex: number) {
    this.dtpedidos.insertBeforeNewRow(rowIndex);
  }

  private _insertAfterNewRow(rowIndex: number) {
    this.dtpedidos.insertAfterNewRow(rowIndex);
  }

  private _initSearchClient() {
    this.configClient = {
      url: 'persona/search',
      title: 'Buscador de cliente',
      codeName: 'identificacion',
      valueName: 'razon_social',
      changeComponent: PersonEditModalComponent,
      columns: [
        {
          title: 'Identificacion',
          data: 'identificacion',
          width: '130px',
        },
        {
          title: 'Nombre',
          data: 'razon_social',
        },
      ],
    };
  }

  private _calculeSubtotal(cantidad: any, precio: any) {
    const subtotal = cantidad * precio;
    return subtotal.toFixed(2);
  }

  private _print() {
    this.modalViewer.show(ViewerComponent, {
      class: 'modal-dialog-centered modal-xl',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        url: `pedido/reporte/${this.pedidoId}`,
      },
    });
  }

  private _obtener() {
    this.loading = true;
    this.http
      .get(this.pedidoId ? `pedido/${this.pedidoId}` : 'pedido')
      .subscribe({
        next: (data: any) => {
          if (data) {
            const { catalogos, model, detalle } = data;

            if (model) {
              //console.log('model',model);
              this.form.patchValue({
                responsable: model.usuario,
                codigo: model.numero,
                observacion: model.observacion,
                fechaValidez: moment(model.fechaValidez, 'DD/MM/YYYY').toDate(),
                fechaEntrega: model.fechaEntrega
                  ? moment(model.fechaEntrega, 'DD/MM/YYYY').toDate()
                  : new Date(),
                cliente: {
                  id: model.idPersona,
                  identificacion: model.identificacion,
                  razon_social: model.razon_social,
                },
              });

              this.bloquear = model.estado >= 2;
              this.fechaValidez.disabled = this.bloquear;
              this.fechaEntrega.disabled = this.bloquear;
              this.searchPersona.disabled = this.bloquear;

              this.botones[1].visible = this.pedidoId != null && !this.bloquear;
            }

            this.botones[3].disabled = this.bloquear;

            if (catalogos) {
              this.dtpedidos.setCatalogs(catalogos);
              //console.log(catalogos);
            }

            if (detalle && detalle.length > 0) {
              //console.log('detalle',detalle);

              const rows = detalle.map((it: any) => ({
                id: it.id,
                cantidad: it.cantidad,
                precio: it.precio.toFixed(2),
                observacion: it.observacion || '',
                unidad: catalogos.TP_UNIDAD_CALCULO.find(
                  (it1: any) => it1.id === it.idTipoUnidad
                ),
                subtotal: this._calculeSubtotal(it.cantidad, it.precio),
                producto: it.idProducto
                  ? { id: it.idProducto, codigo: it.codigo, nombre: it.nombre }
                  : {},
              }));
              //console.log('uno');
              this.dtpedidos.setItems(rows);
              //console.log('dos');

              if (this.bloquear) this.dtpedidos.disabled = true;
              //console.log('tres');
            }
          }
          this.loading = false;
        },
        error: (error) => (this.loading = false),
      });
  }

  private _cancelar() {
    this.router.navigate(['inventory/order/list']);
  }

  private _aprobar() {
    this.msg
      .confirm(
        `¿Desea aprobar el pedido [${this.form.value.codigo}]? <br> Recuerde que una vez aprobado no podrá modificarlo.`
      )
      .then((resul) => {
        if (resul.value) {
          this.loading = true;
          this.http.put(`pedido/aprobar/${this.pedidoId}`, null).subscribe({
            next: (data: any) => {
              if (data.exito) {
                this.msg.success(
                  `Pedido [${this.form.value.codigo}] aprobado con éxito`
                );
                //redirect to edit...
                //this.loading = false;
                this._obtener();
                //this.router.navigate([`pedido/edit/${this.pedidoId}`]);
              } else {
                this.loading = false;
                this.msg.error(data.mensaje);
              }
            },
            error: (error) => (this.loading = false),
          });
        }
      });
  }

  private _guardar() {
    //console.log(this.form.value);
    //console.log(this.dtpedidos.getModel());
    var frm = this.form.value;
    this.submitted = true;
    this.loading = true;

    if (this.form.invalid) {
      this.loading = false;
      return;
    }
    if (this.dtpedidos.invalid) {
      this.msg.error(
        'El detalle del pedido contiene campos requeridos sin utilisarse.'
      );
      this.loading = false;
      return;
    }

    var items = this.dtpedidos.getModel();
    //console.log('items',items);

    const model: any = {
      idClinte: frm.cliente?.id,
      observacion: frm.observacion,
      fechaValidez: frm.fechaValidez,
      fechaEntrega: frm.fechaEntrega,
    };

    if (this.pedidoId) {
      model.detalles = items.map((it: any) => ({
        id: it.id,
        idProducto: it.producto.id,
        cantidad: it.cantidad,
        precio: it.precio,
        idUnidad: it.unidad?.id,
        observacion: it.observacion,
      }));
      //console.log(model);
      this.actualizar(model);
    } else {
      model.detalles = items.map((it: any) => ({
        idProducto: it.producto.id,
        cantidad: it.cantidad,
        precio: it.precio,
        idUnidad: it.unidad?.id,
        observacion: it.observacion,
      }));

      //console.log(model);
      this._registrar(model);
    }
  }

  private _registrar(model: any) {
    this.http.post('pedido', model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          //this.form.reset();
          this.submitted = false;
          this.msg.success(`Pedido registrado con éxito`);
          //redirect to edit...
          this.loading = false;
          this.router.navigate([`inventory/order/edit/${data.id}`]);
        } else {
          this.loading = false;
          this.msg.error(data.mensaje);
        }
      },
      error: (error) => (this.loading = false),
    });
  }

  private actualizar(model: any) {
    //console.log(model);
    this.http.put(`pedido/${this.pedidoId}`, model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          //this.form.reset();
          this.submitted = false;
          this.msg.success(`Pedido actualizado con éxito`);
          //redirect to edit...
          this.loading = false;
          this.router.navigate([`inventory/order/edit/${this.pedidoId}`]);
        } else {
          this.loading = false;
          this.msg.error(data.mensaje);
        }
      },
      error: (error) => (this.loading = false),
    });
  }
}
