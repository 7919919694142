import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagenUploadComponent, SelectGridComponent } from '@components/index';
import { AlertService } from '@services/alert.service';
import { PageButtonsComponent, SpinnerComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { sha512 } from 'js-sha512';

@Component({
  selector: 'app-user-edit',
  standalone: true,
  imports: [
    CommonModule,
    PageButtonsComponent,
    ReactiveFormsModule,
    FormsModule,
    SpinnerComponent,
    ImagenUploadComponent,
    SelectGridComponent,
  ],
  templateUrl: './user-edit.component.html',
  styleUrl: './user-edit.component.scss',
})
export class UserEditComponent implements OnInit {
  private formBuilder = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  private editMode: boolean = false;
  private usuarioId: String;

  title: string = 'Nuevo Usuario';
  defaultImageAvatar: any = null;
  botones: PageButton[];
  submitted: boolean;
  loading: boolean;
  actualizarImagen:boolean=false;
  defaultItemRolText: string = 'Seleccione un rol';

  @ViewChild('cmbRoles') cmbRoles: any;

  public userForm: FormGroup;

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      if (param.id) {
        this.usuarioId = param.id;
        this.title = 'Editar Usuario';
        this.editMode = true;
        this._obtener(this.usuarioId);
      } else {
        this._obtener();
      }

      this.userForm = this.formBuilder.group({
        nombre: ['', [Validators.required]],
        correo: ['', [Validators.required, Validators.email]],
        usuario: ['', [Validators.required]],
        password: ['', !this.editMode ? [Validators.required] : null],
        roles: [''],
        image: [null],
      });
    });

    this._initBotones();
  }

  private _initBotones() {
    this.botones = [
      {
        label: 'cancelar',
        icon: 'bx bx-block',
        cssClass: 'btn-warning',
        actionType: () => this._cancelar(),
      },
      {
        label: 'guardar',
        icon: 'bx bx-save',
        cssClass: 'btn-info',
        actionType: () => this._guardar(),
      },
    ];
  }

  private _obtener(id: any = null) {
    this.loading = true;
    this.http.get(id ? `usuario/${id}` : 'usuario').subscribe({
      next: (data: any) => {
        if (data && data.model) {
          this.userForm.patchValue({
            nombre: data.model.nombre,
            correo: data.model.correo,
            usuario: data.model.usuario,
            password:''
          });

          if(data.model.imagen) this.defaultImageAvatar = data.model.imagen;
        }

        if (data && data.catalogos) {
          if (data.catalogos.TB_ROLL && data.catalogos.TB_ROLL.length > 0) {
            var items = data.catalogos.TB_ROLL;
            this.cmbRoles.setItems(items);
            if (data.roles && data.roles.length > 0) {
              this.cmbRoles.setSelectedItems(data.roles);
            }
          }
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  private _cancelar() {
    this.router.navigate(['administration/user/list']);
  }

  private _guardar() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }

    const _f = this.userForm.value;

    const userModel: any = {
      nombre: _f.nombre,
      correo: _f.correo,
      usuario: _f.usuario,
      roles: _f.roles || [],
    };

    const formData: any = new FormData();

    if (this.editMode) {
      let password = null;
      if (_f.password && _f.password.length > 0)
        password = sha512.create().update(_f.password).hex();
      userModel.password = password;
      userModel.id= this.usuarioId;

      userModel.actualizarImagen = this.actualizarImagen;
      formData.append('foto', this.actualizarImagen ? _f.image?.file || null: null);
      
    } else {
      let password = sha512.create().update(_f.password).hex();
      userModel.password = password;
      formData.append('foto', _f.image?.file || null);
    }

    
    

    formData.append(
      'model',
      new Blob([JSON.stringify(userModel)], {
        type: 'application/json',
      })
    );

    if (this.editMode) {
      this._actualizar(formData);
    } else {
      this._registrar(formData);
    }
  }

  private _actualizar(model: any) {
    this.loading = true;

    this.http.post(`usuario/update`, model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.submitted = false;

          this.msg.growl.success(`Usuario actualizado con éxito`);
          this.loading = false;
          this.router.navigate([`administration/user/list`]);
        } else {
          this.loading = false;
        }
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private _registrar(model: any) {
    this.http.post('usuario/create', model).subscribe((data: any) => {
      if (data && data.exito) {
        this.submitted = false;

        this.msg.growl.success(`Usuario registrado con éxito`);

        this.router.navigate(['administration/user/list']);
      }
    });
  }

  get f() {
    return this.userForm.controls;
  }

  uploadedAvatar(image: any) {
    console.log('image',image);
    if(this.editMode)this.actualizarImagen=true;
    this.userForm.patchValue({ image });
  }

  changeRollSelection(event: any) {
    //console.log('changeRollSelection',event);
    if (event) {
      var items: any = this.userForm.value.roles;
      items = items && items.length > 0 ? items : [];

      if (event.action === 'add') {
        items.push(event.value.id);
      } else if (event.action === 'delete') {
        items = items.filter((it: any) => it !== event.value.id);
      }

      this.userForm.patchValue({ roles: items });
    }
  }
}
