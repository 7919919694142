import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  effect,
  inject,
} from '@angular/core';
import { RightsidebarService } from '@services/rightsidebar.service';
import { SimplebarAngularModule } from 'simplebar-angular';

@Component({
  selector: 'app-right-sidebar',
  standalone: true,
  imports: [CommonModule, SimplebarAngularModule],
  templateUrl: './right-sidebar.component.html',
  styleUrl: './right-sidebar.component.scss',
})
export class RightSidebarComponent implements OnInit {
  private rightsidebar = inject(RightsidebarService);
  @ViewChild('componentContainer', { read: ViewContainerRef })
  componentContainer: ViewContainerRef;

  dynamicComponent: any = null;
  dynamicInjector: any;
  autoClose:boolean;

  constructor() {
    effect(() => {
      this.dynamicComponent = this.rightsidebar.current();
      this.autoClose = this.rightsidebar.autoClose;

      if (this.dynamicComponent) {
        setTimeout(() => {
          this.componentContainer.clear();
          this.componentContainer.insert(this.dynamicComponent);
        }, 1);
        this._show();
      }
    });
  }

  ngOnInit(): void {}

  private _show() {
    //const initialValue = this.rightsidebar.initialValue();
    //if (initialValue) this.dynamicInjector = this.createInjector(initialValue);

    document.body.classList.toggle('right-bar-enabled');
  }

  close() {
    this.rightsidebar.clear();
  }

  hide() {
    this.rightsidebar.hide();
  }
}
