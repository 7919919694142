<h5 class="m-0">Registrar configuracion</h5>
<div class="row sidebar-row">
  <div class="col-12 p-0">
    <form [formGroup]="form" autocomplete="off">
      <div class="row mb-1">
        <div class="col-md-12">
          <label class="form-label">Modulo *</label>
          <app-select-catalog
            [ngClass]="{
              'is-invalid': submitted && f.tipoModulo.errors
            }"
            #cmbTipoModulo
            (changeValue)="changeTipoModulo($event)"
          ></app-select-catalog>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-12">
          <label class="form-label">Servidor SMTP *</label>
          <input
            type="text"
            [ngClass]="{
              'is-invalid': submitted && f.servidorSmtp.errors
            }"
            toUppercase
            formControlName="servidorSmtp"
            class="form-control"
          />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-12">
            <label class="form-label">TLS *</label>
            <div class="form-check form-switch form-switch-md m-0">
                <input class="form-check-input" type="checkbox">
            </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-12">
          <label class="form-label">Puerto SMTP *</label>
          <input
            type="text"
            [ngClass]="{
              'is-invalid': submitted && f.servidorSmtp.errors
            }"
            toUppercase
            formControlName="servidorSmtp"
            class="form-control"
          />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-12">
          <label class="form-label">Usuario *</label>
          <input
            type="text"
            [ngClass]="{
              'is-invalid': submitted && f.servidorSmtp.errors
            }"
            toUppercase
            formControlName="servidorSmtp"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="form-label">Contraseña *</label>
          <input
            type="text"
            [ngClass]="{
              'is-invalid': submitted && f.servidorSmtp.errors
            }"
            type="password"
            formControlName="servidorSmtp"
            class="form-control"
          />
        </div>
      </div>
    </form>
  </div>
</div>
