import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import {
  FormulaEventModel,
  FormulaItemAplicacionModel,
  FormulaItemModel,
  FormulaItemPrensadoModel,
  FormulaItemPreparacionModel,
} from './formula.model';
import { AlertService } from '@services/alert.service';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { UUID } from 'angular2-uuid';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DatatablePreparationComponent } from './datatable-formula/datatable-formula.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalSearchComponent } from '..';
import { DataTablesEmitter } from './datatable-formula/datatable.model';
import { ModalSearchAction } from '@components/modal-search/modal-search.model';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-formula-preparation',
  standalone: true,
  imports: [
    CommonModule,
    DataTablesModule,
    UiSwitchModule,
    CollapseModule,
    DatatablePreparationComponent,
  ],
  templateUrl: './formula-preparation.component.html',
  styleUrl: './formula-preparation.component.scss',
})
export class FormulaPreparationComponent implements OnInit, AfterViewInit {
  private msg = inject(AlertService);
  private http = inject(HttpClient);

  dtOptionsPreparacion: any = {};
  dtOptionsPrensado: any = {};

  tabla: any[] = [];

  private dataAplicacionModel: Array<FormulaItemAplicacionModel> = [];
  private dataPreparacionModel: Array<FormulaItemPreparacionModel> = [];
  private dataPrensadoModel: Array<FormulaItemPrensadoModel> = [];
  private _tipoFormula: string;

  prensadoChecked: boolean = false;
  aplicaEditarNombrePaso: boolean = false;
  isCollapsed: boolean = false;

  @ViewChild('dtpreparacion') dtpreparacion: any;
  @ViewChild('dtprensado') dtprensado: any;
  @ViewChild('txtNombrePaso') txtNombrePaso: ElementRef;

  removeEventListener: () => void;

  @Output() onRemovePasoFormula = new EventEmitter<number>();
  @Input() index: number;
  @Input() defaultModel: FormulaItemModel;
  @Input() nombrePaso: any = '';

  @Input() set tipoFormula(tipoFormula: string) {
    this._changeTipoFormula(tipoFormula);
    this._tipoFormula = tipoFormula;
  }

  constructor(private modalProductos: ModalService) {}

  funcionesMenuDisponibles: any = {
    handleInsertRowBefore: (index: number) => this.handleInsertRowBefore(index),
    handleInsertRowAfter: (index: number) => this.handleInsertRowAfter(index),
  };

  ngAfterViewInit(): void {
    if (this.dtpreparacion) {
      this.dtpreparacion.emitter.subscribe(
        ({ index, data, action, callbackName }: DataTablesEmitter) => {
          switch (action) {
            case 'delete':
              this.msg
                .confirm(`¿Desea eliminar el registro [${data.producto}]?`)
                .then((result) => {
                  if (result.value) {
                    this.dtpreparacion.deleteRow(index);
                  }
                });
              break;
            case 'edit':
              this.searhProducto(index, ModalSearchAction.Edit);
              break;
            default:
              if (callbackName) {
                this.funcionesMenuDisponibles[callbackName](index);
              }
              break;
          }
        }
      );
    }

    if (this.dtprensado) {
      this.dtprensado.emitter.subscribe(
        ({ index, data, action, callbackName }: DataTablesEmitter) => {
          switch (action) {
            case 'delete':
              this.msg
                .confirm(`¿Desea eliminar este registro de prensado?`)
                .then((result) => {
                  if (result.value) {
                    this.dtprensado.deleteRow(index);
                  }
                });
              break;
          }
        }
      );
    }
  }

  ngOnInit(): void {
    this._initdefaultValues();
    this._initDatatable();
  }

  private _initDatatable() {
    this.dtOptionsPreparacion = {
      columns: [
        {
          className: 'col--opt-2',
          title: '',
          render: (data: any, type: any, row: any) => {
            const btn1 = `<div class="btn-group dropdown">
            <button type="button" class="btn btn-outline-info btn-xs btn-rounded btn-tb btn-mnu-act dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i style="pointer-events: none;" class="fa-solid fa-fw fa-sm fas fa-ellipsis-v"></i>
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="javascript: void(0)" view-action="menu" data-event="handleInsertRowBefore">Insertar encima</a></li>
              <li><a class="dropdown-item" href="javascript: void(0)" view-action="menu" data-event="handleInsertRowAfter">Insertar debajo</a></li>
            </ul>
          </div>`;
            const btn2 = `<a class="btn btn-outline-danger btn-xs btn-rounded btn-tb" view-action="delete" ><i style="pointer-events: none;" class="fa-solid fa-fw fa-sm far fa-trash-alt"></i></a>`;
            return `${btn1}${btn2}`;
          },
        },
        { title: 'Productos', data: 'producto' },
        {
          className: 'col-opt-link',
          title: '',
          render: (data: any, type: any, row: any) => {
            return `<a class="btn btn-link btn-xs bprl-none" view-action="edit" ><i style="pointer-events: none;" class="fas fa-pencil-alt"></i></a>`;
          },
        },
        {
          title: this._tipoFormula,
          data: 'cantidad',
          className: 'col--opt-gramos',
          render: (data: any) => {
            return `<input class="form-control decimals" autocomplete="off" type="text" value="${data}">`;
          },
        },
        {
          title: 'Tiempo(min)',
          className: 'col--opt-tiempo',
          data: 'tiempo',
          render: (data: any) => {
            return `<input class="form-control times" autocomplete="off" type="text" value="${
              data || ''
            }">`;
          },
        },
        {
          title: 'Comentario',
          data: 'comentario',
          render: (data: any) => {
            return `<input class="form-control coments" autocomplete="off" type="text" value="${
              data || ''
            }">`;
          },
        },
      ],
    };

    this.dtOptionsPrensado = {
      columns: [
        {
          className: 'col--opt-1',
          title: '',
          render: (data: any, type: any, row: any) => {
            /*const btn1 = `<div class="btn-group dropdown">
            <button type="button" class="btn btn-outline-info btn-xs btn-rounded btn-tb btn-mnu-act dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i style="pointer-events: none;" class="fa-solid fa-fw fa-sm fas fa-ellipsis-v"></i>
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="javascript: void(0)" view-action="menu" data-event="handleInsertRowBefore">Insertar encima</a></li>
              <li><a class="dropdown-item" href="javascript: void(0)" view-action="menu" data-event="handleInsertRowAfter">Insertar debajo</a></li>
            </ul>
          </div>`;*/
            const btn2 = `<a class="btn btn-outline-danger btn-xs btn-rounded btn-tb" view-action="delete" ><i style="pointer-events: none;" class="fa-solid fa-fw fa-sm far fa-trash-alt"></i></a>`;
            return `${btn2}`;
          },
        },
        {
          title: 'Placa',
          data: 'placa',
          render: (data: any) => {
            return `<input class="form-control" name="placa" autocomplete="off" type="text" value="${data}">`;
          },
        },
        {
          title: 'Presion',
          data: 'presion',
          render: (data: any) => {
            return `<input class="form-control" name="presion" autocomplete="off" type="text" value="${data}">`;
          },
        },
        {
          title: 'Temperatura',
          data: 'temperatura',
          render: (data: any) => {
            return `<input class="form-control" name="temperatura" autocomplete="off" type="text" value="${data}">`;
          },
        },
        {
          title: 'Tiempo',
          data: 'tiempo',
          render: (data: any) => {
            return `<input class="form-control" name="tiempo" autocomplete="off" type="text" value="${data}">`;
          },
        },
      ],
    };
  }

  private _initdefaultValues() {
    //console.log(this.defaultModel);

    if (this.defaultModel) {
      this.prensadoChecked = this.defaultModel.aplicaPrensado;

      this.dataAplicacionModel = this.defaultModel.aplicacion || [];
      this.dataPrensadoModel = this.defaultModel.prensado || [];
      this.dataPreparacionModel = this.defaultModel.preparacion || [];
    }
  }

  private handleInsertRowBefore(index: number) {
    this.searhProducto(index, ModalSearchAction.InsertUp);
  }

  private handleInsertRowAfter(index: number) {
    this.searhProducto(index, ModalSearchAction.InsertDown);
  }

  private _changeTipoFormula(name: string) {
    var tb: any = this.tabla[0];
    if (tb) {
      var head_item = tb.columns(2).header();
      $(head_item).html(name);
    }
  }

  private _deleteDetalle(url: string, callback: any) {
    this.http.delete(url).subscribe({
      next: (data: any) => {
        if (data.exito) {
          callback();
        }
      },
      error: (error) => {},
    });
  }

  private _getDataFormulaPreparacion(isClone: boolean = false):Array<FormulaItemPreparacionModel> {
    const rows = this.dtpreparacion.getModel() || [];
    return rows.map((it: any,index:number) => {
      const reg: FormulaItemPreparacionModel = {
        index:(index+1),
        producto: it.id,
        cantidad: it.cantidad,
        tiempo: it.tiempo || null,
        comentario: it.comentario || null,
      };

      if (isClone) {
        reg.productoNombre = it.productoNombre;
      } else if (it.idsys) reg.id = it.idsys;

      return reg;
    });
  }

  private _getDataFormulaAplicacion(isClone: boolean = false):Array<FormulaItemAplicacionModel> {
    return this.dataAplicacionModel.map((it:any)=>{
      const reg:FormulaItemAplicacionModel={
        descripcion:it.descripcion  
      };
      if(it.idsys) reg.id=it.idsys
      return reg;
    });
  }

  private _getDataFormulaPrensado(isClone: boolean = false):Array<FormulaItemPrensadoModel> {
    const rows = this.dtprensado.getModel() || [];
    return rows.map((it:any,index:number)=>{
      const reg: FormulaItemPrensadoModel = {
        index:(index+1),
        placa:it.placa || null,
        presion:it.presion || null,
        temperatura:it.temperatura || null,
        tiempo:it.tiempo || null
      };
      if(it.idsys) reg.id=it.idsys
      return reg;
    });
  }

  get tipoFormula(): string {
    return this._tipoFormula;
  }

  get itemsAplicacion(): Array<FormulaItemAplicacionModel> {
    return this.dataAplicacionModel;
  }

  get itemPrensado(): Array<FormulaItemPrensadoModel> {
    return this.dataPrensadoModel;
  }

  get itemPreparacion(): Array<FormulaItemPreparacionModel> {
    return this.dataPreparacionModel;
  }

  changeItemAplicacion(index: number, evt: any) {
    this.dataAplicacionModel[index].descripcion = evt.target.value;
  }

  editarPasoNombre() {
    this.aplicaEditarNombrePaso = true;
    setTimeout(() => {
      this.txtNombrePaso.nativeElement.focus();
      this.txtNombrePaso.nativeElement.value = this.nombrePaso;
      this.nombrePaso = '';
    }, 0);
  }

  onChange(checked: any) {
    this.prensadoChecked = checked;
  }

  removeDetalleAplicacion(id: string | undefined) {
    if (id) {
      this.msg
        .confirm('¿Desea eliminar este detalle de aplicación?')
        .then((result) => {
          if (result.value) {
            var row: any = this.dataAplicacionModel.find((it) => it.id === id);
            //console.log(row);
            if (row.idsys) {
              var that = this;
              this._deleteDetalle(
                `formula/detalle/aplicacion/${row.idsys}`,
                function () {
                  that.dataAplicacionModel = that.dataAplicacionModel.filter(
                    (item) => item.id !== id
                  );
                }
              );
            } else {
              this.dataAplicacionModel = this.dataAplicacionModel.filter(
                (item) => item.id !== id
              );
            }
          }
        });
    }
  }

  changePasoNombre(evt: any) {
    //if(evt.target.value){
    //console.log("change name:",evt.target.value);
    this.nombrePaso = evt.target.value;
    this.aplicaEditarNombrePaso = false;
    //}
  }

  removePaso() {
    this.msg
      .confirm(`¿Desea eliminar el Paso ${this.index + 1}?`)
      .then((result) => {
        if (result.value) {
          this.onRemovePasoFormula.emit(this.index);
        }
      });
  }

  searhProducto(
    index: number | null = null,
    action: ModalSearchAction = ModalSearchAction.Add
  ) {
    const columns = [
      {
        title: 'Codigo',
        data: 'codigo',
        width: '130px',
      },
      {
        title: 'Nombre',
        data: 'nombre',
      },
    ];

    const modalRef = this.modalProductos.show(ModalSearchComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {
        settings: {
          index: index,
          title: 'Buscador de productos',
          url: 'producto/materiaPrima/listar',
          columns: columns,
          action,
        },
      },
    });

    if (modalRef.content) {
      modalRef.content.selection.subscribe(({ index, data, action }: any) => {
        if (this.dtpreparacion) {
          //console.log(data);
          if (index == null && action === ModalSearchAction.Add) {
          
            this.dtpreparacion.addNewRow({
              id: data.id,
              producto: data.nombre,
              codigo: data.codigo,
              cantidad: 0,
            });
          } else if (index >= 0 && action === ModalSearchAction.InsertUp) {
            //console.log('add up');
            this.dtpreparacion.insertBeforeNewRow(index, {
              id: data.id,
              producto: data.nombre,
              codigo: data.codigo,
              cantidad: 0,
            });
          } else if (index >= 0 && action === ModalSearchAction.InsertDown) {
            //console.log('add down');
            this.dtpreparacion.insertAfterNewRow(index, {
              id: data.id,
              producto: data.nombre,
              codigo: data.codigo,
              cantidad: 0,
            });
          } else if (index >= 0 && action === ModalSearchAction.Edit) {
            this.dtpreparacion.editRow(index, {
              id: data.id,
              producto: data.nombre,
              codigo: data.codigo,
            });
          }
        }

        //console.log(this.dtpreparacion,'index',index,action);
      });
    }
    /*if(modalRef.content){
      modalRef.content.option = {
        title:"Buscador de productos"
      };
    }*/
  }

  addPrensado(data: any = undefined) {
    this.dtprensado.addNewRow({
      id: UUID.UUID(),
      placa: '',
      presion: '',
      temperatura: '',
      tiempo: '',
    });

    /*var tb: DataTables.Api = this.tabla[1];
    if (tb) {
      var reg: FormulaItemPrensadoModel;
      if (data) reg = data;
      else {
        reg = {
          id: UUID.UUID(),
          placa: '',
          presion: '',
          temperatura: '',
          tiempo: '',
        };
      }

      this.dataPrensadoModel.push(reg);
      tb.row.add(reg).draw();

      this._initEventPrensado();

    }*/
  }

  addAplicacion() {
    var reg: FormulaItemAplicacionModel = {
      id: UUID.UUID(),
      descripcion: '',
    };

    this.dataAplicacionModel.push(reg);
  }

  getModel(isClone: boolean = false): FormulaItemModel {
    //const resul = {...this.defaultModel};

    var reg: FormulaItemModel = {
      numero: this.index + 1,
      nombrePaso: this.nombrePaso,
      aplicaPrensado: this.prensadoChecked,
      preparacion: this._getDataFormulaPreparacion(isClone),
      aplicacion: this._getDataFormulaAplicacion(isClone),
      prensado: this._getDataFormulaPrensado(isClone),
    };

    if (this.defaultModel && this.defaultModel.idsys)
      reg.id = this.defaultModel.idsys;

    return reg;
  }
}
