import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { DatatableComponent } from '../../../../components';
import moment from 'moment';
import { PageButtonsComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { RightsidebarService } from '@services/rightsidebar.service';
import { CatalogEditComponent } from '../catalog-edit/catalog-edit.component';
import { NavigationExtras, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-catalog-list',
  standalone: true,
  imports: [DatatableComponent, PageButtonsComponent],
  templateUrl: './catalog-list.component.html',
  styleUrl: './catalog-list.component.scss',
})
export class CatalogListComponent implements OnInit {
  private rightsidebar = inject(RightsidebarService);
  private router=inject(Router);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  optionModel = {};
  title: string = 'Listar Catalogos del sistema';
  botones: PageButton[];
  @ViewChild(DatatableComponent) dt: DatatableComponent;

  ngOnInit(): void {
    this._initBotones();
    this._initDatatable();
  }

  private _initBotones() {
    this.botones = [
      {
        label: 'agregar',
        icon: 'bx bx-plus',
        cssClass: 'btn-success',
        actionType: () => this._add(),
      },
    ];
  }

  private _initDatatable() {
    var columns: any = [
      {
        className: 'col--opt-3 no-sort',
        title: '...',
        buttons: [
          {
            icon: 'far fa-eye fa-1x',
            title: 'ver detalles',
            onclick: (row: any) => {
              this._details(row);
            },
          },
          {
            icon: 'fas fa-pencil-alt',
            title: 'editar',
            onclick: (row: any) => {
              this._edit(row.id);
            },
          },
          {
            className: 'btn-outline-danger',
            icon: 'far fa-trash-alt',
            title: 'eliminar',
            onclick: (row: any) => {
              this._delete(row);
            },
          },
        ],
      },
      {
        title: 'Nemonico',
        data: 'nemonico',
      },
      {
        title: 'Descripcion',
        data: 'descripcion',
      },
      {
        title: 'Fecha Creacion',
        data: 'fecha_creacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
      {
        title: 'Fecha Modificacion',
        data: 'fecha_modificacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
    ];

    this.optionModel = {
      filtering: false,
      searching: true,

      serverSide: true,
      columns: columns,
      url: 'catalogo/listar',
    };
  }

  private _details(row:any) {
    const navigationExtras: NavigationExtras = {
      state: {
          catalog:row 
      }
    };
    
    this.router.navigate([`administration/catalog/detail/${row.id}/list`],navigationExtras); 
  }

  private _edit(id: string) {
    const sidebar = this.rightsidebar.show(CatalogEditComponent, {
      settings: {
        id,
      },
    });

    if (sidebar.componentInstance) {
      sidebar.componentInstance.emitter.subscribe(() => {
        this.dt.reload();
      });
    }
  }

  private _add() {
    const sidebar = this.rightsidebar.show(CatalogEditComponent);
    if (sidebar.componentInstance) {
      sidebar.componentInstance.emitter.subscribe(() => {
        this.dt.reload();
      });
    }
  }

  private _delete(data: any) {
    this.msg
      .confirm(
        `<p>¿Estás seguro de que deseas eliminar el tipo catalogo <strong>${data.nemonico}</strong>?</p><p>Esta acción es irreversible.</p>`
      )
      .then(({ value }) => {
        if (value) {
          this.http.delete(`catalogo/${data.id}`).subscribe({
            next: (data: any) => {
              if (data && data.exito) {
                this.msg.growl.success(
                  `Tipo catalogo ${data.nemonico} eliminado con éxito`
                );
                this.dt.reload();
              }
            },
            error: (err) => {
              this.msg.error(err);
            },
          });
        }
      });
  }
}
