<div class="modal-header">
    <h5 class="modal-title mt-0">{{settings.title}}</h5>
    <button type="button" class="btn-close" (click)="close()" aria-hidden="true"></button>
</div>
<div class="modal-body">
    <div style="min-height: 300px;">
        <table datatable [dtOptions]="dtOptions" class="table table-bordered dt-responsive nowrap datatables table-hover no-footer dtr-inline" style="width: 100%">            
        </table> 
    </div>    
</div> 
<div class="modal-footer">
    <button type="button" class="btn btn-secondary"  (click)="close()" >Cancelar</button>
    <button type="button" class="btn btn-secondary"  (click)="agregar()" >Agregar</button>
    <button type="button" class="btn btn-info" [disabled]="enabledAplicarButton" (click)="aplicar()">Aplicar</button>
</div>