import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SpinnerComponent } from '@shared/ui';
import { BsSidebarRef } from '../../../../core/models/sidebar-ref';
import { SelectCatalogComponent } from '@components/index';
import { WfActivityModel } from '@components/workflow-diagram/workflow-diagram.model';

@Component({
  selector: 'app-flow-transition',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnerComponent,
    SelectCatalogComponent,
  ],
  templateUrl: './flow-transition.component.html',
  styleUrl: './flow-transition.component.scss',
})
export class FlowTransitionComponent implements OnInit, AfterViewInit {
  private formBuilder = inject(FormBuilder);

  private nodes: any[] = [];
  private linkId: string;
  private model: any;

  title: string = 'Nueva';
  submitted: boolean = false;
  loading: boolean = true;
  form: FormGroup;
  catalogoTarget: any[] = [];
  catalogoSource: any[] = [];
  catalogoSourceAnchor: any[] = [];
  catalogoTargetAnchor: any[] = [];

  emitter: EventEmitter<WfActivityModel> = new EventEmitter();

  @ViewChild('cmbSource') cmbSource: any;
  @ViewChild('cmbSourceAnchor') cmbSourceAnchor: any;
  @ViewChild('cmbTarget') cmbTarget: any;
  @ViewChild('cmbTargetAnchor') cmbTargetAnchor: any;

  constructor(private sidebar: BsSidebarRef) {}

  ngAfterViewInit(): void {
    //this._init();
  }

  ngOnInit(): void {
    const { settings } = this.sidebar;
    this.nodes = settings.nodes || [];

    this.form = this.formBuilder.group({
      source: [null, [Validators.required]],
      sourceAnchor: [null, Validators.required],
      target: [null, [Validators.required]],
      targetAnchor: [null, Validators.required],
    });

    if (settings?.model?.id) {
      this.linkId = settings.model.id;
      this.model = settings.model;

      this.title = 'Actualizar';

    }

    this._init();
  }

  get f() {
    return this.form.controls;
  }

  private _init() {
    const items = this.nodes.map((it: any) => ({
      id: it.id,
      nombre: it.text,
    }));

    this.catalogoSource = this.catalogoTarget = items;
    this.catalogoSourceAnchor = this.catalogoTargetAnchor = [
      {
        id: 'top',
        nombre: 'top',
      },
      {
        id: 'right',
        nombre: 'right',
      },
      {
        id: 'bottom',
        nombre: 'bottom',
      },
      {
        id: 'left',
        nombre: 'left',
      },
    ];

    if (this.model && this.linkId) {
      const {source,sourceAnchor,target,targetAnchor}=this.model;
      setTimeout(() => {
        if(source){
          this.cmbSource.setSelectedItem(this.catalogoSource.find((it:any)=>it.id===source));
          this.form.patchValue({source});
        }
        if(sourceAnchor){

          if (this.catalogoSourceAnchor.findIndex(item => item.id === sourceAnchor) === -1) {
            this.catalogoSourceAnchor.push({ id: sourceAnchor, nombre: sourceAnchor });
            this.cmbSourceAnchor.setItems(this.catalogoSourceAnchor);
          }

          this.cmbSourceAnchor.setSelectedItem(this.catalogoSourceAnchor.find((it:any)=>it.id===sourceAnchor));
          this.form.patchValue({sourceAnchor});
        }
        if(target){
          this.cmbTarget.setSelectedItem(this.catalogoTarget.find((it:any)=>it.id===target));
          this.form.patchValue({target});
        }
        if(targetAnchor){
          
          if (this.catalogoTargetAnchor.findIndex(item => item.id === targetAnchor) === -1) {
            this.catalogoTargetAnchor.push({ id: targetAnchor, nombre: targetAnchor });
            this.cmbTargetAnchor.setItems(this.catalogoTargetAnchor);
          }

          this.cmbTargetAnchor.setSelectedItem(this.catalogoTargetAnchor.find((it:any)=>it.id===targetAnchor));
          this.form.patchValue({targetAnchor});
        }
        //console.log(this.cmbSourceAnchor);
      }, 1);
    }

    //this.cmbSource.setItems(items);
    //this.cmbTarget.setItems(items);
  }

  changeSource(event: any) {
    this.form.patchValue({ source: event?.value });
  }

  changeSourceAnchor(event: any) {
    this.form.patchValue({ sourceAnchor: event?.value });
  }

  changeTarget(event: any) {
    this.form.patchValue({ target: event?.value });
  }

  changeTargetAnchor(event: any) {
    this.form.patchValue({ targetAnchor: event?.value });
  }

  guardar() {
    const { source, sourceAnchor, targetAnchor, target } = this.form.value;

    let model:any = {
      source,
      sourceAnchor,
      target,
      targetAnchor,
    };

    if(this.linkId) model.id = this.linkId;

    this.emitter.emit({
      type: 'flow',
      action: this.linkId ? 'update':'create',
      data: model,
    });

    this.sidebar.close();
  }
}
