import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SpinnerComponent } from '@shared/ui';
import { BsSidebarRef } from '../../../../core/models/sidebar-ref';
import { WfActivityModel } from '@components/workflow-diagram/workflow-diagram.model';

@Component({
  selector: 'app-flow-block',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnerComponent,
  ],
  templateUrl: './flow-block.component.html',
  styleUrl: './flow-block.component.scss'
})
export class FlowBlockComponent implements OnInit{
  
  private formBuilder = inject(FormBuilder);

  title: string = 'Nueva';
  submitted: boolean = false;
  loading: boolean = true;
  form: FormGroup;

  private model:any;
  private nodeId:string;

  emitter: EventEmitter<WfActivityModel> = new EventEmitter();

  constructor(private sidebar: BsSidebarRef){}
  
  ngOnInit(): void {
    
    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
    });

    this.model = this.sidebar.settings?.model;
    if(this.model?.id){
      this.title = "Actualizar";
      const {nombre,id}= this.model;
      this.nodeId = id;
      this.form.patchValue({nombre});
    }
    
  }

  get f() {
    return this.form.controls;
  }

  guardar(){
    const {nombre} = this.form.value;
    this.emitter.emit({
      type:'step',
      action: this.nodeId ? 'update' : 'create',
      data:{
        id:this.nodeId,
        nombre
      }
    })
    this.sidebar.close();
  }

}
