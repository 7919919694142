import { Injectable, signal } from '@angular/core';
import { AuthStatus, CheckTokenResponse, Menu, User } from '../interfaces';
import { SecureCookieService } from './secure-cookie.service';
import { MenuService } from './menu.service';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthUtilService {
  
  private _currentUser = signal<User | null>(null);
  private _authStatus = signal<AuthStatus>(AuthStatus.checking);

  constructor(
    private cookieService: SecureCookieService,
    private menu: MenuService,
    private http: HttpClient
  ) { }

  public setAuthentication(user: User, menus: Menu[], token:string|null=null): boolean {
    this._currentUser.set(user);
    this.menu.setMenu(user.userId, menus);
    this._authStatus.set(AuthStatus.authenticated);
    if(token) this.cookieService.setToken(token);
    return true;
  }
  
  public get currentUser() {
    return this._currentUser();
  }

  public get authStatus() {
    return this._authStatus();
  }

  public get cookie (){
    return this.cookieService;
  }

  public checkAuthStatus(): Observable<boolean> {
    const url = 'seguridad/check-token';
    const token = this.cookieService.getToken();

    if (!token) {
      this.logout();
      return of(false);
    }

    return this.http.get<CheckTokenResponse>(url).pipe(
      map(response => {
        if (response) {
          const { user, token } = response;
          return this.setAuthentication(user, [],token);
        }
        this._authStatus.set(AuthStatus.notAuthenticated);
        return false;
      }),
      catchError((err) => {
        console.error(err);
        this._authStatus.set(AuthStatus.notAuthenticated);
        return of(false);
      })
    );   

  }

  public logout() {
    this.cookieService.logout();
    this._currentUser.set(null);
    this.menu.clear();
    this._authStatus.set(AuthStatus.notAuthenticated);
  }

}
