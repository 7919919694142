<div class="container-fluid">
  <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
  <div>
    <app-spinner [loading]="loading"></app-spinner>
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              
              <form [formGroup]="form" autocomplete="off">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-1">
                          <label class="form-label">Tipo Proceso *</label>
                          <app-select-catalog
                            [ngClass]="{
                              'is-invalid': submitted && f.tipoProceso.errors
                            }"
                            #cmbTipoProceso
                            (changeValue)="changeTipoProceso($event)"
                          ></app-select-catalog>
                          <div
                            *ngIf="submitted && f.tipoProceso.errors"
                            class="invalid-feedback"
                          >
                            <span *ngIf="f.tipoProceso.errors.required"
                              >Campo de tipo de proceso es requerido</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-1">
                          <label class="form-label">Tipo Calculo *</label>
                          <app-select-catalog
                            [ngClass]="{
                              'is-invalid': submitted && f.tipoCalculo.errors
                            }"
                            #cmbTipoCalculo
                            [aplicaJeraquia]="true"
                            [selectOneItem]="true"
                            (changeValue)="changeTipoCaculo($event)"
                          ></app-select-catalog>
                          <div
                            *ngIf="submitted && f.tipoCalculo.errors"
                            class="invalid-feedback"
                          >
                            <span *ngIf="f.tipoCalculo.errors.required"
                              >Campo de tipo de calculo es requerido</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-1">
                          <label class="form-label">Tipo Formula *</label>
                          <app-select-catalog
                            [ngClass]="{
                              'is-invalid': submitted && f.tipoFormula.errors
                            }"
                            #cmbTipoFormula
                            [aplicaJeraquia]="true"
                            [selectOneItem]="true"
                            (changeValue)="changeTipoFormula($event)"
                          ></app-select-catalog>
                          <div
                            *ngIf="submitted && f.tipoFormula.errors"
                            class="invalid-feedback"
                          >
                            <span *ngIf="f.tipoFormula.errors.required"
                              >Campo de tipo formula es requerido</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-1">
                          <label for="i-password" class="form-label"
                            >Materia Prima *</label
                          >
                          <app-select-catalog
                            [ngClass]="{
                              'is-invalid': submitted && f.tipoMateriaPrima.errors
                            }"
                            #cmbTipoMateriaPrima
                            [aplicaJeraquia]="true"
                            [selectOneItem]="true"
                            (changeValue)="changeTipoMateriaPrima($event)"
                          ></app-select-catalog>
                          <div
                            *ngIf="submitted && f.tipoMateriaPrima.errors"
                            class="invalid-feedback"
                          >
                            <span *ngIf="f.tipoMateriaPrima.errors.required"
                              >Campo de tipo materia es requerido</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-1">
                      <label for="i-color" class="form-label">Color</label>
                      <div #colboxpickerval class="colorpickerval"></div>
                      <div class="input-group">
                        <input
                          class="form-control"
                          readonly="true"
                          autocomplete="off"
                          formControlName="color"
                          type="text"
                          id="i-color"
                        />
                        <button class="btn btn-info" (click)="openSelectColor()">
                          <i class="fa fa-eye-dropper"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
    
                <div class="row">
                  <div class="col-md-5">
                    <div class="mb-1">
                      <label class="form-label">Codigo *</label>
                      <app-input-mask
                        #inputCodeFormula
                        [ngClass]="{ 'is-invalid': submitted && f.codigo.errors }"
                        formControlName="codigo"
                        [mask]="codigoFormula"
                      ></app-input-mask>
                      <div
                        *ngIf="submitted && f.codigo.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.codigo.errors.required"
                          >Campo de codigo es requerido</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="mb-1">
                      <label class="form-label">Nombre *</label>
                      <input
                        [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"
                        formControlName="nombre"
                        class="form-control"
                      />
                      <div
                        *ngIf="submitted && f.nombre.errors"
                        class="invalid-feedback"
                      >
                        <span *ngIf="f.nombre.errors.required"
                          >Campo de nombre es requerido</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12"> 
          @for(model of formulaItemModels; track $index) {
            <app-formula-preparation #cmp 
            [index]="$index" 
            [defaultModel]="model"
            [nombrePaso]="model.nombrePaso"
            [tipoFormula]="tipoCalculo"                
            (onRemovePasoFormula)="onRemovePasoFormula($event)"></app-formula-preparation>
          }         
        </div>
      </div>
  </div>
  
</div>
