import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, forwardRef, inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalSearchComponent } from '..';
import { InputSearchDualModel } from './input-search-dual.model';
import { ProductEditModalComponent } from '@pages/inventory/products/product-edit/product-edit-modal/product-edit-modal.component';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-input-search-dual',
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSearchDualComponent),
      multi: true,
    },
  ],
  templateUrl: './input-search-dual.component.html',
  styleUrl: './input-search-dual.component.scss',
})
export class InputSearchDualComponent implements OnInit, ControlValueAccessor {
  private http = inject(HttpClient);

  imageUrl: string = 'fa fa-search';
  search: string = '';
  auxsearch: string = '';
  resul: string = '';
  _disabled: boolean = false;

  private value: any;

  private onChange: any = () => {};
  private onTouch: any = () => {};

  @Input() config: InputSearchDualModel;

  ngOnInit(): void {}

  constructor(private modalService: ModalService) {}

  writeValue(obj: any): void {
    this.value = obj;
    if (obj) this._aplicar(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /*8setDisabled?(isDisabled: boolean): void {
    console.log('isDisabled',isDisabled)
    this._disabled = isDisabled;
  }*/
  set disabled(value: boolean) {
    this._disabled = value;
  }

  onEnterPressed() {
    this._loadingSearch(true);
    this.openSearch();
  }

  /*set disabled(value: boolean) {
    this._disabled = value;
  }*/

  onChangeInput(value: any) {
    if (this.auxsearch.length > 0 && this.search !== this.auxsearch) {
      this.resul = '';
      this.auxsearch = '';
      this.onChange(null);
    }
  }

  private _loadingSearch(loading: boolean) {
    this.imageUrl = loading
      ? 'spinner-border spinner-border-sm'
      : 'fa fa-search';
  }

  private openSearch() {
    var model: any = {
      page: 1,
      pageSize: 10,
      sortBy: '',
      search: this.search,
      sortDirection: '',
    };
    this.http.post(this.config.url, model).subscribe({
      next: (data: any) => {
        if (data) {
          //console.log(data);
          const { rows, totalRows } = data;

          if (totalRows > 1) {
            this._openModal({ rows, totalRows });
          } else if (totalRows == 1) {
            this._aplicar(rows[0]);
          } else {
            this._aplicar(null);
          }
        }

        this._loadingSearch(false);
      },
      error: (error) => this._loadingSearch(false),
    });
  }

  private _openModal(data: any) {
    const modalRef = this.modalService.show(ModalSearchComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {
        settings: {
          title: this.config.title,
          data,
          search: this.search,
          url: this.config.url,
          columns: this.config.columns,
          pageLength: 10,
        },
      },
    });

    if (modalRef.content) {
      modalRef.content.selection.subscribe(({ index, data }: any) => {
        this._aplicar(data);
      });

      modalRef.content.change.subscribe((data: any) => {
        //console.log("cambiar");
        if (this.config.changeComponent) {
          const modalChange = this.modalService.show(
            this.config.changeComponent,
            {
              class: 'modal-dialog-centered modal-lg',
            }
          );
          if (modalChange) {
            modalChange?.content?.onSuccess?.subscribe((data: any) => {
              modalRef.content.reload();
            });
          }
        }
        /*const modalAction = this.modalAction.show(ProductEditModalComponent, {
          class: 'modal-dialog-centered modal-lg',
          ignoreBackdropClick: true,
          keyboard: false,
        });*/
      });
    }
  }

  private _aplicar(row: any) {
    this.search =
      row != null && this.config.codeName ? row[this.config.codeName] : '';
    this.auxsearch = this.search;
    this.resul =
      row != null && this.config.valueName ? row[this.config.valueName] : '';
    this.onChange(row);
  }
}
