import { Component } from '@angular/core';

@Component({
  selector: 'app-proveedor-edit',
  standalone: true,
  imports: [],
  templateUrl: './proveedor-edit.component.html',
  styleUrl: './proveedor-edit.component.scss'
})
export class ProveedorEditComponent {

}
