<div class="modal-header">
    <h5 class="modal-title mt-0">Seleccionar color</h5>
    <button type="button" class="btn-close" (click)="close()" aria-hidden="true"></button>
</div>
<div class="modal-body">
    <div class="compbody">
        <input type='file' accept="image/*" (change)="readUrl($event)">
        <div class="main">
       <canvas width="450" height="350" #canvasval class="hideData" [class.displayData]="displayData" (click)="getPixel($event)">
       </canvas>
      
       <div class="data hideData" [class.displayData]="displayData">
       <h6>Preview</h6> 
       <div #colboxval class="colorval" [class.displayCol]="displayCol"></div>
       <h6>HEX Value:</h6> 
       <input type="text" readonly class="form-control" value={{_hexval}}>
       <h6>RGBA Value:</h6> 
      <input type="text" readonly class="form-control" value={{_rgbaval}}>
      </div>
      </div>
    </div>
</div> 
<div class="modal-footer">
    <button type="button" class="btn btn-secondary"  (click)="close()" >Cancelar</button>
    <button type="button" class="btn btn-info" [disabled]="enabledAplicarButton" (click)="aplicar()">Aplicar</button>
</div>