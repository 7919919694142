import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '@services/alert.service';
import { SpinnerComponent } from '@shared/ui';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-viewer',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    
  ],
  templateUrl: './viewer.component.html',
  styleUrl: './viewer.component.scss'
})
export class ViewerComponent implements OnInit  {
  
  private http = inject(HttpClient);
  private sanitizer = inject(DomSanitizer);
  private modal = inject(BsModalRef);
  private msg = inject(AlertService);

  @Input() public url:string;

  myBlobURL:any;
  loading:boolean = true;

  ngOnInit(): void {
    this._obtener();
  }

  close(){
    this.modal.hide();
  }

  private _obtener(){
    if(this.url){
      this.http.get(`${this.url}`,{
        responseType : 'arraybuffer'
      }).subscribe({
        next: (response: any) => {   

          let tempBlob = new Blob([response], { type: 'application/pdf' }); 
          var fileURL = window.URL.createObjectURL(tempBlob);
          this.myBlobURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

          setTimeout(() => {
            this.loading =false;
          }, 100);          

        },
        error: (err) => {
          this.loading=false
          this.modal.hide();
          if(err.error && err.message) this.msg.error(err.message);          
        },
      });
    }
  }
}
