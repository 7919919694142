import { Component, OnInit, inject } from '@angular/core';
import { DatatableComponent } from '../../../../components';
import moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-flow-list',
  standalone: true,
  imports: [
    DatatableComponent
  ],
  templateUrl: './flow-list.component.html',
  styleUrl: './flow-list.component.scss'
})
export class FlowListComponent implements OnInit {

  private router=inject(Router);

  optionModel = {};

  ngOnInit(): void {
    this._initDatatable();
  }

  private _initDatatable(){
      var columns:any = [
        { 
          className:'col--opt-3 no-sort', 
          title:'...', 
          buttons:[
            {
              icon:"far fa-file-pdf",            
              onclick:(row:any)=>{
                this._viewer(row.id);
              }
            },
            {
              icon:"fas fa-pencil-alt",            
              onclick:(row:any)=>{
                this._edit(row.id);
              }
            },
            {            
              className: 'btn-outline-danger',
              icon:"far fa-trash-alt",            
              onclick:(row:any)=>{              
                /*message.fire({               
                  text: `¿Desea eliminar el registro [${row.nombre}]?`,
                  showCancelButton: true,
                  confirmButtonColor: '#00a1e1',
                  cancelButtonColor: '#ccc',
                  confirmButtonText: 'Si',
                  cancelButtonText:'No'
                }).then(result => {
                  if (result.value) {
                  //this._delete(row.id,row.nombre);
                  }
                });*/
              }
            },
          ]              
        },
        {
          title: 'Nombre',
          data: 'nombre'
        },     
        {
          title:'Procesos',
          data: 'procesos'
        }, 
        {
          title: 'Fecha Creacion',
          data: 'fecha_creacion',
          render:(data: any)=>{
            var stillUtc = moment.utc(data).toDate();
            var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm')
            return local;
          }
        },     
        {
          title: 'Fecha Modificacion',
          data: 'fecha_modificacion',
          render:(data: any)=>{
            var stillUtc = moment.utc(data).toDate();
            var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm')
            return local;
          }
        },          
        
      ]

      this.optionModel ={      
        filtering:false,
        searching:true,
        
        serverSide:true,
        columns: columns,
        url:"flujo/listar",  
      }
  }

  private _viewer(id:string){
    alert(id);
  }

  private _edit(id:string){
    this.router.navigate([`administration/flow/edit/${id}`]);
  }
}
