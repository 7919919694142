<app-spinner [loading]="loading"></app-spinner>
<h5 class="m-0">Catalogo detalle</h5>
<h6 cslass="mt-4 mb-3">{{ title }}</h6>
<hr />
<form [formGroup]="form" autocomplete="off">
  <div class="row">
    <div *ngIf="aplicaCatalogoPadre" class="col-md-12">
      <div class="mb-1">
        <label class="form-label">Catalogo Dependiente*</label>
        <app-select-catalog
          [size]="'sm'"
          [ngClass]="{ 'is-invalid': submitted && f.catalogoPadre.errors }"
          #cmbTipoCatalogoDependencia
          (changeValue)="changeTipoCatalogoDependencia($event)"
        ></app-select-catalog>
        <div
          *ngIf="submitted && f.catalogoPadre.errors"
          class="invalid-feedback"
        >
          <span *ngIf="f.catalogoPadre.errors.required"
            >Catalogo padre es requerido</span
          >
        </div>
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-nombre" class="form-label">Nombre *</label>
        <input
          [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"
          class="form-control form-control-sm"
          autocomplete="off"
          formControlName="nombre"
          type="text"
          id="i-nombre"
        />
        <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
          <span *ngIf="f.nombre.errors.required"
            >Nombre de catalogo es requerido</span
          >
        </div>
      </div>
    </div>
  </div>
  @if(aplicaCodigoDetalle){
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-codigo" class="form-label">Codigo</label>
        <input
          class="form-control form-control-sm"
          autocomplete="off"
          formControlName="codigo"
          type="text"
          id="i-codigo"
        />
      </div>
    </div>
  </div>
  }
  @if(aplicaValorDetalle){
    <div class="row">
      <div class="col-md-12">
        <div class="mb-1">
          <label for="i-valor" class="form-label">Valor</label>
          <input
            class="form-control form-control-sm"
            autocomplete="off"
            formControlName="valor"
            type="text"
            id="i-valor"
          />
        </div>
      </div>
    </div>
    }
</form>
<hr />

<button
  type="button"
  class="btn btn-primary btn-sm btn-info"
  (click)="guardar()"
>
  <i class="bx bx-save font-size-16 align-middle me-2"></i>
  Guardar
</button>
