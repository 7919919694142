import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectCatalogComponent } from '@components/index';

@Component({
  selector: 'app-company-correo-edit',
  standalone: true,
  imports: [
    CommonModule, 
    SelectCatalogComponent,
    ReactiveFormsModule,
    FormsModule],
  templateUrl: './company-correo-edit.component.html',
  styleUrl: './company-correo-edit.component.scss'
})
export class CompanyCorreoEditComponent implements OnInit {
  
  private formBuilder = inject(FormBuilder);

  submitted: boolean;
  editMode: boolean;
  form: FormGroup;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      tipoModulo: ['', [Validators.required]],
      servidorSmtp:['', [Validators.required]]
    });
  }

  get f() {
    return this.form.controls;
  }

  changeTipoModulo(item:any){

  }
}
