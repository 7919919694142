<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="sub-menu-slink metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      @for (item of menuItems; track $index) {
      <ng-container>
        @if(item.isTitle){
        <li class="menu-title">{{ item.label! | translate }}</li>
        }@else if(!item.isTitle && !item.isLayout){
        <li>
          @if(hasItems(item)){
          <a href="javascript:void(0);" class="is-parent" [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
            @if(item.icon){
            <i class="bx {{item.icon}}"></i>
            }
            <span> {{ item.label! | translate }}</span>
            <!--@if(item.badge){
            <span class="badge rounded-pill float-end bg-{{item.badge.variant}}">{{item.badge.text | translate}}</span>
            }-->
          </a>}
          @else{
          <a routerLink="{{item.link}}" class="side-nav-link-ref" routerLinkActive="active">
            @if(item.icon){
            <i class="bx {{ item.icon }}"></i>
            }
            <span> {{ item.label! | translate }}</span>
            <!--@if(item.badge){
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end">{{item.badge.text |
              translate}}</span>
            }-->
          </a> 
          }
          @if(hasItems(item)){
          <ul class="sub-menu" aria-expanded="false">
            @for (subitem of item.subItems; track $index) {
            <li [ngClass]="{'sub-item-slink':subitem.shorcut?.link}">
              @if(hasItems(subitem)){
              <a class="side-nav-link-a-ref has-arrow" href="javascript:void(0);" [attr.data-parent]="subitem.parentId">
                {{ subitem.label | translate}}
              </a>
              <ul class="sub-menu mm-collapse" aria-expanded="false">
                @for (subSubitem of subitem.subItems; track $index) {
                <li [ngClass]="{'sub-item-slink':subSubitem.shorcut?.link}">
                  <a [attr.data-parent]="subSubitem.parentId" routerLink="{{subSubitem.link}}" routerLinkActive="active" class="side-nav-link-ref sub-item-sslabel">
                    {{ subSubitem.label | translate }}
                  </a>
                  @if(subSubitem.shorcut?.link){
                    <a
                      [routerLink]="subSubitem.shorcut?.link"
                      [attr.data-parent]="subSubitem.parentId"
                      routerLinkActive="active"
                      class="side-nav-link-ref sub-item-sslink"
                      ><i class="bx bx-plus-circle"></i>
                    </a>}
                </li>
                }
              </ul>}
              @else{
              <a routerLink="{{subitem.link}}" class="side-nav-link-ref sub-item-sslabel" [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                {{ subitem.label | translate}}
                <!--@if(subitem.badge){
                <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end">{{subitem.badge.text | translate}}</span>
                }-->
              </a>
              @if(subitem.shorcut?.link){
                <a
                  [routerLink]="subitem.shorcut?.link"
                  [attr.data-parent]="subitem.parentId"
                  routerLinkActive="active"
                  class="side-nav-link-ref sub-item-sslink"
                  ><i class="bx bx-plus-circle"></i>
                </a>}
              }
            </li>
            }
          </ul>
          }
        </li>
        }
      </ng-container>
      }
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    @if(isCondensed){
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    }@else {
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    }
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
