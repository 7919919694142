import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { dia, shapes, highlighters, linkTools } from 'jointjs';
import {
  NodeRefModel,
  WfActivityModel,
  WorkFlowModel,
} from './workflow-diagram.model';

@Component({
  selector: 'app-workflow-diagram',
  standalone: true,
  imports: [],
  templateUrl: './workflow-diagram.component.html',
  styleUrl: './workflow-diagram.component.scss',
})
export class WorkflowDiagramComponent implements OnInit, AfterViewInit {
  //@ViewChild('diagramContainer') elementRef: ElementRef;

  private graph: any;
  private paper: any;
  private graphBBox: any;
  private fontAttributes: any;

  private unit: number = 4;
  private bevel: number;
  private spacing: number;
  private timerLink: any;
  private timerStep: any;
  private paperWidth:number; //= this.paper.options.width;
  private paperHeight:number; // = this.paper.options.height;


  private item: Map<string, NodeRefModel> = new Map<string, NodeRefModel>();

  @Output() nodedblclick: EventEmitter<WorkFlowModel> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.fontAttributes = {
      fontFamily: 'PPFraktionSans, sans-serif',
      fontStyle: 'normal',
      fontSize: 14,
      lineHeight: 18,
    };

    document.body.classList.toggle('light-theme');
  }

  ngAfterViewInit(): void {
    //const { dia, shapes, highlighters, linkTools } = joint;

    // Styles
    const bevel = (this.bevel = 2 * this.unit);
    const spacing = (this.spacing = 2 * this.unit);
    const flowSpacing = this.unit / 2;

    const rootEl = this.elementRef.nativeElement;
    rootEl.style.setProperty('--flow-spacing', `${flowSpacing}px`);

    // Paper & Graph
    const paperContainer = rootEl.querySelector('#canvas');
    const graph = (this.graph = new dia.Graph({}, { cellNamespace: shapes }));

    const paper = (this.paper = new dia.Paper({
      model: graph,
      cellViewNamespace: shapes,
      el: paperContainer,
      width: '100%',
      height: '100%',
      async: true,
      sorting: dia.Paper.sorting.APPROX,
      background: { color: 'transparent' },
      snapLabels: true,
      clickThreshold: 10,
      interactive: {
        linkMove: false,
      },
      gridSize: 5,
      defaultConnectionPoint: {
        name: 'boundary',
        args: {
          offset: spacing,
          extrapolate: true,
        },
      },
      defaultRouter: { name: 'rightAngle', args: { margin: this.unit * 7 } },
      defaultConnector: {
        name: 'straight',
        args: { cornerType: 'line', cornerPreserveAspectRatio: true },
      }, // bevelled path
    }));

    this.paperWidth = this.paper.options.width;
    this.paperHeight = this.paper.options.height;

    this._init();

    //this.graphBBox = graph.getBBox();
    //window.addEventListener("resize", () => this._transformToFitContent());
    //this._transformToFitContent();
    this._initEvent();
  }

  private _init() {
    const model: NodeRefModel = {
      type: 'start',
      text: 'Start',
      position: { x: 20, y: 30 },
    };

    const start: any = this._createStart(model);
    const { id } = start;
    this.item.set(start.id, { ...model, id, data: start });

    this.graph.addCells([
      start,
      //addToCart,
      //this._createFlow(start, addToCart, "right", "left"),
    ]);
  }

  private _initEvent() {
    const { mask: MaskHighlighter, stroke: StrokeHighlighter } = highlighters;

    this.paper.on('cell:mouseenter', (cellView: any, evt: any) => {
      let selector, padding;
      if (cellView.model.isLink()) {
        if (StrokeHighlighter.get(cellView, 'selection')) return;
        // In case of a link, the frame is added around the label.
        selector = { label: 0, selector: 'labelBody' };
        padding = this.unit / 2;
      } else {
        selector = 'body';
        padding = this.unit;
      }
      const frame: any = MaskHighlighter.add(cellView, selector, 'frame', {
        padding,
        layer: dia.Paper.Layers.FRONT,
        attrs: {
          'stroke-width': 1.5,
          'stroke-linejoin': 'round',
        },
      });
      frame.el.classList.add('jj-frame');
    });

    this.paper.on('cell:mouseleave', (cellView: any) => {
      MaskHighlighter.removeAll(this.paper, 'frame');
    });

    this.paper.on('link:pointerclick', (cellView: any) => {
      this.paper.removeTools();
      dia.HighlighterView.removeAll(this.paper);
      const snapAnchor = function (coords: any, endView: any) {
        const bbox = endView.model.getBBox();
        // Find the closest point on the bbox border.
        const point = bbox.pointNearestToPoint(coords);
        const center = bbox.center();
        // Snap the point to the center of the bbox if it's close enough.
        const snapRadius = 10;
        if (Math.abs(point.x - center.x) < snapRadius) {
          point.x = center.x;
        }
        if (Math.abs(point.y - center.y) < snapRadius) {
          point.y = center.y;
        }
        return point;
      };
      const toolsView: any = new dia.ToolsView({
        tools: [
          new linkTools.TargetAnchor({
            snap: snapAnchor,
            resetAnchor: cellView.model.prop(['target', 'anchor']),
          }),
          new linkTools.SourceAnchor({
            snap: snapAnchor,
            resetAnchor: cellView.model.prop(['source', 'anchor']),
          }),
        ],
      });
      toolsView.el.classList.add('jj-flow-tools');
      cellView.addTools(toolsView);
      // Add copy of the link <path> element behind the link.
      // The selection link frame should be behind all elements and links.
      const strokeHighlighter: any = StrokeHighlighter.add(
        cellView,
        'root',
        'selection',
        {
          layer: dia.Paper.Layers.BACK,
        }
      );
      strokeHighlighter.el.classList.add('jj-flow-selection');

      //console.log('pointerclick');
    });

    this.paper.on('blank:pointerdown', () => {
      //console.log('pointerdown');
      this.paper.removeTools();
      dia.HighlighterView.removeAll(this.paper);
    });

    this.paper.model.on('change:source change:target', (link: any) => {
      clearTimeout(this.timerLink);
      this.timerLink = setTimeout(() => {
        const{id,attributes}=link;
        const{source,target,data}= attributes;
        //console.log(attributes);

        const v:any = this.item.get(id);
        if(v){
          //link.attributes.data = data;
          const c = v.data;
          c.attributes.data = {
            ...data,
            source:source.id,
            sourceAnchor:source.anchor.name,
            target:target.id,
            targetAnchor: target.anchor.name,
          };
          c.prop('source', { id: source.id, anchor: { name: source.anchor.name } });
          c.prop('target', { id: target.id, anchor: { name: target.anchor.name } });
        }        

        console.log('Se editó el origen o destino del enlace:', v);
        // Aquí puedes realizar cualquier acción que desees cuando se edita el origen o destino de un enlace
      }, 500); // 500ms
    });
    
    this.paper.model.on('change:position', (cell: any) => {
      if (cell instanceof shapes.standard.Path) {
        clearTimeout(this.timerStep);
        this.timerStep = setTimeout(() => {

          const paperWidth = this.paper.options.width;
          const paperHeight = this.paper.options.height;

          // Obtener las dimensiones del elemento
          const elementWidth = (cell as any).attributes.size.width;
          const elementHeight = (cell as any).attributes.size.height;


          const newPosition = (cell as any).attributes.position;

          if (newPosition.x + elementWidth > paperWidth || newPosition.y + elementHeight > paperHeight) {
            // Ajustar las dimensiones del elemento
            //const newWidth = Math.min(elementWidth, paperWidth - newPosition.x);
            //const newHeight = Math.min(elementHeight, paperHeight - newPosition.y);
            console.log('nueva dimencion');
            // Establecer las nuevas dimensiones
            //cell.resize(newWidth, newHeight);
          }

          //console.log('cell',newPosition,this.paperHeight,this.paperWidth);

        },500);
      }
    });

    this.paper.on(
      'cell:pointerdblclick',
      (cellView: any, evt: any, x: any, y: any) => {
        const { id, attributes } = cellView.model;
        const { type, data } = attributes;

        if (type === 'standard.Path') {
          this.nodedblclick.emit({
            type: 'step',
            data: {
              id,
              ...data,
            },
          });
        } else if (type === 'standard.Link') {
          this.nodedblclick.emit({
            type: 'flow',
            data: {
              id,
              ...data,
            },
          });
        }
        //console.log(cellView.model.attribut);
      }
    );
  }

  private _transformToFitContent() {
    this.paper.transformToFitContent({
      padding: 30,
      contentArea: this.graphBBox,
      verticalAlign: 'middle',
      horizontalAlign: 'middle',
    });
  }

  private _createStart(node: NodeRefModel) {
    const { position, text } = node;
    const { x, y } = position!!;

    return new shapes.standard.Rectangle({
      position: { x: x + 10, y: y + 5 },
      size: { width: 80, height: 50 },
      z: 1,
      attrs: {
        body: {
          class: 'jj-start-body',
          rx: 25,
          ry: 25,
        },
        label: {
          class: 'jj-start-text',
          ...this.fontAttributes,
          fontSize: this.fontAttributes.fontSize * 1.4,
          fontWeight: 'bold',
          text,
        },
      },
    });
  }

  private _createStep(model: NodeRefModel) {
    const { position, text } = model;
    const { x, y } = position!!;

    const bevel = this.bevel;
    const spacing = this.spacing;

    return new shapes.standard.Path({
      position: { x, y },
      size: { width: 100, height: 60 },
      z: 1,
      data: {
        nombre: text,
      },
      attrs: {
        body: {
          class: 'jj-step-body',
          d: `M 0 ${bevel} ${bevel} 0 calc(w-${bevel}) 0 calc(w) ${bevel} calc(w) calc(h-${bevel}) calc(w-${bevel}) calc(h) ${bevel} calc(h) 0 calc(h-${bevel}) Z`,
        },
        label: {
          ...this.fontAttributes,
          class: 'jj-step-text',
          text,
          textWrap: {
            width: -spacing,
            height: -spacing,
          },
        },
      },
    });
  }

  private _createFlow(
    source: any,
    target: any,
    sourceAnchor = 'right',
    targetAnchor = 'left'
  ) {
    const unit = this.unit;
    const bevel = this.bevel;
    const spacing = this.spacing;

    return new shapes.standard.Link({
      source: { id: source.id, anchor: { name: sourceAnchor } },
      target: { id: target.id, anchor: { name: targetAnchor } },
      z: 2,
      data:{
        source:source.id,
        target:target.id,
        sourceAnchor,
        targetAnchor
      },
      attrs: {
        line: {
          class: 'jj-flow-line',
          targetMarker: {
            class: 'jj-flow-arrowhead',
            d: `M 0 0 L ${2 * unit} ${unit} L ${2 * unit} -${unit} Z`,
          },
        },
        outline: {
          class: 'jj-flow-outline',
          connection: true,
        },
      },
      markup: [
        {
          tagName: 'path',
          selector: 'wrapper',
          attributes: {
            fill: 'none',
            cursor: 'pointer',
            stroke: 'transparent',
            'stroke-linecap': 'round',
          },
        },
        {
          tagName: 'path',
          selector: 'outline',
          attributes: {
            fill: 'none',
            'pointer-events': 'none',
          },
        },
        {
          tagName: 'path',
          selector: 'line',
          attributes: {
            fill: 'none',
            'pointer-events': 'none',
          },
        },
      ],
      defaultLabel: {
        attrs: {
          labelBody: {
            class: 'jj-flow-label-body',
            ref: 'labelText',
            d: `
                          M calc(x-${spacing}) calc(y-${spacing})
                          m 0 ${bevel} l ${bevel} -${bevel}
                          h calc(w+${2 * (spacing - bevel)}) l ${bevel} ${bevel}
                          v calc(h+${
                            2 * (spacing - bevel)
                          }) l -${bevel} ${bevel}
                          H calc(x-${spacing - bevel}) l -${bevel} -${bevel} Z
                      `,
          },
          labelText: {
            ...this.fontAttributes,
            class: 'jj-flow-label-text',
            textAnchor: 'middle',
            textVerticalAnchor: 'middle',
            fontStyle: 'italic',
          },
        },
        markup: [
          {
            tagName: 'path',
            selector: 'labelBody',
          },
          {
            tagName: 'text',
            selector: 'labelText',
          },
        ],
      },
    });
  }

  getGrafo() {
    return this.graph.toJSON();
    //const grafo = graph.toJSON();
  }

  getNodes(): any[] {
    return Array.from(this.item.values()).map((nodeRef) => {
      const { data, ...nodeRefWithoutData } = nodeRef;
      return nodeRefWithoutData;
    });
  }

  getNodesNotLine(): any[] {
    return Array.from(this.item.values())
      .filter((nodeRef) => nodeRef.type !== 'line')
      .map(({ data, ...nodeRefWithoutData }) => nodeRefWithoutData);
  }

  add({ type, data }: WfActivityModel) {
    if (type === 'step') {
      const model: NodeRefModel = {
        type: 'step',
        text: data.nombre,
        position: { x: 10, y: 10 },
      };

      const step: any = this._createStep(model);
      const { id } = step;
      this.item.set(step.id, { ...model, id, data: step });

      this.graph.addCells(step);
    } else if (type === 'flow') {
      //this._createFlow(start, addToCart, "right", "left"),
      const { source, target, sourceAnchor, targetAnchor } = data;
      const origen = this.item.get(source)?.data;
      const destino = this.item.get(target)?.data;

      //console.log(origen,destino);
      const flow: any = this._createFlow(
        origen,
        destino,
        sourceAnchor || 'right',
        targetAnchor || 'left'
      );
      const { id } = flow;
      this.item.set(id, { type: 'line', id, data: flow });

      this.graph.addCells(flow);
    }

    console.log(this.item);
  }

  update({ type, data }: WfActivityModel) {
    if(type==='step'){
      const { id, nombre } = data;
      const v: any = this.item.get(id);
      if (v) {
        const target = v.data;
  
        target.prop('attrs/label/text', nombre);
        target.attributes.data = data;
        v.text = nombre;
      }
    }else if(type==='flow'){
      const { id, source, sourceAnchor, target,targetAnchor } = data;
      const v:any = this.item.get(id);
      if(v){
        const link = v.data;
        link.attributes.data = data;
        link.prop('source', { id: source, anchor: { name: sourceAnchor } });
        link.prop('target', { id: target, anchor: { name: targetAnchor } });
      }
    }
  }
}
