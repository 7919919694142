import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-imagen-colorpicker',
  standalone: true,
  imports: [],
  templateUrl: './modal-imagen-colorpicker.component.html',
  styleUrl: './modal-imagen-colorpicker.component.scss',
})
export class ModalColorpickerComponent {
  @ViewChild('canvasval') canvasval: ElementRef;
  @ViewChild('colboxval') colboxval: any;

  displayData = false;
  displayCol = false;
  enabledAplicarButton: boolean = true;
  _hexval: any;
  _rgbaval: any;

  private _canvas: any;
  private _ctx: CanvasRenderingContext2D;
  private _img: any;
  private _colbox: any;
  private url: string;

  @Output() outputColor = new EventEmitter();

  constructor(private modalRef: BsModalRef) {}

  readUrl(event: any) {
    this.displayData = true;
    this.displayCol = true;
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url = event.target.result;
        this._getimg(this.url);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  private _getimg(url: string) {
    this._colbox = this.colboxval.nativeElement;
    this._colbox.style.cssText = '--bgcolorval:rgba(0,0,0,0)';
    this._hexval = '';
    this._rgbaval = '';
    this._canvas = this.canvasval.nativeElement;
    this._ctx = this._canvas.getContext('2d');
    (this._img = document.createElement('img')),
      (this._img.crossOrigin = 'anonymous');
    this._img.src = this.url;
    this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
    this._img.onload = () =>
      this._ctx.drawImage(
        this._img,
        0,
        0,
        this._img.width,
        this._img.height,
        0,
        0,
        this._canvas.width,
        this._canvas.height
      );
  }

  getPixel(event: any) {
    var boundingRect = this._canvas.getBoundingClientRect();
    var x = event.clientX - boundingRect.left;
    var y = event.clientY - boundingRect.top;
    var px = this._ctx.getImageData(x, y, 1, 1);
    var data_array = px.data;
    var pixelColor =
      'rgba(' +
      data_array[0] +
      ',' +
      data_array[1] +
      ',' +
      data_array[2] +
      ',' +
      data_array[3] +
      ')';
    this._rgbaval = pixelColor;
    var dColor = data_array[2] + 256 * data_array[1] + 65536 * data_array[0];
    this._hexval = '#' + dColor.toString(16);
    this._colbox.style.cssText = '--bgcolorval:' + pixelColor;
    this.enabledAplicarButton= false;
  }

  close() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  aplicar() {
    setTimeout(() => {
      this.modalRef.hide();
      this.outputColor.emit({ hexval: this._hexval, rgbaval: this._rgbaval });
    }, 10);
  }
}
