import { Injectable, computed, inject, signal } from '@angular/core';
import { Menu } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { MenuItem } from '../../core/models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private readonly baseUrl: string = environment.baseUrl;

  //private http = inject(HttpClient);
  private _currentMenu = signal<MenuItem[]>([]);
  private secretKey:string|null= null;

  public currentMenu = computed(() => this._currentMenu());

  constructor() { }


  clear(){
    localStorage.removeItem('menu');
    this._currentMenu.set([]);
  }

  setMenu(secretKey:string ,menu: Menu[] | []) {
    try {
      this.secretKey = secretKey;
      const currentMenu = localStorage.getItem('menu');
      if (currentMenu && menu.length == 0) {
        const m = this.decrypt(currentMenu);
        if (m != null) this._currentMenu.set(this.createMenu(JSON.parse(m)));
      } else if (menu.length > 0) {
        this._currentMenu.set(this.createMenu(menu));
        const mm = this.encrypt(JSON.stringify(menu));
        localStorage.setItem('menu', mm);
      }
    } catch (e) {
      console.error(e);
    }
  }


  private createMenu(menus:Menu[],parentId:any = null):MenuItem[]{

    return menus.filter(item => item.parentId === parentId)
    .map(item => {
      const it:MenuItem = {
      id:item.id,
      label:item.label,
      };

      if(item.link!=null) it.link=item.link;
      if(item.icon!=null) it.icon=item.icon;
      if(item.isTitle!=null && item.isTitle) it.isTitle=item.isTitle;
      if(item.parentId!=null) it.parentId=item.parentId;
      if(item.isShortCut!=null && item.isShortCut) {
        it.shorcut = {
          ...( item.iconShortCut && {icon:item.iconShortCut}),
          ...( item.linkShortCut && {link:item.linkShortCut})
        };
      } 
      let submenus = this.createMenu(menus,item.id);
      if(submenus.length>0) it.subItems = submenus;
      return it;
    });
  }

  private encrypt(value: string): any {
    if (!this.secretKey) return null;
    //console.log('encrypt', secretKey);
    var b64 = CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
    var e64 = CryptoJS.enc.Base64.parse(b64);
    var eHex = e64.toString(CryptoJS.enc.Hex);

    return eHex;
  }

  private decrypt(textToDecrypt: string): any {
    
    if (!this.secretKey) return null;
    //console.log('decrypt', secretKey);

    var reb64 = CryptoJS.enc.Hex.parse(textToDecrypt);
    var bytes = reb64.toString(CryptoJS.enc.Base64);
    var decrypt = CryptoJS.AES.decrypt(bytes, this.secretKey.trim());
    var plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
  }

}
