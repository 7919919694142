import {
  ComponentFactoryResolver,
  Injectable,
  Injector,
  computed,
  signal,
} from '@angular/core';
import { SidebarModel, SidebarRef } from '../models/sidebar.model';
import { BsSidebarRef } from '../models/sidebar-ref';

@Injectable({
  providedIn: 'root',
})
export class RightsidebarService {
  private _current = signal<any>(null);
  private _autoClose: boolean = true;

  //private _params = signal<SidebarModel | undefined>(undefined);

  constructor(private injector: Injector) {}

  public current = computed(() => this._current());
  //public initialValue = computed(() => this._params());

  show(
    content: { new (...args: any[]): any },
    model?: SidebarModel
  ): SidebarRef<any> {
    //console.log('model',model);
    this._autoClose = model?.autoClose ?? true;
    //console.log('model',this._autoClose);

    const initialValue: BsSidebarRef = {
      settings: model?.settings || {},
      close: () => this._close(),
    };

    const componentFactoryResolver = this.injector.get(
      ComponentFactoryResolver
    );
    const componentFactory =
      componentFactoryResolver.resolveComponentFactory(content);
    const injectorWithProvider = Injector.create({
      providers: [{ provide: BsSidebarRef, useValue: initialValue }],
      parent: this.injector,
    });

    const componentInstance = componentFactory.create(injectorWithProvider);

    this._current.set(componentInstance.hostView);

    return new SidebarRef<any>(componentInstance.instance);
  }

  hide() {
    //console.log("hide",this._autoClose);
    if (this._autoClose) this.clear();
  }

  private _close() {
    this.clear();
  }

  clear() {
    document.body.classList.remove('right-bar-enabled');
    this._current.set(null);
  }

  get autoClose(){
    return this._autoClose;
  } 
}
