import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PageButton } from './pagebutton.model';

@Component({
  selector: 'app-page-buttons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-buttons.component.html',
  styleUrl: './page-buttons.component.scss'
})
export class PageButtonsComponent implements OnInit{
  

  @Input() title: string;
  @Input() items: PageButton[] = [];

  ngOnInit(): void {
    if(!this.items) this.items = [];
    this.items.forEach((it:any)=>{
      it.visible = it.visible===undefined ? true : it.visible
    });
  }

  

  clickButton(){
    console.log("click botton...")
  }
}
