<div class="modal-header">
  <h5 class="modal-title mt-0">{{ title }}</h5>
  <button
    type="button"
    class="btn-close"
    (click)="close()"
    aria-hidden="true"
  ></button>
</div>
<div class="modal-body">
  <app-product-edit-view
    #cp_productview
    [editMode]="editMode"
    [productoId]="productoId"
    (onSuccesss)="onSaveSuccesss()"
  ></app-product-edit-view>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    Cancelar
  </button>
  <button type="button" class="btn btn-info" (click)="guardar()">
    Guardar
  </button>
</div>
