import { Component, OnInit, inject } from '@angular/core';
import { DatatableComponent } from '@components/index';
import { RightsidebarService } from '@services/rightsidebar.service';
import { CompanyCorreoEditComponent } from '../company-correo-edit/company-correo-edit.component';

@Component({
  selector: 'app-company-correo-list',
  standalone: true,
  imports: [DatatableComponent],
  templateUrl: './company-correo-list.component.html',
  styleUrl: './company-correo-list.component.scss'
})
export class CompanyCorreoListComponent implements OnInit {
  
  private rightsidebar =inject(RightsidebarService);

  optionModel = {};

  ngOnInit(): void {
    this._initDatatable();
  }

  private _initDatatable(){
    var columns:any = [
      { 
        className:'col--opt-2 no-sort', 
        title:'...', 
        buttons:[
          
          {
            icon:"fas fa-pencil-alt",            
            onclick:(row:any)=>this._edit(row.id)
          },
          {            
            icon:"far fa-trash-alt",            
            onclick:(row:any)=>{              
            }
          },
        ]              
      }, 
      {
        title: 'Modulo',
        data: 'modulo'
      },
      {
        title: 'Servidor SMTP',
        data: 'servidorSmtp'
      }, 
      {
        title: 'TLS',
        data: 'tls'
      },   
      {
        title: 'Puerto SMTP',
        data: 'puerto'
      },          
      {
        title: 'Usuario',
        data: 'usuario'
      },   
    ]

    this.optionModel ={      
      //filtering:false,
      info:false,
      paging:false,      
      serverSide:false,
      lengthChange:false,
      columns: columns,
      //url:"producto/listar",  
    };
  } 

  private _edit(id:string):void{

  }

  addCorreo(){
    this.rightsidebar.show(CompanyCorreoEditComponent);
  }

}
