<h5 class="m-0">transición</h5>
<h6 cslass="mt-4 mb-3">{{ title }}</h6>
<hr />
<form [formGroup]="form" autocomplete="off">
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-nombre" class="form-label">Origen *</label>
        <app-select-catalog
          #cmbSource
          [size]="'sm'"
          [data]="catalogoSource"
          (changeValue)="changeSource($event)"
        ></app-select-catalog>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-nombre" class="form-label">Origen ancla *</label>
        <app-select-catalog
          #cmbSourceAnchor
          [size]="'sm'"
          [data]="catalogoSourceAnchor"
          (changeValue)="changeSourceAnchor($event)"
        ></app-select-catalog>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-nombre" class="form-label">Destino *</label>
        <app-select-catalog
          #cmbTarget
          [size]="'sm'"
          [data]="catalogoTarget"
          (changeValue)="changeTarget($event)"
        ></app-select-catalog>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-nombre" class="form-label">Destino ancla *</label>
        <app-select-catalog
          #cmbTargetAnchor
          [size]="'sm'"
          [data]="catalogoTargetAnchor"
          (changeValue)="changeTargetAnchor($event)"
        ></app-select-catalog>
      </div>
    </div>
  </div>
</form>
<hr />

<button
  type="button"
  class="btn btn-primary btn-sm btn-info"
  (click)="guardar()"
>
  <i class="bx bx-save font-size-16 align-middle me-2"></i>
  Guardar
</button>
