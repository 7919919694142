import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AgeService {
  private settings = {
    tiny: false,
    singular: 1,
    interval: 1000,
    suffixes: {
      past: '',
      future: '',
    },
    prefixes: {
      past: 'hace',
      future: 'Hasta',
    },
    units: ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'],
    formats: {
      now: 'ahora',
      singular: {
        seconds: 'un segundo',
        minutes: 'un minuto',
        hours: 'una hora',
        days: 'un día',
        weeks: 'una semana',
        months: 'un mes',
        years: 'un año',
      },
      plural: {
        seconds: '{{amount}} segundos',
        minutes: '{{amount}} minutos',
        hours: '{{amount}} horas',
        days: '{{amount}} días',
        weeks: '{{amount}} semanas',
        months: '{{amount}} meses',
        years: '{{amount}} años',
      },
      tiny: {
        seconds: '{{amount}}s',
        minutes: '{{amount}}m',
        hours: '{{amount}}h',
        days: '{{amount}}d',
        weeks: '{{amount}}w',
        months: '{{amount}}m',
        years: '{{amount}}y',
      },
    },
  };

  constructor() {}

  setTiny(activo: boolean) {
    this.settings.tiny = activo;
  }

  calculateAge(dateString: string): string {
    const inputDate = moment(dateString, 'DD/MM/YYYY HH:mm');
    const now = moment();

    const diff = inputDate.diff(now);
    const suffix = this.suffix(diff);
    const prefix = this.prefix(diff);
    const formatting = this.formatting(diff);
    const amount = this.amount(formatting);
    const unit = this.unit(formatting);

    if (unit === 'now') {
      return this.settings.formats.now;
    }

    const format = this.format(amount, unit);

    return format
      ? `${prefix} ${format.replace('{{amount}}', String(amount))} ${suffix}`.trim()
      : this.settings.formats.now;
  }

  private suffix(interval: number): string {
    return interval < 0
      ? this.settings.suffixes.past
      : this.settings.suffixes.future;
  }

  private prefix(interval: number): string {
    return interval < 0
      ? this.settings.prefixes.past
      : this.settings.prefixes.future;
  }

  private adjust(interval: number, scale: number): number {
    return Math.floor(Math.abs(interval / scale));
  }

  private formatting(interval: number) {
    return {
      seconds: this.adjust(interval, 1000),
      minutes: this.adjust(interval, 1000 * 60),
      hours: this.adjust(interval, 1000 * 60 * 60),
      days: this.adjust(interval, 1000 * 60 * 60 * 24),
      weeks: this.adjust(interval, 1000 * 60 * 60 * 24 * 7),
      months: this.adjust(interval, 1000 * 60 * 60 * 24 * 30),
      years: this.adjust(interval, 1000 * 60 * 60 * 24 * 365),
    };
  }

  private amount(formatting: Record<string, number>): number {
    const unit = this.unit(formatting);
    return formatting[unit] || 0;
  }

  private unit(formatting: Record<string, number>): string {
    if (formatting.seconds < 60) {
      return 'now';
    }

    return this.settings.units.find((unit) => formatting[unit] > 0) || '';
  }

  private format(amount: number, unit: string): string | undefined {
    if (unit === 'now') {
      return undefined;
    }

    const formatType = this.settings.tiny
      ? 'tiny'
      : amount === this.settings.singular
      ? 'singular'
      : 'plural';
    const formats = this.settings.formats[formatType];
    return formats[unit as keyof typeof formats];
  }
}
