import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-mask',
  standalone: true,
  imports: [],
  templateUrl: './input-mask.component.html',
  styleUrl: './input-mask.component.scss',
  providers:[
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMaskComponent),
      multi: true
    }
  ]
})
export class InputMaskComponent implements ControlValueAccessor  {
  
  @Input() mask: string="";
  onChange: Function;

  @ViewChild('inputMask')inputMask:ElementRef;

  constructor(){}

  onInput(evt: any) {
    //console.log(evt.target.value);
    this.onChange(evt.target.value)
  }

  writeValue(obj: any): void {
    if(obj) {
      this.inputMask.nativeElement.value=obj || "";
      this.onChange(obj);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

}
