import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class BsSidebarRef {
  //title?: string;
  settings?: any;
  close: () => void;
}
