import { AfterViewInit, Component, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output, Renderer2, ViewChild, inject } from '@angular/core';
import { NgOption, NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SelectedItem } from './select-grid.model';
import { FormsModule } from '@angular/forms';
import { Config } from 'datatables.net';

@Component({
  selector: 'app-select-grid',
  standalone: true,
  imports: [NgSelectModule,DataTablesModule,FormsModule],
  templateUrl: './select-grid.component.html',
  styleUrl: './select-grid.component.scss'
})
export class SelectGridComponent implements OnInit,AfterViewInit,OnDestroy {
  

  selectValue: Array<NgOption>;
    
  dtOptions: any = {};

  removeEventListener: () => void;

  private data: Array<any> = []; 
  private temp_items:Array<any> = [];
  private temp_itemsSelected:Array<any> = [];
  private temp_itemsAll:Array<SelectedItem> = [];
  
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Input() defaultItemText:string=""

  @Output() changeSelectedItem = new EventEmitter<SelectedItem>();

  selectedItem: any;  

  tabla:any

  private renderer = inject(Renderer2)

  ngAfterViewInit(): void {
    const that = this;  

    this.datatableElement.dtInstance.then((dtInstance:any) => {
      that.tabla= dtInstance;
    });

    this.removeEventListener = this.renderer.listen('document', 'click', (event) => {
      if(event.target.hasAttribute("view-id")){       
        var row = that.tabla.row($(event.target).closest('tr'));
        if(row) that._removeItem(row);
      }
    })
  }

  ngOnDestroy(): void {
    this.removeEventListener();
  }

  ngOnInit(): void {
    if(this.defaultItemText && this.defaultItemText.trim().length==0) this.defaultItemText = "Seleccione uno"

    this.selectValue = [this.defauld()];

    this.selectedItem = this.defauld()

    this.dtOptions = {
      paging:false,
      search:false,
      info:false,
      ordering:false,
      columns: [
        {
          title: 'id',
          data:'id',
          visible:false,          
          orderable:false,
          className:'no-sort'          
        },
        {
          title: 'Nombre',
          data:'nombre',          
          orderable:false,
          className:'no-sort col-grid-name'          
        },
        {
          className:'no-sort col-grid-button',
          render:(data: any, type: any, row: any,meta:any)=>{         
            return `<button type="button" view-id="${row.id}" view-index="${meta.row}" class="btn btn-sm btn-outline-secondary"><i class="fa fa-times"></i>&nbsp;Eliminar</button>`
          }
        }
      ],
      language:{        
        emptyTable: "No hay datos disponibles", 
        info: "Visualizando del _START_ hasta el _END_ de _TOTAL_ registros", 
        infoEmpty: "0 registros",
        lengthMenu: "Mostrar _MENU_ registros",
      },
      dom:'lrt',
      columnDefs:[
        {
          targets:'no-sort',
          orderable:false
        }
      ],
      data : [],
      //responsive:false
    }
  }

  private defauld = () =>  {
    return {value: '-1', label: this.defaultItemText}
  }

  private _removeItem(row:any){   
    
      var reg = row.data();
            
      var lista:any =  this.selectValue.filter(reg => reg.value != -1);
      lista.push({ value: reg.id, label: reg.nombre});
      lista.sort((a:any, b:any) => a.label.localeCompare(b.label));


      var items = [this.defauld()].concat(lista)
      this.selectValue = items;
      
      if(this.temp_itemsAll && this.temp_itemsAll.length>0){
        const it = this.temp_itemsAll.find(itm=>itm.id==reg.id);        
        if(it){ 
          it.action = "delete";
          this.changeSelectedItem.emit(it);
        }
      }
      
      row.remove().draw();  
      this.data = this.data.filter(reg => reg.id != reg.id);      
    
  }

  private _addItem(item:any){

    var row = {
      id:item.value,
      nombre:item.label
    }
    
    if(this.temp_itemsAll && this.temp_itemsAll.length>0){
      const it = this.temp_itemsAll.find(reg=>reg.id==row.id);
      if(it){
        it.action ="add";
        this.changeSelectedItem.emit(it);
      }
    }

    this.data.push(row)
        

    return row;
  }

  private _toCatalogo(data:Array<any>):Array<NgOption>{
    var ctgs:Array<NgOption> =[];
    data.forEach(it=>{
      ctgs.push({
         label: it.nombre,
        value: it.id
      })
    })
    return ctgs;
  }

  clickButtonAdd(){
    //console.log(this.selectedItem)
   
    if(this.selectedItem && this.selectedItem.value != -1){
      
      const row = this._addItem(this.selectedItem);

      this.selectValue = this.selectValue.filter(reg=>reg.value!=row.id)
      this.ngSelectComponent.handleClearClick();

      this.selectedItem = this.defauld();
      this.tabla.row.add(row).draw(false);
    }
    
  }

  setItems(items:Array<any>) 
  {   
    //console.log('setItems',items);

    if(items && items.length>0){

      this.temp_itemsAll = items.map(it=>{
        return {
          id:it["id"],
          value:it,
          action:"add"
        }
      });

      this.temp_items = this._toCatalogo(items);  
      this.reload();   
    }
  }

  private reload(){

    this.tabla.clear().draw();
    var its=[];
    
    if(this.temp_items.length>0) its = this.temp_items;

    if(this.temp_itemsSelected.length>0){
      var ids = this.temp_itemsSelected.map(it=>it.value);
      if(this.temp_items.length>0){
        its = this.temp_items.filter(it=> !ids.includes(it.value));
      }
    }

    if(its.length>0){
      its = its.sort((a, b) => a.label.localeCompare(b.label));
      this.selectValue = [this.defauld()].concat(its); 
    }

    if(this.temp_itemsSelected.length>0){
      this.temp_itemsSelected.forEach(it=>{
        this._addItem(it)
      });
    }

    
  }

  setSelectedItems(items:Array<any>,){
    if(items && items.length>0){
      this.temp_itemsSelected = this._toCatalogo(items);
      
      this.reload();

      items.forEach(it=>{
        this.tabla.row.add(it).draw(false);
      });   
      
      
    }
  }

}
