import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectCatalogComponent } from '@components/index';
import { NumbersOnlyDirective } from '@directives/numbers-only.directive';
import { AlertService } from '@services/alert.service';
import { SpinnerComponent } from '@shared/ui';

@Component({
  selector: 'app-person-edit-view',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    ReactiveFormsModule,
    FormsModule,
    SelectCatalogComponent,
    NumbersOnlyDirective,
  ],
  templateUrl: './person-edit-view.component.html',
  styleUrl: './person-edit-view.component.scss'
})
export class PersonEditViewComponent implements OnInit {

  private http = inject(HttpClient);
  private msg = inject(AlertService);

  loading: boolean;
  submitted: boolean;
 
  maxlengthIdentificacion: number = 25;

  @Input() personId: string|null;
  @Input() editMode: boolean;
  @Output() onSuccesss = new EventEmitter<any>();

  private formBuilder = inject(FormBuilder);
  private catalogoTipoIdentificacion: Array<any> = [];
  private catalogoTipoReembolso: Array<any> = [];

  @ViewChild('cmbTipoIdentificacion') cmbTipoIdentificacion: any;
  @ViewChild('cmbTipoProReembolso') cmbTipoProReembolso: any;
  @ViewChild('i-identificacion') txtIdentificacion: any;
  
  form: FormGroup;

  constructor(){}

  ngOnInit(): void {

    this._obtener(this.personId);

    this.form = this.formBuilder.group({
      tipoIdentificacion: ['', [Validators.required]],
      identificacion: ['', [Validators.required]],
      razonSocial: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      movil: ['', [Validators.required]],
      direccion: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.email]],
      ckeckContribuyenteEspecial: [false],
      ckeckExtranjero: [false],
      ckeckCliente: [false],
      ckeckProveedor: [false],
      tipoProReembolso: ['', [Validators.required]],
    });
  }

  get f() {
    return this.form.controls;
  }

  guardar() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const _f = this.form.value;

    /*if (!this.buscar(_f.identificacion)) {
      return;
    }*/

    if (this.personId) {
      this._actualizar({
        personaId: _f.personaId,
        catTipIdentificacion: _f.tipoIdentificacion,
        identificacion: _f.identificacion,
        razonSocial: _f.razonSocial,
        telefono: _f.telefono,
        movil: _f.movil,
        direccion: _f.direccion,
        correo: _f.correo,
        contribuyenteEspecial: _f.ckeckContribuyenteEspecial,
        extranjero: _f.ckeckExtranjero,
        cliente: _f.ckeckCliente,
        proveedor: _f.ckeckProveedor,
        catTipoProReembolso: _f.tipoProReembolso,
      });
    } else {
      this._registrar({
        catTipIdentificacion: _f.tipoIdentificacion,
        identificacion: _f.identificacion,
        razonSocial: _f.razonSocial,
        telefono: _f.telefono,
        movil: _f.movil,
        direccion: _f.direccion,
        correo: _f.correo,
        contribuyenteEspecial: _f.ckeckContribuyenteEspecial,
        extranjero: _f.ckeckExtranjero,
        cliente: _f.ckeckCliente,
        proveedor: _f.ckeckProveedor,
        catTipoProReembolso: _f.tipoProReembolso,
      });
    }
  }

  private _obtener(id: any = null) {
    this.loading = true;
    this.http.get(id ? `persona/${id}` : 'persona').subscribe({
      next: (data: any) => {
        if (data) {
          const model = data.model;
          if (model) {
            this.form.patchValue({
              tipoIdentificacion: model.catIdentificacion,
              identificacion: model.identificacion,
              razonSocial: model.razon_social,
              telefono: model.telefono,
              movil: model.movil,
              direccion: model.direccion,
              correo: model.correo,
              ckeckContribuyenteEspecial: model.contribuyente_especial,
              ckeckExtranjero: model.extranjero,
              ckeckCliente: model.cliente,
              ckeckProveedor: model.proveedor,
              tipoProReembolso: model.catProReembolso,
            });
          }

          if (data.catalogos) {
            if (data.catalogos.TP_TIPO_IDENTIFICACION) {
              this.catalogoTipoIdentificacion =
                data.catalogos.TP_TIPO_IDENTIFICACION;
              if (data.model && model.catIdentificacion) {
                const item = this.catalogoTipoIdentificacion.find(
                  (it: any) => it.id === model.catIdentificacion
                );
                this.cmbTipoIdentificacion.setSelectedItem(item);
                this.setMaxlengthIdentificacion(item.nombre);
              }
              this.cmbTipoIdentificacion.setItems(
                this.catalogoTipoIdentificacion
              );
            }

            if (data.catalogos.TP_TIPO_PRO_REEMBOLSO) {
              this.catalogoTipoReembolso = data.catalogos.TP_TIPO_PRO_REEMBOLSO;
              if (data.model && model.catProReembolso) {
                this.cmbTipoProReembolso.setSelectedItem(
                  this.catalogoTipoReembolso.find(
                    (it: any) => it.id === model.catProReembolso
                  )
                );
              }
              this.cmbTipoProReembolso.setItems(this.catalogoTipoReembolso);
            }
          }
        }
        this.loading = false;
      },
      error: (error) => (this.loading = false),
    });
  }

  private _actualizar(model: any) {
    this.http.put(`persona/${this.personId}`, model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.submitted = false;
          this.msg.growl.success(`Persona actualizada con éxito`);
          this.onSuccesss.emit();
        }
        this.loading = false;
      },
      error: (error) => (this.loading = false),
    });
  }

  private _registrar(model: any) {
    this.http.post('persona', model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.submitted = false;
          this.msg.growl.success(`Persona registrada con éxito`);
          this.onSuccesss.emit();
        }
        this.loading = false;
      },
      error: (error) => (this.loading = false),
    });
  }

  private setMaxlengthIdentificacion(label: string) {
    this.maxlengthIdentificacion =
      label === 'CEDULA' ? 10 : label === 'RUC' ? 13 : 25;
  }

  changeTipoIdentificacion(event: any) {
    this.form.patchValue({
      tipoIdentificacion: event.value,
      identificacion: '',
    });

    this.setMaxlengthIdentificacion(event.label || '');
  }

  changeTipoRembolso(event: any) {
    this.form.patchValue({
      tipoProReembolso: event.value,
    });
  }

}
