import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SelectCatalogComponent } from '@components/index';
import { AlertService } from '@services/alert.service';
import { SpinnerComponent } from '@shared/ui';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BsSidebarRef } from 'src/app/core/models/sidebar-ref';
import { TaskEditCommentsComponent } from "./task-edit-comments/task-edit-comments.component";

@Component({
  selector: 'app-task-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SelectCatalogComponent,
    SpinnerComponent,
    SimplebarAngularModule,
    TabsModule,
    TaskEditCommentsComponent
],
  templateUrl: './task-edit.component.html',
  styleUrl: './task-edit.component.scss',
})
export class TaskEditComponent implements OnInit {
  private formBuilder = inject(FormBuilder);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  form: FormGroup;
  submitted: boolean = false;
  loading: boolean = true;
  private orderProdDetId: string;
  private estadoId: string;
  private procesoId:string;
  aux:number;

  emitter: EventEmitter<any> = new EventEmitter();


  @ViewChild('cmbTipoResponsable') cmbTipoResponsable: any;

  constructor(private sidebar: BsSidebarRef) {}

  get f() {
    return this.form.controls;
  }

  get taskId(){
    return this.orderProdDetId;
  }

  ngOnInit(): void {
    const { settings } = this.sidebar;
    this.orderProdDetId = settings.id;
    this.estadoId = settings.estado;
    this.procesoId = settings.proceso;
    this.aux = settings.aux;

    this.form = this.formBuilder.group({
      secuencia: ['', null],
      producto: ['', null],
      cantidad: ['', null],
      tipoResponsable: ['', null],
      numBandas:['',null],
      numBandasProducidas:['',null]
    });

    this._obtener();
  }

  private _obtener() {
    this.http
      .get(`planificacion/produccion/task/${this.orderProdDetId}`)
      .subscribe({
        next: (data: any) => {
          const { exito, model, catalogos } = data;

          if (model) {
            this.form.patchValue({
              secuencia: model.numero,
              producto: model.producto,
              cantidad: `${model.cantidad} ${model.unidad}`,
              numBandas: model.numeroBandas || null,
              numBandasProducidas:model.numeroBandasEntregadas || null
            });
          }

          if (catalogos) {
            const { TB_USR_OPERARIOS } = catalogos;

            if (TB_USR_OPERARIOS) {
              this.cmbTipoResponsable.setItems(TB_USR_OPERARIOS);
              if(model.responsables){
                const lista = model.responsables.map((it:any)=>TB_USR_OPERARIOS.find((i:any)=>i.id===it.id));  
                if(lista.length>0){
                  this.form.patchValue({
                    tipoResponsable : lista.map((it: any) => it.id)
                  });
                }                

                this.cmbTipoResponsable.setSelectedItems(
                  lista  
                 )
              }

              
            }
          }

          this.loading = false;
        },
        error: (error) => (this.loading = false),
      });
  }

  guardar() {
        
    this.loading =true;
    const {tipoResponsable, numBandas,numBandasProducidas} = this.form.value;
    //if(tipoResponsable){
      //actualizar datos de responsable
      //console.log(this.estadoId);
      const model ={
        tarea:this.orderProdDetId,
        estado:this.estadoId,
        proceso:this.procesoId,
        responsables: tipoResponsable && tipoResponsable.length>0 ? tipoResponsable : null,
        numeroBandas: numBandas || null,
        numeroBandasEntregadas: numBandasProducidas || null
      };

      

      this.http.put(`planificacion/produccion/task`, model).subscribe({
        next: (data: any) => {
          this.loading = false;

          if (data && data.exito) {
            this.submitted = false;
            this.msg.success(`Orden de poduccion actualiza con éxito`);
            this.sidebar.close();
            this.emitter.emit({reload:true});
            //this.router.navigate([`production/order/edit/${data.id}`]);
          }
        },
        error: (error) => {
          this.loading = false;
          this.msg.error(error)
          this.sidebar.close();
          this.emitter.emit({reload:false});
        },
      });

    //}

  }

  changeResponsable(item: any) {
    //console.log('select',item);
    this.form.patchValue({
      tipoResponsable: item ? item.map((it: any) => it.value) : null,
    });
  }

  changeComentLoading(estado:boolean){
    this.loading = estado;
  }


}
