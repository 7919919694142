<div class="modal-header">
    <h5 class="modal-title mt-0">Reporte</h5>
    <button type="button" class="btn-close" (click)="close()" aria-hidden="true"></button>
</div>
<div class="pdf-viewer modal-body">
    <app-spinner [loading]="loading"></app-spinner> 
    <div style="min-height: 500px;">
        <object *ngIf="myBlobURL" width="100%" height="500px"
            [data]="myBlobURL"
            type="application/pdf">
        </object>
    </div>    
</div> 
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">Retornar</button>
</div>

