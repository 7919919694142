import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { UploadedImage } from './imagen-upload.model';
import Compressor from 'compressorjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-imagen-upload',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './imagen-upload.component.html',
  styleUrl: './imagen-upload.component.scss'
})
export class ImagenUploadComponent {

  @Input() image: any=null;
  @Input() allowedExtensions: string[] | null =null;
  @Output() uploadedImage = new EventEmitter<UploadedImage>();
  @ViewChild('input') inputFile: ElementRef;

  
  constructor(){
    //console.log(this.image);
  }

  get allowedExtensionsString(): string {
    return this.allowedExtensions ? this.allowedExtensions.map(ext => `.${ext}`).join(',') : '';
  }
  
  fileChange(input:any) {

    if (input.files.length) {
      const file = input.files[0];
      //console.log(originalSize);

      // Check if the file size exceeds 1 MB
      if (file.size > 1000000) { // 1 MB threshold
        new Compressor(file, {
          quality: 0.6, // Adjust the quality parameter as needed
          maxWidth:800,
          maxHeight:800,
          mimeType:'image/jpeg',
          success: (compressedResult) => {
            //console.log('compresion d',compressedResult.size);
            this._processImage(compressedResult);
          },
          error: (err) => {
            console.error('Compression error:', err);
          }
        });
      } else {
        //console.log('otra prueba');
        this._processImage(file);
      }
    }

  }

  private _processImage(file: Blob) {
    //console.log(file);
    const reader = new FileReader();
    let uploadImage: UploadedImage = {
      file: file as File,
      fileBase64: '',
      height: 0,
      width: 0
    };

    reader.onload = (event) => {
      let img = new Image();

      img.onload = () => {
        uploadImage.height = img.height;
        uploadImage.width = img.width;

        this.uploadedImage.emit(uploadImage);
      };

      img.src = <string>event?.target?.result;
      this.image = reader.result; 
      uploadImage.fileBase64 = this.image as string;
    };

    reader.readAsDataURL(file);
  }

  removeImage():void{
    this.image = null;
    this.inputFile.nativeElement.value = null;
    this.uploadedImage.emit();
  }

  clickFileInput() {
      let el: HTMLElement = this.inputFile.nativeElement;
      el.click();
  }

  clickFileRemove(){
    this.removeImage()
  }

  clickFileChange(){
    this.clickFileInput()
  }
}
