import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SelectCatalogComponent } from '@components/index';
import { AlertService } from '@services/alert.service';
import { SpinnerComponent } from '@shared/ui';
import { BsSidebarRef } from '../../../../../core/models/sidebar-ref';

@Component({
  selector: 'app-catalog-detail-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SelectCatalogComponent,
    SpinnerComponent,
  ],
  templateUrl: './catalog-detail-edit.component.html',
  styleUrl: './catalog-detail-edit.component.scss',
})
export class CatalogDetailEditComponent implements OnInit {
  private formBuilder = inject(FormBuilder);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  form: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  title: string = 'Nuevo';
  aplicaCatalogoPadre: boolean = false;
  aplicaCodigoDetalle: boolean = false;
  aplicaValorDetalle: boolean = false;

  private catalogoId: string;
  private catalogoDetalleId: string;

  @ViewChild('cmbTipoCatalogoDependencia') cmbTipoCatalogoDependencia: any;

  emitter: EventEmitter<boolean> = new EventEmitter();

  constructor(private sidebar: BsSidebarRef) {}

  ngOnInit(): void {
    const { settings } = this.sidebar;

    if (settings?.idCatalogo) {
      this.catalogoId = settings.idCatalogo;
    }

    if (settings?.idCatalogoDt) {
      this.title = 'Actualizar';
      this.catalogoDetalleId = settings.idCatalogoDt;
      console.log('actualizar');
      this._obtener();
    }

    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      codigo: ['', null],
      valor: ['', null],
      catalogoPadre: [''],
    });
  }

  get f() {
    return this.form.controls;
  }

  private get tipoCatalogoPadre() {
    return this.form.get('catalogoPadre');
  }

  private _obtener() {
    this.loading = true;
    this.http
      .get(
        this.catalogoDetalleId
          ? `catalogo/${this.catalogoId}/detalle/${this.catalogoDetalleId}`
          : `catalogo/${this.catalogoId}/detalle`
      )
      .subscribe({
        next: (data: any) => {
          //console.log(data);

          if (data && data.model) {
            this.form.patchValue({
              nombre: data.model.nombre,
              codigo: data.model.codigo,
              valor: data.model.valor,
              catalogoPadre: data.model.idCatalogoPadre,
            });
          }

          if (data.aplicaCatalogoPadre) {
            this.aplicaCatalogoPadre = true;
          }

          if (data.requiereCodigoDetalle) {
            this.aplicaCodigoDetalle = true;
          }

          if (data.requiereValorDetalle) {
            this.aplicaValorDetalle = true;
          }

          if (
            data.aplicaCatalogoPadre &&
            data.catalogos &&
            data.catalogos.length > 0
          ) {
            setTimeout(() => {
              this._loadCatalogoPadre(data.catalogos);
            }, 1);
          }

          this.loading = false;
        },
        error: (error) => (this.loading = false),
      });
  }

  private _loadCatalogoPadre(lista: any) {
    const _f = this.form.value;
    if (this.aplicaCatalogoPadre) {
      var item = lista.find((it: any) => it.id === _f.catalogoPadre);
      //console.log('_loadCatalogoPadre',item);
      if (item) this.cmbTipoCatalogoDependencia?.setSelectedItem(item);
      //console.log('items',lista,this.cmbTipoCatalogoDependencia);
      this.cmbTipoCatalogoDependencia?.setItems(lista);

      this.tipoCatalogoPadre?.clearValidators();
      this.tipoCatalogoPadre?.setValidators([Validators.required]);
      this.tipoCatalogoPadre?.updateValueAndValidity();
    }
  }

  private _actualizar(model: any) {
    this.loading = true;
    this.http.put('catalogo/detalle/actualizar', model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.form.reset();
          this.submitted = false;

          this.msg.growl.success(`Catalogo actualizado con éxito`);
          this.emitter.emit(true);
          this.sidebar.close();
        }
        this.loading = false;
      },
      error: (error) => {
        this.msg.error(error);
        this.loading = false;
      },
    });
  }

  private _registrar(model: any) {
    this.loading = true;
    this.http.post(`catalogo/${this.catalogoId}/registrar`, model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.form.reset();
          this.submitted = false;

          this.msg.growl.success(`Catalogo registrado con éxito`);
          this.emitter.emit(true);
          this.sidebar.close();
        } else {
          this.msg.error(data.mensaje);
        }
        this.loading = false;
      },
      error: (error) => (this.loading = false),
    });
  }

  changeTipoCatalogoDependencia(event: any) {
    this.form.patchValue({
      catalogoPadre: event?.value || null,
    });
  }

  guardar() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const _f = this.form.value;
    if (this.catalogoDetalleId) {
      this._actualizar({
        id: this.catalogoDetalleId,
        nombre: _f.nombre,
        codigo: _f.codigo,
        valor: _f.valor,
        catalogoPadre: this.aplicaCatalogoPadre ? _f.catalogoPadre : null,
      });
    } else {
      this._registrar({
        nombre: _f.nombre,
        codigo: _f.codigo,
        valor: _f.valor,
        catalogoPadre: this.aplicaCatalogoPadre ? _f.catalogoPadre : null,
      });
    }
  }
}
