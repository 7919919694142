<div class="container-fluid">
  <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body card-host">
          <app-person-edit-view
            #cp_personview
            [editMode]="editMode"
            [personId]="personId"
            (onSuccesss)="onSuccesss()"
            >
          </app-person-edit-view>
        </div>
      </div>
    </div>
  </div>
</div>
