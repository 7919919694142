import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { DatatableComponent } from '../../../../components';
import moment from 'moment';
import { PageButtonsComponent } from '../../../../shared/ui';
import { PageButton } from '../../../../shared/ui/page-buttons/pagebutton.model';
import { Router } from '@angular/router';
import { AlertService } from '../../../../core/services/alert.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [DatatableComponent,PageButtonsComponent],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})
export class UserListComponent implements OnInit {
  
  private router=inject(Router);
  private alert=inject(AlertService);
  private http=inject(HttpClient);

  botones: PageButton[];
  title: string = 'Listar Usuarios';

  @ViewChild(DatatableComponent) dt: DatatableComponent;
  optionModel = {};
  
  ngOnInit(): void {
    this._initBotones();
    this._initDatatable();
  }

  private _initBotones(){
    this.botones = [
      {
        label:"agregar",
        icon:"bx bx-plus",
        cssClass:"btn-success",
        actionType:()=>this._add()
      },
    ]
  }

  private _initDatatable(){
      var columns:any = [
        { 
          className:'col--opt-2 no-sort', 
          title:'...', 
          buttons:[
            
            {
              icon:"fas fa-pencil-alt",            
              onclick:(row:any)=>this._edit(row.id)
            },
            {            
              icon:"far fa-trash-alt",            
              onclick:(row:any)=> this._delete(row)
            },
          ]              
        },
        {
          title: 'Nombre',
          data: 'nombre'
        }, 
        {
          title: 'Correo',
          data: 'correo'
        }, 
        {
          title: 'Usuario',
          data: 'usuario'
        }, 
        {
          title: 'Fecha Creacion',
          data: 'fecha_creacion',
          render:(data: any)=>{
            var stillUtc = moment.utc(data).toDate();
            var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm')
            return local;
          }
        },     
        {
          title: 'Fecha Modificacion',
          data: 'fecha_modificacion',
          render:(data: any)=>{
            var stillUtc = moment.utc(data).toDate();
            var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm')
            return local;
          }
        },         
        
      ]

      this.optionModel ={      
        filtering:false,
        searching:true,
        
        serverSide:true,
        columns: columns,
        url:"usuario/listar",  
      };
    
  }

  private _edit(id:string){
    this.router.navigate([`administrations/users/edit/${id}`]);
  }

  private _add(){
    this.router.navigate([`administrations/users/add`]);
  }

  private _delete(row:any){
    this.alert.confirm(`¿Desea eliminar el registro [${row.nombre}]?`).then(result => {
      if (result.value) {
        this.http.delete(`usuario/${row.id}`).subscribe((data:any)=>{
          if(data && data.exito){
            this.alert.success(`Usuario ${name} eliminado con éxito`);
            this.dt.reload();
          }      
        });
        //this._delete(row.id,row.nombre);
      }
    })
  }

}
