<div class="container-fluid">
  <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body card-host">
          <app-product-edit-view
            #cp_productview
            [editMode]="editMode"
            [productoId]="productoId"
            (onSuccesss)="onSuccesss()"
          ></app-product-edit-view>
        </div>
      </div>
    </div>
  </div>
</div>
