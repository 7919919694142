<div class="container-fluid">
  <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="form" autocomplete="off">
            <div class="row">
              <div class="col-md-1">
                <label>Tipo:</label>
              </div>
              <div class="col-md-3">
                <app-select-catalog
                  [size]="'sm'"
                  #cmbTipoReporte
                  (changeValue)="changeTipoReporte($event)"
                ></app-select-catalog>
              </div>
              <div class="col-md-1">
                <label>Numero:</label>
              </div>
              <div class="col-md-3">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="numeroPedido"
                />
              </div>
              <div class="col-md-1">
                <label>Cliente:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control form-control-sm"
                  maxlength="200"
                  formControlName="cliente"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-1 mt-1">
                <label>Estado:</label>
              </div>
              <div class="col-md-3 mt-1">
                <app-select-catalog
                  [size]="'sm'"
                  #cmbEstado
                  (changeValue)="changeEstadoReporte($event)"
                ></app-select-catalog>
              </div>
              <div class="col-md-1 mt-1">
                <label>Desde:</label>
              </div>
              <div class="col-md-3 mt-1">
                <app-datepicker
                  [ngClass]="{
                    'is-invalid': submitted && f.fechaDesde.errors
                  }"
                  formControlName="fechaDesde"
                  #fechaDesde
                ></app-datepicker>
              </div>
              <div class="col-md-1 mt-1">
                <label>Hasta:</label>
              </div>
              <div class="col-md-3 mt-1">
                <app-datepicker
                  [ngClass]="{
                    'is-invalid': submitted && f.fechaHasta.errors
                  }"
                  formControlName="fechaHasta"
                  #fechaHasta
                ></app-datepicker>
              </div>              
            </div>
            <div class="row">
              <div class="col-md-1 mt-1">
                <label>Producto:</label>
              </div>
              <div class="col-md-3 mt-1">
                <input
                  class="form-control form-control-sm"
                  formControlName="producto"
                />
              </div>
            </div>
          </form>
          <hr />
          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary btn-sm btn-info"
                (click)="buscar()"
              >
                <i
                  class="bx bx-search-alt-2 font-size-16 align-middle me-2"
                ></i>
                Consultar
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <app-datatable
              [options]="optionModel"
              [filters]="filters"
            ></app-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
