/*export interface OptionsModel{
    columns?: ColumnsModel[] | undefined
}

 export interface ColumnsModel {
    title?:string 
    data?: string
    visible?:boolean
    orderable?:boolean
    className?:string
    render?: (data: any, type: any, row: any,meta:any)=> void | undefined
}


export interface DataTablesResponse {
    rows: any[];
    totalRows: number;
}*/
export enum ModalSearchAction {
    Add = "add",
    Edit = "edit",
    InsertUp = "insertUp",
    InsertDown = "insertDown"
  }

export interface ModalSearchModel{
    index?:number | null
    action?: ModalSearchAction
    title?:string
    url?:string
    search?:string
    pageLength?:number
    data?:DataModel
    scrollx?:boolean
    scrolly?:boolean
    columns?:any[]
    ignoreFields?:any
}

export interface DataModel{
    totalRows?:number,
    rows?:any[]
}

export interface ModalSearchEmitter {
    index?:number|null,
    data: any;
    action?: ModalSearchAction
}
