<div class="card">
  <div
    class="card-header bg-transparent border-bottom d-flex justify-content-between align-items-center"
  >
    <h4 class="card-title card-title-1" style="margin: 0px">
      <span style="line-height: 2.4"
        >Paso {{ index + 1
        }}{{ nombrePaso && nombrePaso.length > 0 ? " - " + nombrePaso : "" }}
      </span>
      <button
        *ngIf="!aplicaEditarNombrePaso"
        class="btn nav-btn"
        (click)="editarPasoNombre()"
      >
        <i class="bx bxs-pencil"></i>
      </button>

      <input
        *ngIf="aplicaEditarNombrePaso"
        #txtNombrePaso
        type="text"
        style="margin-right: 10px; margin-left: 10px"
        class="form-control form-control-sm"
        (keyup.enter)="changePasoNombre($event)"
        (focusout)="changePasoNombre($event)"
        maxlength="100"
      />
    </h4>
    <ul class="list-inline user-chat-nav text-end mb-0">
      <li class="list-inline-item d-none d-sm-inline-block">
        <button
          class="btn nav-btn"
          data-toggle="collapse"
          (click)="isCollapsed = !isCollapsed"
        >
          <i
            class="bx"
            [ngClass]="{
              'bx-chevron-up': isCollapsed === false,
              'bx-chevron-down': isCollapsed === true
            }"
          ></i>
        </button>
      </li>
      <li class="list-inline-item">
        <button class="btn nav-btn" (click)="removePaso()">
          <i class="bx bx-trash"></i>
        </button>
      </li>
    </ul>
  </div>
  <div class="card-body collapse show" [collapse]="isCollapsed">
    <div style="width: 100%">
      <label for="i-preparacion" class="form-label"
        >[1] - DETALLE DE PREPARACION</label
      >
      <div style="float: right" class="text-end">
        <div class="button-items">
          <button
            type="button"
            class="btn btn-success btn-sm"
            (click)="searhProducto()"
          >
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>agregar
          </button>
        </div>
      </div>
    </div>
    <app-datatable-formula
      #dtpreparacion
      [options]="dtOptionsPreparacion"
      [data]="itemPreparacion"
    ></app-datatable-formula>
    <hr />
    <div style="width: 100%">
      <label for="i-aplicacion" class="form-label"
        >[2] - DETALLE DE APLICACIÓN</label
      >
      <div style="float: right" class="text-end">
        <div class="button-items">
          <button
            type="button"
            class="btn btn-success btn-sm"
            (click)="addAplicacion()"
          >
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>agregar
          </button>
        </div>
      </div>
    </div>
    <div style="margin-top: 7px">
      <div
        *ngFor="let d of itemsAplicacion; index as i"
        class="d-flex align-items-center mb-1"
      >
        <div class="avatar-xs me-3">
          <span
            class="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18"
          >
            <i class="bx bx-copy-alt"></i>
          </span>
        </div>
        <div class="input-group">
          <span class="input-group-text">Tarea {{ i + 1 }}</span>
          <input
            class="form-control"
            value="{{ d.descripcion }}"
            (change)="changeItemAplicacion(i, $event)"
            autocomplete="off"
            type="text"
          />
          <button
            class="btn btn-outline-danger"
            (click)="removeDetalleAplicacion(d.id)"
          >
            <i class="fa fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div style="width: 100%; margin-top: 7px; vertical-align: middle">
      <div style="display: inline-block">
        <label style="margin-bottom: 0px; margin-top: 5px" class="form-label"
          >[3] - DETALLE DE PRENSADO</label
        >
      </div>
      <div style="float: right" class="text-end">
        <div class="button-items" style="display: flex">
          <div class="check-prensado" style="display: inline-block">
            <ui-switch
              defaultBoColor="#dfdfdf"
              [checked]="prensadoChecked"
              size="small"
              color="#34c38f"
              (change)="onChange($event)"
            ></ui-switch>
          </div>
          <button
            type="button"
            style="margin-bottom: 0px"
            class="btn btn-success btn-sm"
            [disabled]="!prensadoChecked"
            (click)="addPrensado()"
          >
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>agregar
          </button>
        </div>
      </div>
    </div>
    @if (prensadoChecked) {
    <div style="width: 100%; margin-top: 7px">
      <app-datatable-formula
      #dtprensado
      [options]="dtOptionsPrensado"
      [data]="itemPrensado"
    ></app-datatable-formula>

      <!--<table
        #tbprensado
        datatable
        [dtOptions]="dtOptionsPrensado"
        class="row-border hover table table-bordered dt-responsive nowrap dt-select-grid"
        cellspacing="0"
        width="100%"
      ></table>-->
    </div>
    }
  </div>
</div>
