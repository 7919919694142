<div class="input-group input-group-sm clockpicker">
  <input
    bsDatepicker
    [disabled]="_disabled"
    class="form-control form-control-sm"
    placeholder="dd/mm/yyyy"
    [bsConfig]="datepickerConfig"
    (ngModelChange)="onChangeDate($event)"
    [(ngModel)]="value"
    [minDate]="minDate"
    #d="bsDatepicker"
  />

  <span
    class="input-group-text c-d-b"
    (click)="_disabled ? null : d.toggle()"
    [ngClass]="{ disabled: _disabled }"
    ><i class="bx bx-calendar"></i
  ></span>
</div>
