import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ProductEditViewComponent } from '../product-edit-view/product-edit-view.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-product-edit-modal',
  standalone: true,
  imports: [
    CommonModule,
    ProductEditViewComponent
  ],
  templateUrl: './product-edit-modal.component.html',
  styleUrl: './product-edit-modal.component.scss'
})
export class ProductEditModalComponent implements OnInit {

  title:string="Agregar Producto"
  @Input() editMode:boolean=false;
  @Input() productoId:string|null=null;

  @ViewChild('cp_productview') productView: any;
  onSuccess: EventEmitter<any> = new EventEmitter();

  constructor(private modalRef: BsModalRef){}

  ngOnInit(): void {
    console.log(this.editMode, this.productoId);
    if(this.productoId) this.title="Editar Producto";
  }

  close(){
    //console.log(this.modalRef);
    this.modalRef.hide();
  }

  guardar(){
    if(this.productView){
      this.productView.guardar();
    }
  }

  onSaveSuccesss(){
    this.close();
    this.onSuccess.emit();
  }
}
