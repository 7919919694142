<app-spinner [loading]="loading"></app-spinner>
<form [formGroup]="form" autocomplete="off">
  <div class="row">
    <div class="col-md-4">
      <div class="mb-1">
        <label for="i-nombre" class="form-label">Tipo *</label>
        <app-select-catalog
          [size]="'sm'"
          [ngClass]="{
            'is-invalid': submitted && f.tipoProducto.errors
          }"
          #cmbTipoProcucto
          (changeValue)="changeTipoProducto($event)"
        ></app-select-catalog>
        <div
          *ngIf="submitted && f.tipoProducto.errors"
          class="invalid-feedback"
        >
          <span *ngIf="f.tipoProducto.errors.required"
            >Tipo de producto es requerido</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="mb-1" *ngIf="!aplicaProductoServicio">
        <label for="i-nombre" class="form-label">P.Terminado</label>
        <div class="form-check form-switch form-switch-md mt-1">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="ckeckProductoTerminado"
            (change)="changeCheckProductoTerminado($event)"
          />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-1" *ngIf="!aplicaProductoServicio">
        <label for="i-casa-quimica" class="form-label">Casa Quimica</label>
        <app-select-catalog
          [size]="'sm'"
          #cmbCasaQuimica
          (changeValue)="changeTipoCasaQuimica($event)"
        ></app-select-catalog>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="mb-1">
        <label for="i-codigo" class="form-label">Codigo *</label>
        <input
          [ngClass]="{ 'is-invalid': submitted && f.codigo.errors }"
          class="form-control form-control-sm"
          autocomplete="off"
          formControlName="codigo"
          type="text"
          id="i-codigo"
        />
        <div *ngIf="submitted && f.codigo.errors" class="invalid-feedback">
          <span *ngIf="f.codigo.errors.required"
            >Nombre de Codigo es requerido</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="mb-1">
        <label for="i-nombre" class="form-label">Nombre *</label>
        <input
          [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"
          class="form-control form-control-sm"
          autocomplete="off"
          formControlName="nombre"
          type="text"
          id="i-nombre"
        />
        <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
          <span *ngIf="f.nombre.errors.required"
            >Nombre de producto es requerido</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="mb-1">
        <label for="i-costo" class="form-label">Costo *</label>
        <input
          numbersOnly
          [ngClass]="{ 'is-invalid': submitted && f.costo.errors }"
          class="form-control form-control-sm"
          autocomplete="off"
          formControlName="costo"
          type="text"
          id="i-costo"
        />
        <div *ngIf="submitted && f.costo.errors" class="invalid-feedback">
          <span *ngIf="f.costo.errors.required"
            >Costo de producto es requerido</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-1">
        <label for="i-casa-quimica" class="form-label">Unidad Calculo</label>
        <app-select-catalog
          [size]="'sm'"
          #cmbUnidadCalculo
          (changeValue)="changeTipoUnidadCalculo($event)"
        ></app-select-catalog>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-descripcion" class="form-label"
          >Descripcion tecnicas</label
        >
        <textarea
          rows="3"
          class="form-control form-control-sm"
          maxlength="4000"
          formControlName="descripcion"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-descripcion" class="form-label">Remplazo</label>
        <textarea
          rows="2"
          class="form-control form-control-sm"
          maxlength="4000"
          formControlName="remplazo"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!form.value.ckeckProductoTerminado">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-descripcion" class="form-label">Procesos</label>
        <app-check-list
          #ckbItemsProcesos
          [items]="itemsProceso"
        ></app-check-list>
      </div>
    </div>
  </div>
</form>
