import { Injectable, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
//import 'sweetalert2/src/sweetalert2.scss'

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private toastr= inject(ToastrService);

  constructor() { }

  

  error(message:string){
    Swal.fire('',message, 'error');
  }

  info(message:string){
    Swal.fire('',message, 'info');
  }

  warning(message:string){
    Swal.fire('',message, 'warning');
  }

  success(message:string){
    Swal.fire('',message, 'success');
  }

  confirm(message:string){
    return Swal.fire({               
      html: message,
      showCancelButton: true,
      confirmButtonColor: '#00a1e1',
      cancelButtonColor: '#ccc',
      confirmButtonText: 'Si',
      cancelButtonText:'No'
    });
  }

  public get growl(){
    return new GrowlAlert(this.toastr);
  } 

}

class GrowlAlert{
  

  constructor(private toastr: ToastrService){}

  private _displayToast(message: string, type: string) {
    switch (type) {
      case 'success' : this.toastr.success(message); break;
      case 'info' : this.toastr.info(message); break;
      case 'warning' : this.toastr.warning(message); break;
      case 'error' : this.toastr.error(message); break;
    }
    
  }

  success(message: string) {
    //console.log(message);
    this._displayToast(message, 'success');
  };

  info(message: string) {
    this._displayToast(message, 'info');
  };

  error(message: string) {
    this._displayToast(message, 'error');
  };

  warning(message: string) {
    this._displayToast(message, 'warning');
  };

}
