<div class="input-group input-group-sm">
    <input
      [(ngModel)]="search"
      type="text"
      class="form-control custom-input-search i-c-search"
      style="width: 110px; flex: none"
      placeholder=""
      aria-label=""
      [readonly]="_disabled"
      aria-describedby="button-addon2"
      (keydown.enter)="onEnterPressed()"
      (change)="onChangeInput($event)"
    />
    <button
      #btnbuscar
      class="btn btn-outline-secondary custom-button-search i-c-search"
      type="button"
      (click)="onEnterPressed()"
      id="button-addon2"
      [disabled]="_disabled"
    >
      <span [class]="imageUrl" role="status" aria-hidden="true"></span>
    </button>
    <input type="text" [(ngModel)]="resul" class="form-control  i-c-search" readonly placeholder="" />
  </div>
