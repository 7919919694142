<app-spinner [loading]="loading"></app-spinner>
<div class="container">
  <div [@toggleDiv]="isEditVisible ? 'hide' : 'show'" id="establecimento-list">
    <h5 class="m-0">Establecimientos</h5>
    <h6 cslass="mt-4 mb-3">{{ title }}</h6>
    <hr />
    <div>
      <button
        type="button"
        class="btn btn-primary btn-sm btn-info"
        (click)="showEdit()"
      >
        <i class="bx bx-save font-size-16 align-middle me-2"></i>
        Agregar
      </button>
    </div>
    <div style="margin-top: 10px">
      <div class="card border">
        <div class="card-body">
          <div class="d-flex flex-wrap">
            <h5 class="card-title mb-3 me-2">Subscribes</h5>

            <div class="dropdown ms-auto" dropdown>
              <a
                class="text-muted dropdown-toggle font-size-16"
                dropdownToggle
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
              >
                <i class="mdi mdi-dots-horizontal"></i>
              </a>

              <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);"
                  >Another action</a
                >
                <a class="dropdown-item" href="javascript: void(0);"
                  >Something else here</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);"
                  >Separated link</a
                >
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <div>
              <p class="text-muted mb-1">Total Subscribe</p>
              <h4 class="mb-3">10,512</h4>
              <p class="text-success mb-0">
                <span>0.6 % <i class="mdi mdi-arrow-top-right ms-1"></i></span>
              </p>
            </div>
            <div class="ms-auto align-self-end">
              <i class="bx bx-building-house display-4 text-light"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [@toggleDiv]="isEditVisible ? 'show' : 'hide'" id="establecimento-edit">
    <h5 class="m-0">Establecimiento</h5>
    <h6 cslass="mt-4 mb-3">{{ titleEdit }}</h6>
    <hr />
    <div style="margin-top: 10px">
      <form [formGroup]="form" autocomplete="off">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-1">
              <label class="form-label">No. establecimiento*</label>
              <input
                numbersOnly
                [allowDecimals]="false"
                [ngClass]="{ 'is-invalid': submitted && f.numero.errors }"
                class="form-control form-control-sm"
                autocomplete="off"
                formControlName="numero"
                type="text"
                [maxlength]="3"
                id="i-numero"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-1">
              <label class="form-label">Nombre comercial</label>
              <input
                [ngClass]="{ 'is-invalid': submitted && f.nombreComercial.errors }"
                class="form-control form-control-sm"
                autocomplete="off"
                formControlName="nombreComercial"
                type="text"
                id="i-nombreComercial"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-1">
              <label class="form-label">Direccion</label>
              <textarea
                [ngClass]="{ 'is-invalid': submitted && f.direccion.errors }"
                class="form-control form-control-sm"
                autocomplete="off"
                rows="3"
                formControlName="direccion"
                type="text"
                [maxlength]="3"
                id="i-direccion"></textarea>
              
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="mb-1">
              <label class="form-label">Estado</label>
              <div class="form-check form-switch m-0">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="estado"
                />
              </div>              
            </div>
          </div>
        </div>
        
      </form>
    </div>
    <hr />
    <div>
      <button
        type="button"
        class="btn btn-primary btn-sm btn-info"
        (click)="showList()"
      >
        <i class="bx bx-save font-size-16 align-middle me-2"></i>
        Guardar
      </button>
    </div>
  </div>
</div>
