import { Routes } from '@angular/router';
import { isAuthenticatedGuard, isNotAuthenticatedGuard } from './account/guards';
import { LoginComponent } from './account/login/login.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { DefaultComponent } from '@pages/dashboard/default/default.component';
import { FormulaListComponent } from '@pages/administration/formulas/formula-list/formula-list.component';
import { FormulaEditComponent } from '@pages/administration/formulas/formula-edit/formula-edit.component';
import { CatalogListComponent } from '@pages/administration/catalogs/catalog-list/catalog-list.component';
import { FlowListComponent } from '@pages/administration/flows/flow-list/flow-list.component';
import { ProductListComponent } from '@pages/inventory/products/product-list/product-list.component';
import { ProductEditComponent } from '@pages/inventory/products/product-edit/product-edit.component';
import { PersonListComponent } from '@pages/persons/person-list/person-list.component';
import { PersonEditComponent } from '@pages/persons/person-edit/person-edit.component';
import { OrderListComponent } from '@pages/inventory/order/order-list/order-list.component';
import { OrderEditComponent } from '@pages/inventory/order/order-edit/order-edit.component';
import { ConfigurationComponent } from '@pages/company/configuration/configuration.component';
import { CatalogDetailListComponent } from '@pages/administration/catalogs/catalog-details/catalog-detail-list/catalog-detail-list.component';
import { FlowEditComponent } from '@pages/administration/flows/flow-edit/flow-edit.component';
import { ClienteListComponent } from '@pages/transaction/compra-venta/cliente/cliente-list/cliente-list.component';
import { ClienteEditComponent } from '@pages/transaction/compra-venta/cliente/cliente-edit/cliente-edit.component';
import { ProveedorListComponent } from '@pages/transaction/compra-venta/proveedor/proveedor-list/proveedor-list.component';
import { ProveedorEditComponent } from '@pages/transaction/compra-venta/proveedor/proveedor-edit/proveedor-edit.component';
import { ReportOrderComponent } from '@pages/reports/report-order/report-order.component';
import { PlannerComponent } from '@pages/inventory/planner/planner.component';
import { OrderListComponent as ProductionOrderListComponent} from '@pages/production/order/order-list/order-list.component';
import { OrderEditComponent as ProductionOrderEditComponent} from '@pages/production/order/order-edit/order-edit.component';
import { TaskBoardComponent } from '@pages/production/planner/task-board/task-board.component';
import { UserListComponent } from '@pages/administration/users/user-list/user-list.component';
import { UserEditComponent } from '@pages/administration/users/user-edit/user-edit.component';



export const routes: Routes = [
    {
        path: 'auth/login',
        component: LoginComponent,
        canActivate: [isNotAuthenticatedGuard],
      },
      {
        path: '',
        component: LayoutsComponent,
        canActivate: [isAuthenticatedGuard],
        pathMatch: 'prefix',
        children: [
          { path: 'dashboard', component: DefaultComponent },
          {
            path: 'company',
            children: [
              { path: 'configuration', component: ConfigurationComponent },
            ],
          },
          {
            path: 'administration',
            children: [
              {
                path: 'user',
                children: [
                  { path: 'list', component: UserListComponent },
                  { path: 'add', component: UserEditComponent },
                  { path: 'edit/:id', component: UserEditComponent },
                ],
              },
              {
                path: 'formula',
                children: [
                  { path: 'list', component: FormulaListComponent },
                  { path: 'add', component: FormulaEditComponent },
                  { path: 'edit/:id', component: FormulaEditComponent },
                ],
              },
              {
                path: 'catalog',
                children: [
                  { path: '', component: CatalogListComponent },
                  {
                    path: 'detail/:id/list',
                    component: CatalogDetailListComponent,
                  },
                ],
              },
              //{ path: 'catalog/detail/:id/list', component: CatalogDetailListComponent },
              {
                path: 'flow',
                children: [
                  { path: 'list', component: FlowListComponent },
                  { path: 'edit/:id', component: FlowEditComponent }
                ],
              },
            ],
          },
          {
            path: 'person',
            children: [
              { path: 'list', component: PersonListComponent },
              { path: 'add', component: PersonEditComponent },
              { path: 'edit/:id', component: PersonEditComponent },
            ],
          },
          {
            path: 'inventory',
            children: [
              {
                path: 'product',
                children: [
                  { path: 'list', component: ProductListComponent },
                  { path: 'add', component: ProductEditComponent },
                  { path: 'edit/:id', component: ProductEditComponent },
                ],
              },
              {
                path: 'order',
                children: [
                  { path: 'list', component: OrderListComponent },
                  { path: 'add', component: OrderEditComponent },
                  { path: 'edit/:id', component: OrderEditComponent },
                ],
              },
              {
                path: 'planner',
                children: [
                  { path: 'board', component: PlannerComponent },
                ],
              },
            ],
          },
          {
            path:'production',
            children:[
              {
                path: 'order',
                children: [
                  { path: 'list', component: ProductionOrderListComponent },
                  { path: 'add', component: ProductionOrderEditComponent },
                  { path: 'edit/:id', component: ProductionOrderEditComponent },
                ],
              },
              {
                path: 'planner',
                children: [
                  { path: 'board', component: TaskBoardComponent },
                ],
              },
            ]
          },
          {
            path: 'transaction',
            children:[
              {
                path:'compra-venta/cliente',
                children:[
                  { path:'list', component:ClienteListComponent },
                  { path:'add', component:ClienteEditComponent },
                  { path:'edit/:id', component:ClienteEditComponent }
                ]
              },
              {
                path:'compra-venta/proveedor',
                children:[
                  { path:'list', component:ProveedorListComponent },
                  { path:'add', component:ProveedorEditComponent },
                  { path:'edit/:id', component:ProveedorEditComponent }
                ]
              }
            ]
          },
          {
            path: 'reports',
            children:[
              { path:'orders', component:ReportOrderComponent },
            ]
          },
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full',
          },
        ],
      },
];
