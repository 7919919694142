import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@components/index';
import { PageButtonsComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import moment from 'moment';

@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [DatatableComponent,PageButtonsComponent],
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.scss'
})
export class OrderListComponent {
  private router=inject(Router);

  botones: PageButton[];
  title: string = 'Listar Producciones';
  optionModel = {};

  ngOnInit(): void {
    this._initBotones();
    this._initDatatable();
  }

  private _initBotones(){
    this.botones = [
      {
        label:"agregar",
        icon:"bx bx-plus",
        cssClass:"btn-success",
        actionType:()=>this._add()
      },
    ]
  }

  private _initDatatable(){
    var columns:any = [
      { 
        className:'col--opt-2 no-sort', 
        title:'...', 
        buttons:[
          
          {
            icon:"fas fa-pencil-alt",            
            onclick:(row:any)=>this._edit(row.id)
          },
          {
            className: 'btn-outline-danger',            
            icon:"far fa-trash-alt",            
            onclick:(row:any)=>{              
            }
          },
        ]              
      },
      {
        title: 'N. Orden',
        data: 'numero'
      }, 
      {
        title: 'Creado',
        data: 'usuario'
      }, 
      
      {
        title: 'Fecha Creacion',
        data: 'fechaCreacion',
        render:(data: any)=>{
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY')
          return local;
        }
      },     
      {
        title: 'Fecha Finalizacion',
        data: 'fechaFinalizacion',
        render:(data: any)=>{
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY')
          return local;
        }
      },              
      
    ]

    this.optionModel ={      
      filtering:false,
      searching:true,
      
      serverSide:true,
      columns: columns,
      url:"ordenproduccion/listar",  
    };
  }

  private _add(){
    this.router.navigate(['production/order/add']);
  }

  private _edit(id:string){
    this.router.navigate([`production/order/edit/${id}`]);
  }
}
