import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SecureCookieService {

  private readonly tokenKey = 'miToken';
  private readonly urlKey = 'miNavigateUrl';
  private readonly path = '/';
  private readonly isSecure = true;
  private readonly sameSite = 'Strict';


  constructor(private cookieService: CookieService) { }

  // Método para establecer una cookie
  private setCookie(key: string, value: string): void {
    this.cookieService.set(key, value, undefined, this.path, undefined, this.isSecure, this.sameSite);
  }

  // Método para establecer el token en una cookie segura y HttpOnly
  setToken(token: string): void {
    this.setCookie(this.tokenKey, token);
  }

  // Método para establecer la URL en una cookie segura y HttpOnly
  setCurrentUrl(url: string): void {
    this.setCookie(this.urlKey, url);
  }

  // Método para obtener el token almacenado en la cookie
  getToken(): string | null {
    return this.cookieService.get(this.tokenKey);
  }

  // Método para obtener la URL almacenada en la cookie
  getCurrentUrl(): string | null {
    return this.cookieService.get(this.urlKey);
  }

  // Método para eliminar las cookies al cerrar sesión
  logout(): void {
    this.cookieService.delete(this.tokenKey, this.path);
    this.cookieService.delete(this.urlKey, this.path);
  }

}
