import { ApplicationConfig,importProvidersFrom,LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { errorInterceptor } from './core/helpers/error.interceptor';
import { loggerInterceptor } from './core/helpers/logger.interceptor';
import { IMAGE_CONFIG } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideAnimations } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DndModule } from 'ngx-drag-drop';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export const provideTranslation = () => ({
  defaultLanguage: 'es',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});

export const appConfig: ApplicationConfig = {
  providers: [
    CookieService,
    provideAnimations(),
    importProvidersFrom([
      HttpClientModule,
      ToastrModule.forRoot({
        timeOut: 2000,
        positionClass: 'toast-top-right',
      }),
      TranslateModule.forRoot(provideTranslation()),
      ModalModule.forRoot(),
      TabsModule.forRoot(),
      DndModule,
      BsDatepickerModule.forRoot(),
      TooltipModule.forRoot()
    ]),
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true,
      },
    },
    provideHttpClient(withInterceptors([errorInterceptor, loggerInterceptor])),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
  ]
};
