<app-spinner [loading]="loading"></app-spinner>
<h5 class="m-0">Catalogo</h5>
<h6 cslass="mt-4 mb-3">{{title}}</h6>
<hr />
<form [formGroup]="form" autocomplete="off">
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-nemonico" class="form-label">Nemonico *</label>
        <input
          [ngClass]="{ 'is-invalid': submitted && f.nemonico.errors }"
          class="form-control form-control-sm"
          autocomplete="off"
          formControlName="nemonico"
          type="text"
          id="i-nemonico"
        />
        <div *ngIf="submitted && f.nemonico.errors" class="invalid-feedback">
          <span *ngIf="f.nemonico.errors.required"
            >Nombre de catalogo es requerido</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-descripcion" class="form-label">Descripcion *</label>
        <textarea
          [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }"
          class="form-control form-control-sm"
          autocomplete="off"
          formControlName="descripcion"
          maxlength="100"
        ></textarea>
        <div *ngIf="submitted && f.descripcion.errors" class="invalid-feedback">
          <span *ngIf="f.descripcion.errors.required"
            >Descripcion de catalogo es requerido</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-publico" class="form-label mb-0">Catalogo Interno</label>
        <div class="form-check form-switch form-switch-sm mt-0">
          <input
            class="form-check-input"
            type="checkbox" 
            name="checkCatalogoInterno"
            formControlName="checkCatalogoInterno"    
            (change)="changeCheckbox($event)"       
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-requiere-codigo" class="form-label mb-0">Requiere codigo en detalle</label>
        <div class="form-check form-switch form-switch-sm mt-0">
          <input
            class="form-check-input"
            type="checkbox" 
            name="checkRequiereCodigoDetalle"
            (change)="changeCheckbox($event)" 
            formControlName="checkRequiereCodigoDetalle"           
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-requiere-codigo" class="form-label mb-0">Requiere valor en detalle</label>
        <div class="form-check form-switch form-switch-sm mt-0">
          <input
            class="form-check-input"
            type="checkbox" 
            name="checkRequiereValorDetalle"
            (change)="changeCheckbox($event)" 
            formControlName="checkRequiereValorDetalle"           
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label for="i-descripcion" class="form-label mb-0">Aplica dependencia</label>
        <div class="form-check form-switch form-switch-sm mt-0">
          <input
            class="form-check-input"
            type="checkbox"
            (change)="changeAplicaDependencia($event)"
            formControlName="checkDependenciaCatalogo"
          />
        </div>
      </div>
    </div>
  </div>
  
  @if(f.checkDependenciaCatalogo && f.checkDependenciaCatalogo.value){
  <div class="row">
    <div class="col-md-12">
      <div class="mb-1">
        <label class="form-label">Catalogo dependiente *</label>
        <app-select-catalog
          size="sm"
          [ngClass]="{
            'is-invalid': submitted && f.tipoCatalogoDependencia.errors
          }"
          #cmbTipoCatalogoDependencia
          (changeValue)="changeTipoCatalogoDependencia($event)"
        ></app-select-catalog>
        <div
          *ngIf="submitted && f.tipoCatalogoDependencia.errors"
          class="invalid-feedback"
        >
          <span *ngIf="f.tipoCatalogoDependencia.errors.required"
            >Catalogo padre es requerido</span
          >
        </div>
      </div>
    </div>
  </div>
  }
</form>
<hr />

<button
  type="button"
  class="btn btn-primary btn-sm btn-info"
  (click)="guardar()"
>
  <i class="bx bx-save font-size-16 align-middle me-2"></i>
  Guardar
</button>
