import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SimplebarAngularModule } from 'simplebar-angular';
import { LanguageService } from '../../core/services/language.service';
import { CookieService } from 'ngx-cookie-service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AuthService } from '../../account/services/auth.service';
import { Router } from '@angular/router';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SimplebarAngularModule,
    BsDropdownModule
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent  implements OnInit{
  
  private router=inject(Router);
  private auth=inject(AuthService);
  private languageService =inject(LanguageService);
  public translate = inject(TranslateService);  
  public _cookiesService = inject(CookieService);
  private msg = inject(AlertService);
  
  notifications: number = 0;

  theme: string='horizontal';
  flagvalue: any;
  valueset: any;
  countryName: any;
  cookieValue: any;
  openMobileMenu: boolean;
  element: any;

  urlPhoto = this.auth.currentUser()?.userPhoto??""  
  userNameCompleto = this.auth.currentUser()?.userName;

  listLang: any = [
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
  ];

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  //constructor(@Inject(DOCUMENT) private document: any){}

  ngOnInit(): void {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter((x:any) => x.lang === this.cookieValue);
    this.countryName = val.map((element:any) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/spain.jpg'; }
    } else {
      this.flagvalue = val.map((element:any) => element.flag);
    }
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  toggleRightSidebar(){
    this.settingsButtonClicked.emit();
  }

  logout(){
    this.msg.confirm('¿Estás seguro de que deseas salir del sistema?</br>Si sales ahora, podrías perder datos no guardados.')
    .then(({ value }) => {
      if (value) {
        this.auth.logout(); 
      }
    });
  }

  fullscreen(){

  }

  empresaConfiguracion(){
    this.router.navigate([`company/configuration`]);
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

}
