<div class="container-fluid">
  <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
  <form [formGroup]="form" autocomplete="off">
    <div class="row">
      <div class="col-12">
        <div class="card mb-1">
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">Fecha de Emision:</div>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-sm-3">Tipo de documento:</div>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" />
              </div>
              <div class="col-sm-4">
                <input
                  type="text"
                  value="Factura"
                  class="form-control form-control-sm"
                />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-sm-3">Establecimiento:</div>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-sm-3">Punto Emision:</div>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-sm-3"># de Documento:</div>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-sm-3">Persona:</div>
              <div class="col-sm-4">
                <app-input-search-dual
                  [config]="configClient"
                  [ngClass]="{
                    'is-invalid': submitted && f.cliente.errors
                  }"
                  formControlName="cliente"
                  #searchPersona
                ></app-input-search-dual>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-sm-3">Referencia:</div>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-sm-3">Vendedor:</div>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-sm-3">Vencimiento:</div>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">Orden de Compra/Venta:</div>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  
  <div class="row">
    <div class="col-12">
      <div class="card mb-1">
        <div class="card-body">
          <tabset [justified]="false" class="nav-tabs nav-tabs-custom">
            <tab
              id="productos"
              heading="Productos"
              (selectTab)="changeTab($event)"
            >
              <ng-template
                [ngTemplateOutlet]="TabProductos"
              ></ng-template>
            </tab>
            <tab
              id="retencion"
              heading="Retencion"
              (selectTab)="changeTab($event)"
            >
              <ng-template
                [ngTemplateOutlet]="TabRetencion"
              ></ng-template>
            </tab>
            <tab
              id="formapago"
              heading="Forma de Pago"
              (selectTab)="changeTab($event)"
            >
              <ng-template
                [ngTemplateOutlet]="TabFormaPago"
              ></ng-template>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-9">
                Descripcion
                <textarea rows="3" class="form-control form-control-sm"></textarea>
            </div>
            <div class="col-md-3">
              <div class="input-container">
                <div>Subtotal 12%</div>
                <div><input value="100.45" type="text" class="input-sm" readonly></div>
              </div>
              <div class="input-container">
                <div>Subtotal 0%</div>
                <div><input value="100.45" type="text" class="input-sm" readonly></div>
              </div>
              <div class="input-container">
                <div>Descuento 0%</div>
                <div><input value="100.45" type="text" class="input-sm" readonly></div>
              </div>
              <div class="input-container">
                <div>IVA</div>
                <div><input value="100.45" type="text" class="input-sm" readonly></div>
              </div>
              <div class="input-container">
                <div>ICE</div>
                <div><input value="100.45" type="text" class="input-sm" readonly></div>
              </div>
              <div class="input-container">
                <div>TOTAL</div>
                <div><input value="100.45" type="text" class="input-sm" readonly></div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div>
</div>

<ng-template #TabProductos>
  <app-datatable-editor
    #dtproductos
    [options]="optionModelProductos"
  ></app-datatable-editor>
</ng-template>
<ng-template #TabRetencion> </ng-template>
<ng-template #TabFormaPago> </ng-template>
