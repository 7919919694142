<div class="container-fluid">
    <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
    <div class="row">
      <div class="col-12">
        <app-spinner [loading]="loading"></app-spinner>
        <div class="row">
          <div class="col-12">
            <div class="card mb-0">
              <div class="card-body">
                <form [formGroup]="form" autocomplete="off">
                  <div class="row">
                    <label class="col-md-1 col-form-label"
                      >Numero:</label
                    >
                    <div class="col-md-3">
                      <input
                        class="form-control form-control-sm"
                        formControlName="secuenciaOrden"
                        type="text"
                        readonly
                      />
                    </div>
                    <label class="col-md-2 col-form-label"
                      >Fecha Inicio:</label
                    >
                    <div class="col-md-2">
                      <app-datepicker
                        [minDate]="minDate"
                        [ngClass]="{
                          'is-invalid': submitted && f.fechaCreacion.errors
                        }"
                        formControlName="fechaCreacion"
                        #fechaCreacion
                      ></app-datepicker>
                    </div>
                    <label class="col-md-2 col-form-label"
                      >Fecha Finalizacion:</label
                    >
                    <div class="col-md-2">
                      <app-datepicker
                        [minDate]="minDate"
                        [ngClass]="{
                          'is-invalid': submitted && f.fechaFinalizacion.errors
                        }"
                        formControlName="fechaFinalizacion"
                        #fechaFinalizacion
                      ></app-datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-md-1 col-form-label"
                      >Estado:</label
                    >
                    <div class="col-md-3">
                      <input
                        class="form-control form-control-sm"
                        formControlName="estado"
                        type="text"
                        readonly
                      />
                    </div>
                    <label class="col-md-2 col-form-label"
                      >Requerido por:</label
                    >
                    <div class="col-md-2">
                      <input
                        class="form-control form-control-sm"
                        formControlName="requerido"
                        type="text"
                        readonly
                      />
                    </div>
                    <div class="col-md-4">
                    </div>
                    
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <div class="card mb-0">
              <div class="card-body m-0">
                <app-datatable-editor
                  #dtproduccion
                  [options]="optionModel"
                ></app-datatable-editor>
                @if(!bloquear){
                  <button #btnadddetail class="btn btn-success btn-sm" (click)="addNewRow()">Add Order</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
