import { Component, ElementRef, computed, effect, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from './account/services/auth.service';
import { AuthStatus } from './account/interfaces';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  title = 'curtiembre-industry';

  private authService = inject(AuthService);
  private router = inject(Router);
  private elementRef= inject(ElementRef);
  
  private readonly excludeURL=["/auth/login","/"]

  public finishedAuthCheck = computed(() => {
    if (this.authService.authStatus() === AuthStatus.checking) {
      return false;
    }
    return true;
  });

  public authStatusChangeEffect = effect(() => {
    switch (this.authService.authStatus()) {
      case AuthStatus.checking:
        return;
      case AuthStatus.authenticated:
        //console.log('AuthStatus.authenticated',);
        this.router.navigateByUrl(this.authService.getCurrentUrl());
        return;
      case AuthStatus.notAuthenticated:
        this.router.navigateByUrl('/auth/login');
        return;
    }
  });

  constructor() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event:any) => {
      if(!this.excludeURL.includes(event.url)){
        this.authService.setCurrentUrl(event.url);
      }
    });
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.removeAttribute('ng-version');
  }

}
