import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DatatableExtensionService {

  private readonly decimalSeparator:string='.';
  //private readonly allowDecimals: boolean = true;
  private readonly integerUnsigned: string = '^[0-9]*$';

  /*private isAllowedKey(key: string): boolean {
    return ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key);
  }

  private isDecimalAllowedKey(key: string): boolean {
    return ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete','.'].includes(key);
  }*/

  constructor() {}

  addAfterRowByPos(dt: any, data: any, index: number) {
    /*//console.log('addAfterRowByPos', index);

    //const dt = this;
    console.log(dt);

    // Guarda la página actual
    var currentPage = dt.page();

    // Inserta la fila
    dt.row.add(data);

    // Mueve la fila agregada a la posición deseada
    var rowCount = dt.data().length - 1,
      insertedRow = dt.row(rowCount).data(),
      tempRow;

    for (var i = rowCount; i > index + 1; i--) {
      tempRow = dt.row(i - 1).data();
      dt.row(i).data(tempRow);
      dt.row(i - 1).data(insertedRow);
    }

    // Refresca la página actual
    dt.page(currentPage).draw(false);
    */
   var currentPage = dt.page();

    // Guarda el estado original de las filas
    const originalData = dt.data().toArray();
    
    try {
        // Inserta la fila
        dt.row.add(data).draw(); // Asegúrate de dibujar para actualizar el DOM

        // Mueve la fila agregada a la posición deseada
        var rowCount = dt.data().length - 1,
            insertedRow = dt.row(rowCount).data(),
            tempRow;

        // Reorganiza las filas
        for (var i = rowCount; i > index; i--) {
            if (i - 1 >= 0 && dt.row(i - 1).length) { // Verifica que la fila existe
                tempRow = dt.row(i - 1).data();
                dt.row(i).data(tempRow);
            } else {
                throw new Error('Fila no encontrada para el índice: ' + (i - 1));
            }
        }

        // Inserta la nueva fila en la posición correcta
        if (dt.row(index + 1).length) { // Verifica que la fila existe
            dt.row(index + 1).data(insertedRow);
        } else {
            throw new Error('Fila no encontrada para el índice: ' + (index + 1));
        }

        // Refresca la página actual
        dt.page(currentPage).draw(false);
    } catch (error) {
        console.error('Error al modificar las filas:', error);

        // Restaura el estado original de las filas en caso de error
        dt.clear().rows.add(originalData).draw();
        dt.page(currentPage).draw(false);
    }
  }

  addBeforeRowByPos(dt: any, data: any, index: number) {
    // Guarda la página actual
    var currentPage = dt.page();

    // Guarda el estado original de las filas
    const originalData = dt.data().toArray();
    
    try {
        // Inserta la fila
        dt.row.add(data);

        // Mueve la fila agregada a la posición deseada
        var rowCount = dt.data().length - 1,
          insertedRow = dt.row(rowCount).data(),
          tempRow;

        for (var i = rowCount; i >= index + 1; i--) {
            // Verifica que la fila existe antes de intentar accederla
            if (i - 1 >= 0) {
                tempRow = dt.row(i - 1).data();
                dt.row(i).data(tempRow);
            }
        }

        // Inserta la nueva fila en la posición correcta
        dt.row(index).data(insertedRow);

        // Refresca la página actual
        dt.page(currentPage).draw(false);
    } catch (error) {
        console.error('Error al modificar las filas:', error);

        // Restaura el estado original de las filas en caso de error
        dt.clear().rows.add(originalData).draw();
        dt.page(currentPage).draw(false);
    }
    /*
    //console.log('addBeforeRowByPos');

    //const dt = this;
    //console.log(dt);

    // Guarda la página actual
    var currentPage = dt.page();

    // Inserta la fila
    dt.row.add(data);

    // Mueve la fila agregada a la posición deseada
    var rowCount = dt.data().length - 1,
      insertedRow = dt.row(rowCount).data(),
      tempRow;

    for (var i = rowCount; i >= index + 1; i--) {
      tempRow = dt.row(i - 1).data();
      dt.row(i).data(tempRow);
      dt.row(i - 1).data(insertedRow);
    }

    // Refresca la página actual
    dt.page(currentPage).draw(false);
    */
  }

  onlyNumbers(e: any) {
    
    //let theEvent = e || window.event;
    //let cursorPosition: number = e.target['selectionStart'];
    //let originalValue: string = e.target['value'];
    let key: string = this._getName(e);
    let controlOrCommand = e.ctrlKey === true || e.metaKey === true;
    //let signExists = originalValue.includes('-');
    //let separatorExists = originalValue.includes(this.decimalSeparator);
    
    //'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'
    let allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab','Delete'];
    
    if (
      allowedKeys.indexOf(key) != -1 ||
      // Allow: Ctrl+A and Command+A
      (key == 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key == 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key == 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key == 'x' && controlOrCommand)
    ) {
      // let it happen, don't do anything
      return;
    }

    let isNumber = new RegExp(this.integerUnsigned).test(key);
    if (!isNumber) {      
      e.preventDefault();
    }
    else return;
  }

  onlyDecimal(e: any) {
    const allowDecimals:boolean= true;

    let cursorPosition: number = e.target['selectionStart'];
    let originalValue: string = e.target['value'];
    let key: string = this._getName(e);
    let controlOrCommand = e.ctrlKey === true || e.metaKey === true;
    let signExists = originalValue.includes('-');
    let separatorExists = originalValue.includes(this.decimalSeparator);

    // allowed keys apart from numeric characters
    let allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab','Delete'];

    let separatorIsCloseToSign = signExists && cursorPosition <= 1;
    if (allowDecimals && !separatorIsCloseToSign && !separatorExists) {
      if (this.decimalSeparator == '.') allowedKeys.push('.');
      else allowedKeys.push(',');
    }

    if (
      allowedKeys.indexOf(key) != -1 ||
      // Allow: Ctrl+A and Command+A
      (key == 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key == 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key == 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key == 'x' && controlOrCommand)
    ) {
      // let it happen, don't do anything
      return;
    }

    // allow number characters only
    let isNumber = new RegExp(this.integerUnsigned).test(key);

    if (isNumber) {
      if(allowDecimals && separatorExists){
        let decimalCount = (originalValue.split(this.decimalSeparator)[1] || '').length;
        if (decimalCount >= 2) {
          e.preventDefault();
          return;
        }
      }
      return;
    }
    else e.preventDefault();


  }

  private _getName(e: any): any {
    if (e.key) {
      return e.key;
    } else {
      // for old browsers
      if (e.keyCode && String.fromCharCode) {
        switch (e.keyCode) {
          case 8:
            return 'Backspace';
          case 9:
            return 'Tab';
          case 27:
            return 'Escape';
          case 37:
            return 'ArrowLeft';
          case 39:
            return 'ArrowRight';
          case 188:
            return ',';
          case 190:
            return '.';
          case 109:
            return '-'; // minus in numbpad
          case 173:
            return '-'; // minus in alphabet keyboard in firefox
          case 189:
            return '-'; // minus in alphabet keyboard in chrome
          default:
            return String.fromCharCode(e.keyCode);
        }
      }
    }
  } 

}
