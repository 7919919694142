import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { SecureCookieService } from '../../account/services/secure-cookie.service';
import { environment } from '../../../environments/environment';

export const loggerInterceptor: HttpInterceptorFn = (req, next) => {
  //console.log(`request ->${req.url}`);

  if (req.url.includes('assets/i18n')) {
    return next(req); 
  }
  else if(req.url.includes('seguridad/signin')){
    req  = req.clone({
      url: `${environment.baseUrl}/${req.url}`
    });
    return next(req);
  }

  const cookie = inject(SecureCookieService);
  const token = cookie.getToken();
  //console.log('token',token);

  if(token){
    req  = req.clone({
      url: `${environment.baseUrl}/${req.url}`,
      headers:req.headers.set('Authorization',`Bearer ${token}`),
      //withCredentials: true
    });
  }
  return next(req);
};
