import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { PageButtonsComponent, SpinnerComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { get } from 'jquery';

@Component({
  selector: 'app-planner',
  standalone: true,
  imports: [
    CommonModule,
    PageButtonsComponent, 
    BsDropdownModule,
    DragDropModule,
    SpinnerComponent
  ],
  templateUrl: './planner.component.html',
  styleUrl: './planner.component.scss',
})
export class PlannerComponent implements OnInit {

  private http = inject(HttpClient);

  loading: boolean = true;

  botones: PageButton[];
  title: string = 'Programacion';
  
  procesoAsignado:any = {};
  procesos:any[] = [];
  disponibles = [];
  asignados = [];
  private allItems:Map<string, number> = new Map<string, number>(); 


  ngOnInit(): void {
    this._obtener();
  }

  getValue(key:any):number | undefined{
    return this.allItems.get(key);
  }
  
  drop(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      const {id, data} = event.container;
      const model = data[event.currentIndex];

      if(this.procesoAsignado.id){
        if(id==="asignadosList"){
          model.proceso =this.procesoAsignado.id;
        }else{
          model.proceso = null;
        }
      }

      this._actualizar(model);
      //console.log(this.asignados,this.disponibles);
    }
  }

  changeProcesoAsignado(item:any){
    this.procesoAsignado = item;

    this.asignados = this.asignados.filter((it:any)=> it.proceso===this.procesoAsignado.id);
    this._obtener();

  }

  private _obtener() {
    this.loading= true;

    this.http.get('planificacion/pedido/board').subscribe({
      next: (data: any) => {
        const {exito,model,catalogos} = data;
        //console.log(model);
        
        this.disponibles = model.filter((it:any)=> it.proceso===null);
        this.procesos = catalogos.TP_PROCESO_PRODUCCION || [];
        if(this.procesos.length>0){
          if(this.procesoAsignado.id===undefined)this.procesoAsignado =  this.procesos[0];

          this.procesos.forEach((it:any)=>{

            var items = model.filter((i:any)=> i.proceso===it.id);
            this.allItems.set(it.id,items.length);

            if(it.id === this.procesoAsignado.id) this.asignados = items;

          });
          
          //this.asignados = model.filter((it:any)=> it.proceso===this.procesoAsignado.id);
        }

        

        this.loading = false
      },
      error: (error) => (this.loading = false),
    });
  }

  private _actualizar(model:any){

    this.loading = true;

    const m = {
      pedidoDetalleId:model.id,
      procesoId:model.proceso
    }

    this.http.put("planificacion/pedido",m).subscribe({
      next: (data: any) => {
        const {exito, mensaje}= data
        this.loading = false
        this._obtener();

      },
      error: (error) => (this.loading = false),
    });
  }
  
}
