<div class="right-bar">
  <ngx-simplebar class="h-100">
    <div class="rightbar-title px-3 py-4">
      @if(autoClose){
      <a
        href="javascript:void(0);"
        class="right-bar-toggle float-end"
        (click)="close()"
      >
        <i class="mdi mdi-close noti-icon"></i>
      </a>
      } @if(dynamicComponent!==null){
      <!--<ng-container style="margin-top: 10px;" *ngComponentOutlet="dynamicComponent;injector: dynamicInjector;"></ng-container>-->
      <!--<ng-container style="margin-top: 10px;" *ngComponentOutlet="dynamicComponent;"></ng-container>-->
      <ng-container style="margin-top: 10px" #componentContainer></ng-container>
      }
    </div>
  </ngx-simplebar>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay" (click)="hide()"></div>
