import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ColumnButtonsModel,
  ColumnsModel,
  OptionsModel,
} from './datatable.model';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@services/alert.service';
import { TooltipDirective, TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-datatable',
  standalone: true,
  imports: [DataTablesModule, TooltipModule],
  templateUrl: './datatable.component.html',
  styleUrl: './datatable.component.scss',
})
export class DatatableComponent implements OnInit, AfterViewInit, OnDestroy {
  private msg = inject(AlertService);

  private http = inject(HttpClient);
  private renderer = inject(Renderer2);

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('dataTable', { static: false }) private table1: ElementRef;
  private dt: any;

  @Input() options: OptionsModel;
  @Input() filters: any = null;

  dtOptions: any = {};
  private setting: any = {};
  private links: Array<ColumnButtonsModel> = [];
  private externalParams: any;
  private hasExternalParams: Boolean = false;
  private autoLoad: Boolean = true;

  private defaults: OptionsModel = {
    method: 'get',
    url: '',
    buttons: [],
    columns: [],
    serverSide: false,
    processing: false,
    ordering: true,
    filtering: false,
    searching: false,
    responsive: false,
    showButtons: true,
    lengthChange: true,
    autoLoad: true,
    rowReordering: false,
    paging: true,
    info: true,
    data: [],
  };

  private tableEventListener: () => void;
  private resizeListener: () => void;

  constructor() {}

  ngOnDestroy(): void {
    this.tableEventListener();
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }

    /*document
      .querySelectorAll('[data-bs-toggle="tooltip"]')
      .forEach((element: any) => {
        const tooltipInstance = bootstrap.Tooltip.getInstance(element);
        if (tooltipInstance) {
          console.log('hola');
          tooltipInstance.hide();
        }
      });
    console.log('borrar');*/
  }

  ngAfterViewInit(): void {
    const that = this;
    /*this.resizeListener = () => {
      console.log('ajustar')
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: any) => {
          dtInstance.columns.adjust().responsive.recalc();
        });
      }
    };*/
    //window.addEventListener('resize', this.resizeListener);

    this.tableEventListener = this.renderer.listen(
      'document',
      'click',
      (event) => {
        const target = event.target as HTMLElement;

        // Check if the target has 'view-id' attribute
        if (!target.hasAttribute('view-id')) return;

        // Find the closest row element
        const row = that.dt.row($(target).closest('tr'));
        if (!row) return;

        const viewId:any = target.getAttribute('view-id');
        if (!viewId) return;

        // Get the corresponding link action
        const action:any = that.links[viewId];
        if (action) {
          action.onclick(row.data());
        }

        // Remove tooltip if it exists
        const tooltipDiv = document.querySelector(
          'div.tooltip.bs-tooltip-auto'
        );
        if (tooltipDiv) {
          this.renderer.removeChild(document.body, tooltipDiv);
        }
        /*const _target: any = event.target as HTMLElement;
        if (_target.hasAttribute('view-id')) {
          var row = that.dt.row($(_target).closest('tr'));
          if (row) {
            const view: any = _target.getAttribute('view-id');
            if (view) {
              const b: any = that.links[view];
              const tooltipDiv = document.querySelector(
                'div.tooltip.bs-tooltip-auto'
              );
              if (tooltipDiv) {
                this.renderer.removeChild(document.body, tooltipDiv);
              }
              if (b) b.onclick(row.data());
            }
          }
        }*/
      }
    );

    this.datatableElement.dtInstance.then((dtInstance: any) => {
      that.dt = dtInstance;

      $(dtInstance.tables().header()).addClass('table-light');

      if (!this.autoLoad) {
        $(dtInstance.table(0).node()).on('page.dt', () => {
          if (!this.setting.autoLoad) this.autoLoad = true;
        });
      }
    });
  }

  ngOnInit(): void {
    this._initDatatable();
  }

  private _initDatatable() {
    this.setting = { ...this.defaults, ...this.options };

    var _columns = this._getColumns();

    //console.log(this.options);

    if (this.setting.autoLoad != undefined) {
      this.autoLoad = this.setting.autoLoad;
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      lengthMenu: [5, 10, 25, 50, 75],
      lengthChange: this.setting.lengthChange,
      data: this.setting.data,
      serverSide: this.setting.serverSide,
      processing: this.setting.processing,
      searching: this.setting.searching,
      ordering: this.setting.ordering,
      language: {
        paginate: {
          first: '<i class="bx bx-chevron-left"></i>',
          last: '<i class="bx bx-chevron-right"></i>',
          next: '<i class="bx bx-chevrons-right"></i>',
          previous: '<i class="bx bx-chevrons-left"></i>',
        },
        emptyTable: 'No hay datos disponibles',
        info: 'Visualizando del _START_ hasta el _END_ de _TOTAL_ registros',
        infoEmpty: '0 registros',
        lengthMenu: 'Mostrar _MENU_ registros',
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      },
      dom: "<'row'<'col-sm-6'l><'col-sm-6 tbfB'fB>><'row'<'col-sm-12'tr>><'row'<'col-sm-5'i><'col-sm-7'p>>",
      columns: _columns.columns,
      columnDefs: _columns.columnDefs,
      responsive: this.setting.responsive,
      paging: this.setting.paging,
      info: this.setting.info,
      order: this.setting.ordering ? [[1, 'desc']] : undefined,
      deferRender: true,
      scrollX: true,
      search: {
        return: true,
      },
      drawCallback: () => {
        $('.dataTables_paginate > .pagination').addClass('pagination-rounded');

        //console.log(this.table1);

        document
          .querySelectorAll('[data-toggle="tooltip"]')
          .forEach((element: any) => {
            new bootstrap.Tooltip(element);
          });

        /*var tooltipTriggerList = document.querySelectorAll(
          '[data-toggle="tooltip"]'
        );
        tooltipTriggerList.forEach((element: any) => {
          new bootstrap.Tooltip(element);          
        });*/
      },
      initComplete: () => {},
    };

    if (this.setting.scrollX) {
      this.dtOptions.scrollX = this.setting.scrollX;
    }

    if (this.setting.onClickRow) {
      this.dtOptions.rowCallback = (
        row: Node,
        data: any[] | Object,
        index: number
      ) => {
        //const self = this;
        $('td', row).off('click');
        $('td', row).on('click', () => this.setting.onClickRow(data));
        return row;
      };
    }

    if (this.setting.rowReordering) {
      /*this.options.rowReorder = {
        selector: 'tr'
      };

      console.log(this.options.rowReorder)
      */
    }

    if (this.setting.url.length === 0) {
      this.dtOptions.data = this.setting.data;
    }

    if (this.setting.order) {
      this.dtOptions.order = this.setting.order;
    }

    if (this.setting.serverSide) {
      this._serverSide();
    }
  }

  private _serverSide() {
    this.dtOptions.ajax = (params: any, callback: any) => {
      if (this.autoLoad) {
        const { ordering, columns, start, length, search } = params;
        const sortBy = ordering ? columns[ordering[0].column].data : '';
        const sortDirection = ordering ? ordering[0].dir : '';

        const sparams: string[] = [];
        const rows = this.externalParams;

        columns.forEach((column: any) => {
          const { data, searchable } = column;
          let value = this.hasExternalParams ? rows[data] : undefined;
          if (value) delete rows[data];
          if (searchable)
            sparams.push(`${data}=${value || column.search.value}`);
        });

        if (this.hasExternalParams) {
          Object.keys(rows).forEach((key) => {
            sparams.push(`${key}=${rows[key]}`);
          });
        }

        const page = start / length + 1;
        const [url] = this.setting.url.split('?');

        const filterBy =
          this.filters && Object.keys(this.filters).length > 0
            ? this.filters
            : null;

        const pdata = {
          page,
          pageSize: length,
          search: search.value || null,
          sortBy,
          sortDirection,
          filterBy,
        };

        //console.log(pdata);

        if (this.setting.url && this.setting.url.length > 0) {
          this.http.post(url, pdata).subscribe({
            next: (data: any) => {
              this.autoLoad = this.setting.autoLoad;

              callback({
                recordsTotal: data.totalRows,
                recordsFiltered: data.totalRows,
                data: data.rows,
              });
            },
            error: (err: any) => {
              this.autoLoad = this.setting.autoLoad;
              this.msg.error(err);
              console.error(err);
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            },
          });
        }
      } else {
        callback({
          recordsTotal: 0,
          recordsFiltered: 0,
          data: [],
        });
      }
    };
  }

  private _onAdd(e: any, dt: any, node: any) {
    if (this.setting.onAdd) {
      this.setting.onAdd(e, dt, node);
    }
  }

  private _getColumns() {
    var _columns: Array<any> = [];
    var _columnDefs: Array<any> = [];
    var _columnsHideOrShow: Array<any> = [];

    var cols = this.setting.columns || [];
    if (cols.length > 0) {
      var col: ColumnsModel;

      cols.forEach((el: any, index: Number) => {
        col = { ...{ visible: true, buttons: [], searchable: true }, ...el };

        //evaluar si tienes la columna tipo boton y inicializarla..
        if ((col.buttons || []).length > 0) {
          _columnDefs.push({
            targets: index,
            orderable: false,
            searchable: false,
          });
          _columns.push(this._createButtonsOfColumn(col));
        } else {
          _columnsHideOrShow.push(this._createHideOrShowColumn(col, index));

          //crear filtros si aplica
          if (this.setting.filtering) {
            col.title += this._createFilterOfColumn(col);

            if (col.filters && !col.render) {
              const options = col.filters;
              col = {
                ...col,
                render: (data: any) => {
                  return options[data];
                },
              };
            }
          }

          if (!col.data) {
            _columnDefs.push({
              targets: index,
              orderable: false,
              searchable: false,
            });
          }

          _columns.push(col);
        }
      });
    }

    return {
      columns: _columns,
      columnDefs: _columnDefs,
      columnsHideOrShow: _columnsHideOrShow,
    };
  }

  private _createFilterOfColumn(col: any) {
    if (col.filters == false) {
      return ' <div class="dt-col-filter open">&nbsp;</div>';
    }

    let componente = ' <div class="dt-col-filter open">';

    if (col.filters) {
      componente += "<select class='form-select-sm' >";

      if (col.filters['']) {
        componente +=
          "<option value='' selected>" + col.filters[''] + '</option>';
      }

      Object.keys(col.filters).forEach((key) => {
        if (key != '') {
          componente +=
            "<option value='" + key + "'>" + col.filters[key] + '</option>';
        }
      });

      componente += '</select>';
    } else {
      componente +=
        '<input class="form-control" ' +
        (col.type == 'datetime-local' ? 'step="1"' : '') +
        ' type="' +
        (col.type ? col.type : 'text') +
        '" placeholder="Buscar ' +
        col.title.toLowerCase() +
        '" name="search-' +
        col.data +
        '"/>';
    }

    componente += '</div>';

    return componente;
  }

  private _createButtonsOfColumn(col: ColumnsModel) {
    this.links = col.buttons || [];
    return {
      className: col.className,
      title: col.title,
      render: (data: any, type: any, row: any, meta: any) => {
        return this._renderButtonsOfColumn(this.links, meta.row);
      },
    };
  }

  private _renderButtonsOfColumn(buttons: ColumnButtonsModel[], row: number) {
    var btns: any = [];
    //const fn = ()=>{console.log("Impri")}
    buttons.forEach((el: any, index: any) => {
      btns.push(
        `<a class="btn ${
          el.className ? el.className : 'btn-outline-info'
        } btn-xs btn-rounded btn-tb" view-id="${index}"  ${
          el.title ? `data-toggle="tooltip" title="${el.title}"` : ''
        } ><span style="pointer-events: none;" class="fa-solid ${
          el.icon
        } fa-fw fa-sm"></span>${el.text || ''}</a>`
      );
    });
    return btns.join('&nbsp;');
  }

  private _createHideOrShowColumn(col: any, index: Number) {
    return {
      text:
        '<i class="fa ' +
        (col.visible ? 'fa-check' : 'fa-times') +
        '">&nbsp;</i>&nbsp;' +
        col.title,
      action: (e: any, dt: any, node: any) => {
        this._changeShowHideColumn(dt, node, index);
      },
    };
  }

  private _changeShowHideColumn(dt: any, node: any, index: any) {
    //console.log(dt)

    var that = node.find('i');
    if (that.hasClass('fa-times')) {
      that.removeClass('fa-times');
      that.addClass('fa-check');
    } else {
      that.removeClass('fa-check');
      that.addClass('fa-times');
    }

    var column = dt.column(index);
    column.visible(!column.visible());
  }

  getColumn(index: number) {
    return this.dt.column(index);
  }

  reload() {
    setTimeout(() => {
      if (!this.setting.autoLoad) this.autoLoad = true;
      this.dt.ajax.reload();
    }, 1);
  }

  /*get dataTable() {
    return this.dt;
  }*/
}
