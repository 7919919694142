<div class="image-wrapper">
  @if(image){<img [attr.src]="image" />} @else{
  <img src="../../../assets/images/users/no-user.jpeg" />
  }
</div>

<div style="text-align: center; width: 100%; display: block">
  @if(!image){
  <button class="btn btn-sm btn-outline-secondary" (click)="clickFileInput()">
    <span>Seleccionar imagen</span>
  </button>
  }@else {
  <button
    class="btn btn-sm btn-outline-info"
    style="display: inline"
    (click)="clickFileChange()"
  >
    <span>Cambiar imagen</span>
  </button>
  <button
    class="btn btn-sm btn-outline-danger"
    style="display: inline; margin-left: 3px"
    (click)="clickFileRemove()"
  >
    <span>Eliminar imagen</span>
  </button>
  }

  <input type="file" (change)="fileChange(input)" #input  [accept]="allowedExtensionsString" />
</div>
