<div class="container-fluid">
  <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <app-datatable [options]="optionModel"></app-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
