<div class="container-fluid">
  <app-spinner [loading]="loading"></app-spinner>
  <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
            
              <h4 class="card-title mb-4">Disponibles</h4>
              <div [ngStyle]="{ 'display': 'flex', 'justifyContent': 'stretch', 'marginTop': '50px', 'marginRight': '50px' }" >
                <div [ngStyle]="{'marginLeft': '50px', 'flex': '1'}">
                  <div cdkDropList id="disponiblesList" [cdkDropListData]="disponibles" class="example-list" (cdkDropListDropped)="drop($event)"  [cdkDropListConnectedTo]="['asignadosList']">
                    <div *ngFor="let task of disponibles" cdkDrag [cdkDragData]="task" class="example-box">
                      <ng-container *ngTemplateOutlet="TaskContent; context: {task: task}"></ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="dropdown float-end" dropdown>
                <a
                  href="javascript: void(0);"
                  class="dropdown-toggle arrow-none"
                  dropdownToggle
                >
                  <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                </a>
                <div style="min-width: 180px;" class="dropdown-menu dropdown-menu-end" *dropdownMenu>
                  @for (item of procesos; track $index) {
                    <a class="dropdown-item" href="javascript: void(0);" (click)="changeProcesoAsignado(item)">
                      <span>{{item.nombre}}</span>
                      <span *ngIf="getValue(item.id)?? 0 > 0" class="badge rounded-pill float-end bg-success">{{getValue(item.id)}}</span>
                    </a>
                  }
                </div>
              </div>
              <h4 class="card-title mb-4">Asignados a {{procesoAsignado.nombre}}</h4>
              <div [ngStyle]="{ 'display': 'flex', 'justifyContent': 'stretch', 'marginTop': '50px', 'marginRight': '50px' }" >
                <div [ngStyle]="{'marginLeft': '50px', 'flex': '1'}">
                  <div cdkDropList id="asignadosList" [cdkDropListData]="asignados" class="example-list" (cdkDropListDropped)="drop($event)" [cdkDropListConnectedTo]="['disponiblesList']">
                    <div *ngFor="let task of asignados" cdkDrag [cdkDragData]="task" class="example-box">
                      <ng-container *ngTemplateOutlet="TaskContent; context: {task: task}"></ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #TaskContent let-task='task'>
    <div class="card task-box">
        <div class="card-body">
            <h5>{{task.cliente}}</h5>
            <h6>{{task.numero}}</h6>
            <p class="text-muted mb-0">{{task.producto}}</p>
            @if(task.observacion!==null && task.observacion.length>0){<p class="text-muted mb-0"> {{task.observacion}}</p>}
            <div>
              <p class="fw-medium mb-0">Cantidad:</p>
              <h5 class="text-muted mb-0">{{task.cantidad}} {{task.nemonico}}</h5>
            </div>
            
        </div>
    </div>
</ng-template>
