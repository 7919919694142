<div class="container-fluid">
  <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <tabset [justified]="false" class="nav-tabs nav-tabs-custom">
            <tab id="general" heading="General" (selectTab)="changeTab($event)">
              <ng-template *ngIf="activeTab === 'general'" [ngTemplateOutlet]="TabGeneral"  ></ng-template>
            </tab>
           
            <tab id="factElectronica" heading="Facturación Electrónica" (selectTab)="changeTab($event)">
              <ng-template *ngIf="activeTab === 'factElectronica'" [ngTemplateOutlet]="TabFirmaElectronica"></ng-template>
            </tab>   
            <tab id="correo" heading="Correo" (selectTab)="changeTab($event)">
              <ng-template *ngIf="activeTab === 'correo'" [ngTemplateOutlet]="TabCorreo"></ng-template>
            </tab>         
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #TabGeneral>
  <app-company-general-edit></app-company-general-edit>
</ng-template>



<ng-template #TabFirmaElectronica>
  <app-company-factura-electronica></app-company-factura-electronica>
</ng-template>

<ng-template #TabCorreo>
  <app-company-correo-list></app-company-correo-list>
</ng-template>
