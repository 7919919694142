<app-spinner [loading]="loading"></app-spinner>
<h5 class="m-0">Tarea</h5>
<div style="margin-top: 23px">
  <tabset
    class="nav-tabs nav-justified nav-tabs-custom card-header-tabs custom-widget-nav ms-auto"
  >
    <tab heading="General" class="mt-2">
      <form [formGroup]="form" autocomplete="off">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-1">
              <label for="i-secuencia" class="form-label">Secuencia</label>
              <input
                [ngClass]="{ 'is-invalid': submitted && f.secuencia.errors }"
                class="form-control form-control-sm"
                autocomplete="off"
                readonly
                formControlName="secuencia"
                type="text"
                id="i-secuencia"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-1">
              <label for="i-producto" class="form-label">Producto</label>
              <input
                [ngClass]="{ 'is-invalid': submitted && f.producto.errors }"
                class="form-control form-control-sm"
                autocomplete="off"
                readonly
                formControlName="producto"
                type="text"
                id="i-producto"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-1">
              <label for="i-cantidad" class="form-label">cantidad</label>
              <input
                [ngClass]="{ 'is-invalid': submitted && f.cantidad.errors }"
                class="form-control form-control-sm"
                autocomplete="off"
                readonly
                formControlName="cantidad"
                type="text"
                id="i-cantidad"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-1">
              <label class="form-label">Responsables</label>
              <app-select-catalog
                size="sm"
                [multiple]="true"
                [ngClass]="{
                  'is-invalid': submitted && f.tipoResponsable.errors
                }"
                #cmbTipoResponsable
                (changeValue)="changeResponsable($event)"
              ></app-select-catalog>
            </div>
          </div>
          @if(aux===2 || aux===3){
          <div class="col-md-12">
            <div class="mb-1">
              <label class="form-label">Numero bandas *</label>
              <input
                [ngClass]="{ 'is-invalid': submitted && f.numBandas.errors }"
                class="form-control form-control-sm"
                autocomplete="off"
                formControlName="numBandas"
                type="text"
                id="i-num-bandas"
              />
            </div>
          </div>
          } @if(aux===3){
          <div class="col-md-12">
            <div class="mb-1">
              <label class="form-label">Numero bandas entregadas *</label>
              <input
                [ngClass]="{
                  'is-invalid': submitted && f.numBandasProducidas.errors
                }"
                class="form-control form-control-sm"
                autocomplete="off"
                formControlName="numBandasProducidas"
                type="text"
                id="i-num-bandas-prod"
              />
            </div>
          </div>
          }
        </div>
      </form>
      <hr />
      <button
        type="button"
        class="btn btn-primary btn-sm btn-info"
        (click)="guardar()"
      >
        <i class="bx bx-save font-size-16 align-middle me-2"></i>
        Guardar
      </button>
    </tab>
    <tab heading="Comentarios" class="mt-2">
      <app-task-edit-comments (changeLoading)="changeComentLoading($event)" [taskId]="taskId" />
    </tab>
  </tabset>
</div>
