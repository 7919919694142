<div class="container-fluid">
  <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
  <div class="row">
    <div class="col-12">
      <div class="card mb-1">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-1">
                <label class="form-label">Nombre *</label>
                <input
                  [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"
                  formControlName="nombre"
                  class="form-control"
                  type="text"
                />
                <div
                  *ngIf="submitted && f.nombre.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="f.nombre.errors.required"
                    >Campo de nombre del flujo es requerido</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          class="card-header bg-transparent border-bottom d-flex justify-content-between align-items-center"
        >
          <h4 class="card-title">Flujo de trabajo</h4>
          <div class="d-flex flex-wrap gap-1">
            <button
              type="button" (click)="addTransition()"
              class="btn btn-sm btn-light position-relative p-0 avatar-xs rounded-circle"
            >
              <span class="avatar-title bg-transparent text-reset">
                <i class="bx bx-vertical-center font-size-16 align-middle"></i>
              </span>
            </button>
            <button
              type="button" (click)="addBlock()"
              class="btn btn-sm btn-light position-relative p-0 avatar-xs rounded-circle"
            >
              <span class="avatar-title bg-transparent text-reset">
                <i class="bx bx-plus font-size-16 align-middle"></i>
              </span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <app-workflow-diagram #workflow (nodedblclick)="nodedblclick($event)" ></app-workflow-diagram>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
