import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthUtilService } from 'src/app/account/services/auth-util.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {

  const auth = inject(AuthUtilService);

  return next(req).pipe(
    catchError((err) => {

      if (err.status === 401) {
        auth.logout();
      }

      const error = err?.error?.message || err.message || err.statusText;
      //console.log('err intereptor',error);
      return throwError(() => error);
    })
  );
};
