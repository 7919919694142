<table
  #tbpreparacion
  datatable
  [dtOptions]="dtOptions"
  class="row-border hover table table-bordered dt-responsive nowrap dt-select-grid"
  cellspacing="0"
  width="100%"
></table>


