<div class="container-fluid">
  <app-spinner [loading]="loading"></app-spinner>
  <div class="row">
    <div class="col-12">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0 font-size-18">{{ title }}</h4>
        <div class="page-title-right">
          <div class="d-flex flex-wrap gap-1">
            <app-select-catalog
              style="min-width: 200px"
              (changeValue)="changeProceso($event)"
              size="sm"
              #cmbTipoProceso
            ></app-select-catalog>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <div class="dropdown float-end" dropdown>
                <a href="javascript: void(0);" class="dropdown-toggle arrow-none" dropdownToggle>
                  <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
                  <a class="dropdown-item" href="javascript: void(0);" (click)="generarReporteProceso('pendientesList')">Reporte</a>
                </div>
              </div> <!-- end dropdown -->
              <h4 class="card-title mb-4">Mis Pendientes</h4>
              <div
                [ngStyle]="{
                  display: 'flex',
                  justifyContent: 'stretch',
                  marginTop: '5px',
                  marginRight: '5px'
                }"
              >
                <div [ngStyle]="{ marginLeft: '5px', flex: '1' }">
                  <div
                    cdkDropList
                    id="pendientesList"
                    [cdkDropListData]="pendientesList"
                    class="example-list"
                    (cdkDropListDropped)="drop($event)"
                    [cdkDropListConnectedTo]="['enProcesoList']"
                  >
                    <div
                      *ngFor="let task of pendientesList"
                      cdkDrag
                      [cdkDragData]="task"
                      class="example-box"
                    >
                      <ng-container
                        *ngTemplateOutlet="
                          TaskContent;
                          context: { task: task, id: 'pendientesList' }
                        "
                      ></ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <div class="dropdown float-end" dropdown>
                <a href="javascript: void(0);" class="dropdown-toggle arrow-none" dropdownToggle>
                  <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
                  <a class="dropdown-item" href="javascript: void(0);" (click)="generarReporteProceso('enProcesoList')">Reporte</a>
                </div>
              </div> <!-- end dropdown -->
              <h4 class="card-title mb-4">En proceso</h4>
              <div
                [ngStyle]="{
                  display: 'flex',
                  justifyContent: 'stretch',
                  marginTop: '5px',
                  marginRight: '5px'
                }"
              >
                <div [ngStyle]="{ marginLeft: '5px', flex: '1' }">
                  <div
                    cdkDropList
                    id="enProcesoList"
                    [cdkDropListData]="enProcesoList"
                    class="example-list"
                    (cdkDropListDropped)="drop($event)"
                    [cdkDropListConnectedTo]="[
                      'finalizadoList',
                      'pendientesList'
                    ]"
                  >
                    <div
                      *ngFor="let task of enProcesoList"
                      cdkDrag
                      [cdkDragData]="task"
                      class="example-box"
                    >
                      <ng-container
                        *ngTemplateOutlet="
                          TaskContent;
                          context: { task: task, id: 'enProcesoList' }
                        "
                      ></ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <div class="dropdown float-end" dropdown>
                <a href="javascript: void(0);" class="dropdown-toggle arrow-none" dropdownToggle>
                  <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
                  <a class="dropdown-item" href="javascript: void(0);" (click)="generarReporteProceso('finalizadoList')">Reporte</a>
                </div>
              </div> <!-- end dropdown -->
              <h4 class="card-title mb-4">Finalizados</h4>
              <div
                [ngStyle]="{
                  display: 'flex',
                  justifyContent: 'stretch',
                  marginTop: '5px',
                  marginRight: '5px'
                }"
              >
                <div [ngStyle]="{ marginLeft: '5px', flex: '1' }">
                  <div
                    cdkDropList
                    id="finalizadoList"
                    [cdkDropListData]="finalizadoList"
                    class="example-list"
                    (cdkDropListDropped)="drop($event)"
                    [cdkDropListConnectedTo]="['enProcesoList']"
                  >
                    <div
                      *ngFor="let task of finalizadoList"
                      cdkDrag
                      [cdkDragData]="task"
                      class="example-box"
                    >
                      <ng-container
                        *ngTemplateOutlet="
                          TaskContent;
                          context: { task: task, id: 'finalizadoList' }
                        "
                      ></ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #TaskContent let-task="task" let-queue="id">
  <div class="card task-box">
    <div class="card-body bc-{{ queue }} bc-smf {{task?.semaforo}}">
      <div class="dropdown float-end" dropdown>
        <a
          href="javascript:void(0);"
          class="dropdown-toggle arrow-none"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          dropdownToggle
        >
          <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <a
            class="dropdown-item edittask-details"
            href="javascript:void(0);"
            id="taskedit"
            (click)="editTask(task, queue)"
            data-id="#uptask-1">Editar</a>
            <a class="dropdown-item" href="javascript: void(0);" (click)="generarReporteProcesoTarera(queue,task.id)">Reporte</a>
          @if(queue==="finalizadoList" && activaArchivar){<a
            class="dropdown-item edittask-details"
            href="javascript:void(0);"
            id="taskArchivar"
            (click)="archivarTask(task)"
            data-id="#uptask-2"
            >Archivar</a
          >}
        </div>
      </div>
      <h6>
        {{ task.numeroPedido }} <i class="bx bx-right-arrow-alt"></i>
        {{ task.numero }}
      </h6>
      <p class="text-muted mb-0">
        <b>[{{ task.codigo }}]</b> - {{ task.producto }}
      </p>
      <p class="text-muted mb-0">FC: {{ task.fechaAprobacion }}</p>
      <p class="mb-0" style="font-size: 11px">
        @if(task.fechaInicia!=null){<span class="text-muted mb-0">
          INI: {{ task.fechaInicia }}</span
        >} @if(task.fechaFinaliza!=null){<span class="text-muted mb-0">
          - FIN: {{ task.fechaFinaliza }}</span
        >}
      </p>
      
      <div>
        <div class="float-start">
          <p class="fw-medium mb-0">Cantidad:</p>
          <h5 class="text-muted mb-0">{{ task.cantidad }} {{ task.unidad }}</h5>
          <p class="mb-0" style="font-size: 10px;">
            {{task.fecha | async}} 
         </p> 
        </div>
        <div class="avatar-group float-end mt-2 text-end">
          @if(task.responsables!=null){ @for (item of task.responsables; track
          $index) {
          <div class="avatar-group-item">
            <a
              href="javascript: void(0);"
              title="{{ item.nombre }}"
              class="d-inline-block"
            >
              <img
                src="{{ item.url }}"
                class="rounded-circle avatar-xs"
                alt=""
              />
            </a>
          </div>
          }}
        </div>
        
      </div>
    </div>
  </div>
</ng-template>
