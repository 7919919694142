import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { DataTablesEmitter, OptionsModel } from './datatable.model';
import { DatatableExtensionService } from '@shared/extension/datatable-extension.service';

@Component({
  selector: 'app-datatable-formula',
  standalone: true,
  imports: [DataTablesModule],
  templateUrl: './datatable-formula.component.html',
  styleUrl: './datatable-formula.component.scss',
})
export class DatatablePreparationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private renderer = inject(Renderer2);
  private dtService = inject(DatatableExtensionService);
  private elRef = inject(ElementRef);

  private defaultModel: any[] = [];

  dtOptions: any = {};

  @Input() options: OptionsModel;
  @Input() data: any[] = [];

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  private dt: any;
  private tableEventListener: () => void;

  emitter: EventEmitter<DataTablesEmitter> = new EventEmitter();

  ngOnDestroy(): void {
    if (this.tableEventListener) {
      this.tableEventListener();
    }
  }

  private subscribeToClickEvent(): void {
    if (!this.tableEventListener) {
      this.tableEventListener = this.renderer.listen(
        this.elRef.nativeElement,
        'click',
        (event) => {
          if (event.target.hasAttribute('view-action')) {
            var row = this.dt.row($(event.target).closest('tr'));
            if (row) {
              //console.log("click en tabla");

              const rowIndex = row.index();
              const action = event.target.getAttribute('view-action');
              const evt = event.target.getAttribute('data-event');

              // console.log(action, event.target);
              this.emitter.emit({
                index: rowIndex,
                action,
                data: row.data(),
                callbackName: evt,
              });

              //const b = that.links[event.target.getAttribute("view-id")];
              //if(b) b.onclick(row.data());
            }
          }
        }
      );
    }
  }

  ngAfterViewInit(): void {
    const that = this;

    this.datatableElement.dtInstance.then((dtInstance: any) => {
      that.dt = dtInstance;
      $(dtInstance.tables().header()).addClass('table-light');

      $(dtInstance.table(0).body())
        .on('blur', 'input:not(.decimals, .numbers, .times)', function () {
          const $row = $(this).closest('tr');
          const columnIndex: number = $(this).closest('td').index();
          const dataFieldName = that.options.columns![columnIndex].data;

          if (dataFieldName) {
            const newValue = $(this).val();
            const row = dtInstance.row($row);
            const rowData: any = row.data();
            rowData[dataFieldName] = newValue;
          }
        })
        .on('blur', 'input.decimals', function () {
          const $row = $(this).closest('tr');
          const columnIndex: number = $(this).closest('td').index();
          const dataFieldName = that.options.columns![columnIndex].data;

          if (dataFieldName) {
            const newValue = $(this).val();
            const row = dtInstance.row($row);
            const rowData: any = row.data();
            rowData[dataFieldName] = Number(newValue || 0);
          }
        })
        .on('blur', 'input.times', function () {
          const $row = $(this).closest('tr');
          const columnIndex: number = $(this).closest('td').index();
          const dataFieldName = that.options.columns![columnIndex].data;

          if (dataFieldName) {
            const newValue = $(this).val();
            const row = dtInstance.row($row);
            const rowData: any = row.data();
            rowData[dataFieldName] = Number(newValue||0);
          }
        })
        .on('keypress paste', 'input.decimals', function (evt) {
          that._onlyDecimal(evt);
        })
        .on('keypress paste', 'input.times', function (evt) {
          that._onlyTime(evt);
        });
    });
  }

  ngOnInit(): void {
    this._initDatatable();
    this.subscribeToClickEvent();
  }

  private _initDatatable() {
    //console.log('_initDatatable',this.defaultModel)

    this.dtOptions = {
      data: this.data,
      columns: this.options.columns || [],
      responsive: false,
      paging: false,
      search: false,
      info: false,
      ordering: false,
      searching: false,
    };
  }

  getModel(): any[] {
    return this.dt.rows().data().toArray();
  }

  addNewRow(data: any): void {
    if (this.dt) {
      this.dt.row.add(data).draw(false);
    }
  }

  insertBeforeNewRow(index: number, data: any): void {
    if (this.dt) {
      this.dtService.addBeforeRowByPos(this.dt, data, index);
    }
  }

  insertAfterNewRow(index: number, data: any): void {
    if (this.dt) {
      this.dtService.addAfterRowByPos(this.dt, data, index);
    }
  }

  deleteRow(index: number): void {
    if (this.dt) {
      console.log('delte index', index);
      this.dt.row(index).remove().draw();
    }
  }

  editRow(index: number, data: any) {
    if (this.dt) {
      const updatedRow:any = this.dt.row(index).data();
      updatedRow.id = data.id;
      updatedRow.producto=data.producto;
      updatedRow.codigo =data.codigo;      
      this.dt.row(index).data(updatedRow);
    }
  }

  private _onlyDecimal(evt: any) {
    var theEvent = evt || window.event;

    if (theEvent.type === 'paste') {
      key = (evt.originalEvent || evt).clipboardData.getData('text/plain');
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }

    let originalValue: string = evt.target['value'];
    let separatorExists = originalValue.includes('.');
    if (separatorExists && key === '.') {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }

    var regex = /^\d*\.?\d*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  private _onlyTime(evt:any){
    var theEvent = evt || window.event;

    if (theEvent.type === 'paste') {   
      key = (evt.originalEvent || evt).clipboardData.getData('text/plain');

    } else {
    // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
   
    var regex = /^\d*\/?\d*$/;
    if( !regex.test(key)) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
    
  }
}
