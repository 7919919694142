<div class="container-fluid">
    <app-page-buttons title="{{ title }}" [items]="botones"></app-page-buttons>
    <!--<div class="row">
          <div class="col-12 text-end mb-3">
      
            <div class="button-items">        
    
              <button type="button" class="btn btn-warning btn-sm" (click)="cancelar()">
                  <i class="bx bx-block font-size-16 align-middle me-2"></i>cancelar
              </button>
      
              <button type="button" class="btn btn-info btn-sm" (click)="guardar()">
                <i class="bx bx-save font-size-16 align-middle me-2"></i>guardar
              </button>
      
            </div>
      
          </div>
      </div>-->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <app-spinner [loading]="loading"></app-spinner>
            <form [formGroup]="userForm" autocomplete="off">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="card">
                        <div class="card-header bg-transparent border-bottom">
                          Detalles
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="mb-3">
                                <label for="f-nombre-input">Nombre *</label>
                                <input
                                  [ngClass]="{
                                    'is-invalid': submitted && f.nombre.errors
                                  }"
                                  type="text"
                                  formControlName="nombre"
                                  class="form-control"
                                  id="f-nombre-input"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-3">
                                <label for="f-mail-input">Correo *</label>
                                <input
                                  [ngClass]="{
                                    'is-invalid': submitted && f.correo.errors
                                  }"
                                  type="text"
                                  formControlName="correo"
                                  class="form-control"
                                  id="f-mail-input"
                                />
                                <div
                                  *ngIf="submitted && f.correo.errors"
                                  class="invalid-feedback"
                                >
                                  <span *ngIf="f.correo.errors.required"
                                    >Correo es requerido</span
                                  >
                                  <span *ngIf="f.correo.errors.email"
                                    >Este valor debe ser un correo electrónico
                                    válido</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="mb-3">
                                <label for="f-usuario-input">Usuario *</label>
                                <input
                                  [ngClass]="{
                                    'is-invalid': submitted && f.usuario.errors
                                  }"
                                  type="text"
                                  formControlName="usuario"
                                  class="form-control"
                                  id="f-usuario-input"
                                />
                                <div
                                  *ngIf="submitted && f.usuario.errors"
                                  class="invalid-feedback"
                                >
                                  <span *ngIf="f.usuario.errors.required"
                                    >Usuario es requerido</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-3">
                                <label for="f-password-input">Contraseña *</label>
                                <input
                                  [ngClass]="{
                                    'is-invalid': submitted && f.password.errors
                                  }"
                                  type="password"
                                  formControlName="password"
                                  class="form-control"
                                  id="f-password-input"
                                />
                                <div
                                  *ngIf="submitted && f.password.errors"
                                  class="invalid-feedback"
                                >
                                  <span *ngIf="f.password.errors.required"
                                    >Password es requerido</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card">
                        <div class="card-header bg-transparent border-bottom">
                          Imagen
                        </div>
                        <div class="card-body">
                          <app-imagen-upload
                            [image]="defaultImageAvatar"
                            (uploadedImage)="uploadedAvatar($event)"
                            [allowedExtensions]="['png', 'jpg', 'jpeg']"
                          ></app-imagen-upload>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <div class="card">
                    <div class="card-header bg-transparent border-bottom">
                      Roles
                    </div>
                    <div class="card-body">
                      <app-select-grid
                        #cmbRoles
                        [defaultItemText]="defaultItemRolText"
                        (changeSelectedItem)="changeRollSelection($event)"
                      ></app-select-grid>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
