import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormulaPreparationComponent } from '@components/formula-preparation/formula-preparation.component';
import { FormulaEventModel, FormulaItemAplicacionModel, FormulaItemModel, FormulaItemPrensadoModel, FormulaItemPreparacionModel } from '@components/formula-preparation/formula.model';
import { InputMaskComponent, ModalColorpickerComponent, SelectCatalogComponent } from '@components/index';
import { AlertService } from '@services/alert.service';
import { PageButtonsComponent, SpinnerComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { UUID } from 'angular2-uuid';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-formula-edit',
  standalone: true,
  imports: [
    CommonModule,
    PageButtonsComponent,
    SpinnerComponent,
    ReactiveFormsModule,
    FormsModule,
    SelectCatalogComponent,
    InputMaskComponent,
    FormulaPreparationComponent
  ],
  templateUrl: './formula-edit.component.html',
  styleUrl: './formula-edit.component.scss',
})
export class FormulaEditComponent implements OnInit,AfterViewInit {
  
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private formBuilder = inject(FormBuilder);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  title: string = 'Nueva Formula';
  botones: PageButton[];
  loading: boolean;
  submitted: boolean;
  editMode: boolean;
  formulaId: string;
  codigoFormula: string = '';
  tipoCalculo: string = '-';
  formulaItemModels: Array<FormulaItemModel> = [];

  private _colbox: any;
  private color: any = {};

  public form: FormGroup;

  @ViewChildren('cmp') components: QueryList<FormulaPreparationComponent>;
  @ViewChild('colboxpickerval') colboxpickerval: any;
  @ViewChild('cmbTipoFormula') cmbTipoFormula: any;
  @ViewChild('cmbTipoMateriaPrima') cmbTipoMateriaPrima: any;
  @ViewChild('cmbTipoProceso') cmbTipoProceso: any;
  @ViewChild('cmbTipoCalculo') cmbTipoCalculo: any;
  @ViewChild('inputCodeFormula') inputCodeFormula: any;

  constructor(private modalColorPicker: BsModalService){}

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      if (param.id) {
        this.formulaId = param.id;
        this.title = 'Editar';
        this.editMode = true;
        this._obtener(this.formulaId);
      } else {
        this._obtener();
      }
    });

    this._initBotones();

    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      codigo: ['', [Validators.required]],
      color: [''],
      tipoMateriaPrima: ['', [Validators.required]],
      tipoCalculo: ['', [Validators.required]],
      tipoProceso: ['', [Validators.required]],
      tipoFormula: ['', [Validators.required]],
    });
  }

  ngAfterViewInit(): void {
    this._colbox = this.colboxpickerval.nativeElement;
    this._colbox.style.cssText = this.color
      ? this.color.rgb
      : '--bgcolorval:rgba(0,0,0,0)';
  }

  private _initBotones() {
    this.botones = [
      {
        label: 'cancelar',
        icon: 'bx bx-block',
        cssClass: 'btn-warning',
        actionType:()=> this._cancelar()
      },
      {
        label: 'agregar paso',
        icon: 'bx bx-plus',
        cssClass: 'btn-success',
        actionType:()=> this.agregarPaso()
      },
      {
        label: 'duplicar',
        icon: 'bx bx-block',
        cssClass: 'btn-success',
        actionType:()=> this.duplicar()
      },
      {
        label: 'guardar',
        icon: 'bx bx-save',
        cssClass: 'btn-info',
        actionType:()=> this._guardar()
      }
    ];
  }

  private _cancelar(){
    this.router.navigate(['administration/formula/list']);
  }

  private _guardar(){

    const m = this._getModel();
    console.log('guaradar',m);

  }

  private agregarPaso(){
    //console.log("agregar");
    this.formulaItemModels.push({
      id: UUID.UUID(),
      nombrePaso:"",
      aplicaPrensado: false,
      preparacion: [],
      aplicacion: [],
      prensado: [],
    });
  }

  private duplicar(){

  }

  private _obtener(id: string | null = null, modelClone: any = null) {
    this.loading=true;
    //console.log(id);
    this.http.get(id ? `formula/${id}` : 'formula').subscribe({
      next: (data: any) => {
        if (data) {
          
          if (!id && modelClone) {
            data['model'] = modelClone;
            this._loadFormula(data, true);
          } else {
            this._loadFormula(data, false);
          }
        }

        this.loading = false;
      },
      error: (error) => (this.loading = false),
    });
  }

  private _loadFormula(data: any, isClone: boolean) {
  
    const model = data.model;
    if (model) {
      this.form.patchValue({
        nombre: model.nombre,
        codigo: model.codigo,
        color: model.colorHEX,
        tipoMateriaPrima: model.tipoMateriaPrima,
        tipoCalculo: model.tipoCalculo,
        tipoProceso: model.tipoProceso,
        tipoFormula: model.tipoFormula,
      });

      this.color = {
        hex: model.colorHEX,
        rgb: model.colorRGB,
      };

      this._setColorPicher();
      this._loadFormulaPasos(model.pasos, isClone);
    }

    //setear catalogos...
    if (data.catalogos) {
      if (data.catalogos.TP_PROCESO_FORMULA) {
        var ctgs = data.catalogos.TP_PROCESO_FORMULA;
        if (data.model && data.model.tipoProceso) {
          this.cmbTipoProceso.setSelectedItem(
            ctgs.find((it: any) => it.id === data.model.tipoProceso)
          );
        }
        this.cmbTipoProceso.setItems(ctgs);
      }

      if (data.catalogos.TP_FORMULA_CALCULO) {
        var ctgs = data.catalogos.TP_FORMULA_CALCULO;
        if (data.model && data.model.tipoCalculo && data.model.tipoProceso)
          this.cmbTipoCalculo.setSelectedItem(
            ctgs.find((it: any) => it.id === data.model.tipoCalculo)
          );
        this.cmbTipoCalculo.setItems(ctgs);
      }

      if (data.catalogos.TP_TIPO_FORMULA) {
        var ctgs = data.catalogos.TP_TIPO_FORMULA;
        if (data.model && data.model.tipoFormula && data.model.tipoProceso)
          this.cmbTipoFormula.setSelectedItem(
            ctgs.find((it: any) => it.id === data.model.tipoFormula)
          );
        this.cmbTipoFormula.setItems(ctgs);
      }

      if (data.catalogos.TP_MATERIA_PRIMA) {
        var ctgs = data.catalogos.TP_MATERIA_PRIMA;
        if (data.model && data.model.tipoMateriaPrima && data.model.tipoProceso)
          this.cmbTipoMateriaPrima.setSelectedItem(
            ctgs.find((it: any) => it.id === data.model.tipoMateriaPrima)
          );
        this.cmbTipoMateriaPrima.setItems(ctgs);
      }

      if (data.model && data.model.tipoProceso) {
        //console.log("hola",data.model.tipoProceso)
        this._loadItemsJeraquicos(data.model.tipoProceso, false);
        this._createMaskCodigo();
      }
    }
  }

  private _loadItemsJeraquicos(id: any, reset: boolean = true) {
    this.cmbTipoCalculo.setItemsJerarquia(id, reset);
    this.cmbTipoFormula.setItemsJerarquia(id, reset);
    this.cmbTipoMateriaPrima.setItemsJerarquia(id, reset);
  }

  private _createMaskCodigo() {
    var tf = this.cmbTipoFormula.getCode();
    var mp = this.cmbTipoMateriaPrima.getCode();
    var p = this.cmbTipoProceso.getCode();

    var reg = `${p}${this.evaluaMaskCodigo(tf)}${this.evaluaMaskCodigo(mp)}`;
    this.codigoFormula = reg.length > 0 ? `${reg}-` : '';
  }

  private evaluaMaskCodigo(data: string): string {
    return data.length > 0 ? `-${data}` : '';
  }

  private _setColorPicher() {
    if (this.color && this.color.rgb && this._colbox) {
      this._colbox.style.cssText = `--bgcolorval:${this.color.rgb}`;
    }
  }

  private _loadFormulaPasos(model: any, isClone: boolean) {
   
    if (model) {
      var items: Array<FormulaItemModel> = [];
      model.forEach((pasos: any) => {
        var r: FormulaItemModel = {
          //idsys:pasos.id,
          id: UUID.UUID(),
          nombrePaso:pasos.nombrePaso,
          aplicaPrensado: pasos.aplicaPrensado,
          preparacion: this._loadFormulaPreparacion(
            pasos.preparacion || [],
            isClone
          ),
          aplicacion: this._loadFormulaAplicacion(
            pasos.aplicacion || [],
            isClone
          ),
          prensado: this._loadFormulaPrensado(pasos.prensado || [], isClone),
        };

        if (!isClone) {
          r.idsys = pasos.id;
        }

        items.push(r);

        //console.log('pasos',model);
      });
      
      //console.log('_loadFormulaPasos',items);
      this.formulaItemModels = items || [];
    }
  }

  private _loadFormulaPreparacion(
    model: any,
    isClone: boolean
  ): Array<FormulaItemPreparacionModel> {
    var items: Array<FormulaItemPreparacionModel> = [];
    model.forEach((itm: any) => {
      var r: FormulaItemPreparacionModel = {
        //idsys: itm.id,
        id: itm.producto,
        producto: itm.productoNombre,
        cantidad: itm.cantidad,
        tiempo:itm.tiempo,
        comentario:itm.comentario
      };

      if (!isClone) r.idsys = itm.id;

      items.push(r);
    });
    //console.log(items);
    return items;
  }

  private _loadFormulaAplicacion(
    model: any,
    isClone: boolean
  ): Array<FormulaItemAplicacionModel> {
    var items: Array<FormulaItemAplicacionModel> = [];
    model.forEach((itm: any) => {
      var r: FormulaItemAplicacionModel = {
        //idsys: itm.id,
        id: UUID.UUID(),
        descripcion: itm.descripcion,
      };

      if (!isClone) r.idsys = itm.id;

      items.push(r);
    });
    return items;
  }

  private _openModalColorImagenPicker() {

    const modalRef = this.modalColorPicker.show(ModalColorpickerComponent, {
      class: 'modal-dialog-centered modal-lg',
      ignoreBackdropClick: true,
      keyboard: false,
    });

    modalRef.content!.outputColor.subscribe((color:any)=>{
      console.log(color);
      if (color) {
        this.color = {
          hex: color.hexval,
          rgb: color.rgbaval,
        };

        this.form.patchValue({
          color: this.color.hex,
        });

        this._setColorPicher();
      }
    });
    //modalRef.content.emitter.subscribe(({ index, data, action }: any) => {

    /*const modalRef = this.modalService.open(PickerColorComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.emitter.subscribe((color: any) => {
      //console.log(color);
      if (color) {
        this.color = {
          hex: color.hexval,
          rgb: color.rgbaval,
        };

        this.form.patchValue({
          color: this.color.hex,
        });

        this._setColorPicher();
      }
    });*/
  }


  private _loadFormulaPrensado(
    model: any,
    isClone: boolean
  ): Array<FormulaItemPrensadoModel> {
    var items: Array<FormulaItemPrensadoModel> = [];
    model.forEach((itm: any) => {
      var r: FormulaItemPrensadoModel = {
        //idsys: itm.id,
        id: UUID.UUID(),
        placa: itm.placa,
        presion: itm.presion,
        temperatura: itm.temperatura,
        tiempo: itm.tiempo,
      };
      if (!isClone) r.idsys = itm.id;

      items.push(r);
    });
    return items;
  }

  get f() { return this.form.controls; }

  changeTipoProceso(event: any) {
    this.form.patchValue({
      tipoProceso: event.value,
    });

    this._loadItemsJeraquicos(event.value);
    this._createMaskCodigo();
  }

  changeTipoCaculo(event: any) {
    this.tipoCalculo = event.label || '-';
    this.form.patchValue({
      tipoCalculo: event.value,
    });
  }

  changeTipoFormula(event: any) {
    //console.log('changeTipoFormula',event);

    this.form.patchValue({
      tipoFormula: event.value,
    });
    this._createMaskCodigo();
  }

  changeTipoMateriaPrima(event: any) {
    this.form.patchValue({
      tipoMateriaPrima: event.value,
    });
    this._createMaskCodigo();
  }

  openSelectColor() {
    if (this.form.value.color) {
      //confirmacion...
      this.msg.confirm('¿Desea usted remplazar el color actual?')
        .then((result) => {
          if (result.value) {
            this._openModalColorImagenPicker();
          }
        });
    } else {
      this._openModalColorImagenPicker();
    }
  }

  private _getModel(isClone: boolean = false) {
    const _f = this.form.value;
    var model: any = {};
    model.nombre = _f.nombre;
    model.codigo = _f.codigo.toUpperCase();
    model.codigoCompleto = `${this.codigoFormula}${model.codigo}`;
    model.tipoMateriaPrima = _f.tipoMateriaPrima;
    model.tipoCalculo = _f.tipoCalculo;
    model.tipoFormula = _f.tipoFormula;
    model.tipoProceso = _f.tipoProceso;
    model.colorRGB = this.color.rgb;
    model.colorHEX = this.color.hex;

    model.pasos = [];

    if (this.components.length > 0) {
      this.components.forEach((it, index) => {
        model.pasos.push(this.components.get(index)?.getModel(isClone));
      });
    }

    return model;
  }

  onRemovePasoFormula(index: number) {
    
    const reg = this.formulaItemModels[index];
    if(reg.idsys){
      //eliminar item en base de datos...

    }else{
      this.formulaItemModels = this.formulaItemModels.filter(
        (it, idx) => idx != index
      );
    }

    /*if (event.type === 'add') {
      const modalRef = this.modalService.open(SearchComponent, {
        size: 'lg',
        centered: true,
      });
      modalRef.componentInstance.current = event.current;
      modalRef.componentInstance.url = "producto/materiaPrima/listar";

      modalRef.componentInstance.emitter.subscribe((data: any) => {
        this.components.get(event.index)?.addProduct(data);
      });
    } else if (event.type === 'delete') {

      var model = this.formulaItemModels.find((it,index)=>index===event.index);
            
      if(model?.idsys){
        var that = this;
          this._deleteDetalle(`formula/detalle/${model?.idsys}`,function(){
            that.loading= false;
            that.formulaItemModels = that.formulaItemModels.filter(
              (it, index) => index != event.index
            );
          });
      }else{
        this.formulaItemModels = this.formulaItemModels.filter(
          (it, index) => index != event.index
        );
      }      
    }*/
  }

  private _deleteDetalle(url:string,callback:any){
    this.loading=true;
    this.http.delete(url).subscribe({
      next:(data: any) => {
      if(data.exito){
        callback();
      }
    },
    error: (error) => (this.loading = false),
  });
  }

}
