import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { DatatableComponent } from '@components/index';
import moment from 'moment';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { PageButtonsComponent } from '@shared/ui';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-person-list',
  standalone: true,
  imports: [DatatableComponent, PageButtonsComponent],
  templateUrl: './person-list.component.html',
  styleUrl: './person-list.component.scss',
})
export class PersonListComponent implements OnInit {
  private router = inject(Router);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  botones: PageButton[];
  title: string = 'Listar Personas';
  optionModel = {};

  @ViewChild(DatatableComponent) dt: DatatableComponent;

  ngOnInit(): void {
    this._initBotones();
    this._initDatatable();
  }

  private _initBotones() {
    this.botones = [
      {
        label: 'agregar',
        icon: 'bx bx-plus',
        cssClass: 'btn-success',
        actionType: () => this._add(),
      },
    ];
  }

  private _initDatatable() {
    var columns: any = [
      {
        className: 'col--opt-3 no-sort',
        title: '...',
        buttons: [
          {
            icon: 'fas fa-pencil-alt',
            title: 'editar',
            onclick: (row: any) => {
              this._edit(row.id);
            },
          },
          {
            className: 'btn-outline-danger',
            icon: 'far fa-trash-alt',
            title: 'eliminar',
            onclick: (row: any) => {
              this._delete(row);
            },
          },
        ],
      },
      {
        title: 'Identificacion',
        data: 'identificacion',
      },
      {
        title: 'Razon Social',
        data: 'razon_social',
      },
      {
        title: 'Direccion',
        data: 'direccion',
      },
      {
        title: 'Telefono',
        data: 'telefono',
      },
      {
        title: 'Movil',
        data: 'movil',
      },
      {
        title: 'Fecha Creacion',
        data: 'fecha_creacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
      {
        title: 'Fecha Modificacion',
        data: 'fecha_modificacion',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
    ];

    this.optionModel = {
      filtering: false,
      searching: true,
      scrollX: true,
      serverSide: true,
      columns: columns,
      url: 'persona/listar',
      //onAdd:()=>this._registrar()
    };
  }

  private _edit(id: string) {
    this.router.navigate([`person/edit/${id}`]);
  }

  private _add() {
    this.router.navigate(['person/add']);
  }

  private _delete(data:any) {
    const {razon_social:nombre} = data;
    this.msg
      .confirm(
        `<p>¿Estás seguro de que deseas eliminar la persona <strong>${nombre}</strong>?</p><p>Esta acción es irreversible.</p>`
      )
      .then(({ value }) => {
        if (value) {
          this.http.delete(`persona/${data.id}`).subscribe({
            next: (data: any) => {
              if (data && data.exito) {
                this.msg.success(`Persona [${nombre}] eliminado con éxito`);
                this.dt.reload();
              }
            },
            error: (err) => {
              this.msg.error(err);
            }
          });
        }
      });

    
  }
}
