import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ColumnsEditorModel,
  DatatableEditorModel,
} from '@components/datatable-editor/datatable-editor.model';
import {
  DatatableComponent,
  DatatableEditorComponent,
  DatepickerComponent,
  ModalSearchComponent,
  SelectCatalogComponent,
} from '@components/index';
import { AlertService } from '@services/alert.service';
import { PageButtonsComponent, SpinnerComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/account/services/auth.service';
import { ItemOrderDt, ItemOrderModel } from './order-edit.model';

@Component({
  selector: 'app-order-edit',
  standalone: true,
  imports: [
    CommonModule,
    DatatableComponent,
    PageButtonsComponent,
    SpinnerComponent,
    ReactiveFormsModule,
    FormsModule,
    DatepickerComponent,
    SelectCatalogComponent,
    DatatableEditorComponent,
  ],
  templateUrl: './order-edit.component.html',
  styleUrl: './order-edit.component.scss',
})
export class OrderEditComponent {
  private formBuilder = inject(FormBuilder);
  private auth = inject(AuthService);
  private route = inject(ActivatedRoute);
  private http = inject(HttpClient);
  private router = inject(Router);
  private msg = inject(AlertService);

  botones: PageButton[];
  title: string = 'Agregar';
  loading: boolean;
  submitted: boolean;
  bloquear: boolean = false;
  minDate: Date | undefined;
  optionModel: DatatableEditorModel;
  private produccionId: string | null = null;
  private catalogoTipoUnidad = [];
  private catalogoTipoProceso= [];

  public form: FormGroup;

  @ViewChild('cmbTipoResponsable') cmbTipoResponsable: any;
  @ViewChild('dtproduccion') dtproduccion: any;
  @ViewChild('fechaCreacion') fechaCreacion: any;
  @ViewChild('fechaFinalizacion') fechaFinalizacion: any;

  private columnsPedido = [
    {
      title: 'N.Pedido',
      data: 'numero',
    },
    {
      title: 'Identificacion',
      data: 'identificacion',
    },
    {
      title: 'Razon Social',
      data: 'razonSocial',
    },
    {
      title: 'Observacion',
      data: 'observacion',
    },
    {
      title: 'Producto',
      data: 'producto',
    },
    {
      title: 'Cantidad',
      data: 'cantidad',
    },
    {
      title: 'Unidad',
      data: 'tipoUnidad',
    },
    {
      title: 'F. Creacion',
      data: 'fechaEmision',
      render: (data: any) => {
        var stillUtc = moment.utc(data).toDate();
        var local = moment(stillUtc).local().format('DD/MM/YYYY');
        return local;
      },
    },
    {
      title: 'F. Entrega',
      data: 'fechaEntrega',
      render: (data: any) => {
        var stillUtc = moment.utc(data).toDate();
        var local = moment(stillUtc).local().format('DD/MM/YYYY');
        return local;
      },
    },
  ];

  constructor(private modalSearhPedidos: BsModalService) {}

  ngOnInit(): void {
    this._initBotones();
    this._initDatatable();

    this.route.params.subscribe((param) => {
      if (param.id) {
        this.produccionId = param.id;
        this.title = 'Editar';
        this._obtener();
      } else {
        this._obtener();
      }
    });

    this.form = this.formBuilder.group({
      secuenciaOrden: [''],
      estado: [''],
      //sresponsable: [null, [Validators.required]],
      observacion: [''],
      requerido: [this.auth.currentUser()?.userName],
      fechaCreacion: [new Date(), [Validators.required]],
      fechaFinalizacion: [new Date(), [Validators.required]],
    });

    this.minDate = new Date();
  }

  get f() {
    return this.form.controls;
  }

  private _initBotones() {
    //console.log('_initBotones', this.produccionId);
    this.botones = [
      {
        label: 'cancelar',
        icon: 'bx bx-block',
        cssClass: 'btn-warning',
        actionType: () => this._cancelar(),
      },
      {
        label: 'aprobar',
        icon: 'bx bx-check',
        cssClass: 'btn-info',
        visible: false,
        actionType: () => this._aprobar(),
      },
      {
        label: 'guardar',
        icon: 'bx bx-save',
        cssClass: 'btn-info',
        disabled: true,
        actionType: () => this._guardar(),
      },
    ];
  }

  private _initDatatable() {
    this.optionModel = {
      columns: this._initColumnsEditor(),
      newRow: false,
    };
  }

  private _initColumnsEditor(): ColumnsEditorModel[] {
    return [
      {
        title: '...',
        width: 60,
        cell: {
          type: 'button',
          items: [
            {
              class: '',
              type: 'menu',
              items: [
                {
                  title: 'Insertar encima',
                  onclick: (rowIndex: number) =>
                    this._insertBeforeNewRow(rowIndex),
                },
                {
                  title: 'Insertar debajo',
                  onclick: (rowIndex: number) =>
                    this._insertAfterNewRow(rowIndex),
                },
              ],
            },
            {
              class: '',
              icon: 'far fa-trash-alt',
              onclick: (rowIndex: number) => this._deletePedidoRow(rowIndex),
            },
          ],
        },
      },
      {
        title: 'N. pedido',
        data: 'numero',
      },
      {
        title: 'Identificacion 1',
        data: 'identificacion',
        width: 120,
      },
      {
        title: 'Razon Social',
        data: 'razonSocial',
      },
      {
        title: 'Producto',
        data: 'producto',
      },
      {
        title: 'Proceso Ini.',
        data: 'procesoInicial',
        width: 140,
        cell: {
          type: 'select',
          required: true,
          selecting: {
            name: 'TP_PROCESO_PRODUCCION',
            valueName: 'id',
            labelName: 'nombre',
          },
        },
      },
      {
        title: 'Cant.',
        data: 'cantidad',
      },
      {
        title: 'Und.',
        data: 'tipoUnidad',
      },
      {
        title: 'Cant. P',
        data: 'cantidadProduccion',
        width: 80,
        cell: {
          type: 'input',
          format: 'number',
          required: true,
        },
        onChange: (tabla: any, data: any, rowIndex: number) =>
          this._inputCantidadChange(tabla, data, rowIndex),
      },
    ];
  }

  private _inputCantidadChange(
    tabla: any,
    data: ItemOrderDt,
    rowIndex: number
  ) {
    if (!data.cantidadProduccion) {
      const aux = data.cantidadProduccionAux;
      data.cantidadProduccion = aux;

      this._setValorCantidadProduccion(tabla, rowIndex, aux);
      const strError = 'La cantidad a producir no puede estar vacia';
      console.error(strError);
      this.msg.growl.error(strError);
      return;
    }

    if (data.cantidadProduccion > data.cantidad) {
      const aux = data.cantidad;
      data.cantidadProduccion = aux;

      this._setValorCantidadProduccion(tabla, rowIndex, aux);

      const strError =
        'La cantidad a producir no puede ser mayor a la cantidad del pedido detalle';
      console.error(strError);
      this.msg.growl.error(strError);
      return;
    }

    let inputValor = data.cantidadProduccion;

    const cantidadTotales = tabla
      .rows()
      ?.data()
      ?.toArray()
      .filter((pedido: any) => pedido.idPedidoDetalle === data.idPedidoDetalle)
      .reduce((total: number, p: any) => {
        return total + p.cantidadProduccion;
      }, 0);

    if (cantidadTotales > 0 && cantidadTotales > data.cantidadPedido) {
      const aux = data.cantidadProduccionAux;
      data.cantidadProduccion = aux;

      this._setValorCantidadProduccion(tabla, rowIndex, aux);

      const strError =
        'La cantidad total a producir no puede ser mayor a la cantidad del pedido detalle';
      console.error(strError);
      this.msg.growl.error(strError);
      return;
    }

    data.cantidadProduccionAux = inputValor;
  }

  private _setValorCantidadProduccion(
    tabla: any,
    rowIndex: number,
    valor: any
  ) {
    const celda = tabla.cell({ row: rowIndex, column: 8 });
    if (!celda) return;

    const inputElement = celda.node().querySelector('input');

    if (inputElement) inputElement.value = valor;
  }

  private _obtener() {
    this.loading = true;
    //sconsole.log('_obtener', this.produccionId);
    this.http
      .get(
        this.produccionId
          ? `ordenproduccion/${this.produccionId}`
          : 'ordenproduccion'
      )
      .subscribe({
        next: (data: any) => {
          //console.log(data);
          if (data) {
            const { model, detalle, catalogos } = data;

            if (model) {
              this.form.patchValue({
                fechaCreacion: model.fechaCreacion
                  ? moment(model.fechaCreacion, 'DD/MM/YYYY').toDate()
                  : new Date(),
                fechaFinalizacion: model.fechaFinalizacion
                  ? moment(model.fechaFinalizacion, 'DD/MM/YYYY').toDate()
                  : new Date(),
                secuenciaOrden: model.numero,
              });

              console.log(model.estado);

              this.bloquear = model.estado >= 2;
              this.fechaCreacion.disabled = this.bloquear;
              this.fechaFinalizacion.disabled = this.bloquear;

              this.botones[1].visible =
                model.idOrdenProduccion != null && !this.bloquear;
                this.botones[2].disabled = this.bloquear;  
            } else {
              this.botones[2].disabled = false;
            }

            if (catalogos) {
              const { TP_UNIDAD_CALCULO, TP_PROCESO_PRODUCCION } = catalogos;

              this.catalogoTipoUnidad = TP_UNIDAD_CALCULO;
              this.catalogoTipoProceso = TP_PROCESO_PRODUCCION;

              this.dtproduccion.setCatalogs({
                TP_UNIDAD_CALCULO: TP_UNIDAD_CALCULO || [],
                TP_PROCESO_PRODUCCION: TP_PROCESO_PRODUCCION || [],
              });

            }
            

            if (detalle && detalle.length > 0) {
              
              detalle.forEach((row: any) => {
                row.idOrdenProduccionDet = row.id,
                row.procesoInicial = this.catalogoTipoProceso.find(
                  (it: any) => it.id === row.idProcesoInicial
                );
              });

              this.dtproduccion.setItems(detalle);

              if (this.bloquear) this.dtproduccion.disabled = true;
            }

            console.log(data);
          }
          this.loading = false;
        },
        error: (error) => (this.loading = false),
      });
  }

  private _cancelar() {
    this.router.navigate(['production/order/list']);
  }

  private _aprobar() {
    this.msg
      .confirm(
        `¿Desea aprobar la orden de produccion [${this.form.value.secuenciaOrden}]? <br> Recuerde que una vez aprobado no podrá modificarlo.`
      )
      .then((resul) => {
        if (resul.value) {
          this.loading = true;
          this.http
            .put(`ordenproduccion/aprobar/${this.produccionId}`, null)
            .subscribe({
              next: (data: any) => {
                if (data.exito) {
                  this.msg.success(
                    `Orden de produccion [${this.form.value.secuenciaOrden}] aprobado con éxito`
                  );

                  this._obtener();
                } else {
                  this.loading = false;
                  this.msg.error(data.mensaje);
                }
              },
              error: (error) => (this.loading = false),
            });
        }
      });
  }

  private _guardar() {
    if (this.bloquear) return;

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    if (this.dtproduccion.invalid) {
      this.msg.error(
        'El detalle de la orden de produccion contiene campos requeridos sin utilisarse.'
      );
      return;
    }

    const frm = this.form.value;
    const det = this.dtproduccion.getModel().map((it: any) => ({
      id: it.idOrdenProduccionDet || null,
      idPedidoDetalle:it.idPedidoDetalle,
      cantidad: it.cantidad,
      cantidadProd:it.cantidadProduccion,
      idProcesoInicial:it.procesoInicial?.id ?? null,
      idTipoUnidad:it.idTipoUnidad,

      //tipoUnidad: it.tipoUnidadProduccion?.id,

      /*pedidos: it.pedidos.map((it1: any) => ({
        id: it1.idOrdenProduccionDetPedido || null,
        idPedidoDetalle: it1.idPedidoDet || null,
        idPedidoDetalleParcial: null,
        cantidad: it1.cantidad,
        cantidadProd: it1.cantidadProduccion,
        tipoUnidad: it1.idtipoUnidadProduccion,
      })),*/
    }));

    const model: any = {
      fechaCreacion: frm.fechaCreacion,
      fechaFinalizacion: frm.fechaFinalizacion,
      idResponsable: frm.responsable?.value,
      observacion: '',
      detalles: det,
    };

    //console.log(model);

    if (this.produccionId) {
      //console.log(model);
      this._actualizar(model);
    } else {
      this._regitrar(model);
    }
  }

  private _actualizar(model: any) {
    this.http.put(`ordenproduccion/${this.produccionId}`, model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.submitted = false;
          this.msg.success(`Orden de poduccion actualiza con éxito`);
          //this.router.navigate([`production/order/edit/${data.id}`]);
        }else{
          this.submitted = false;
          this.msg.error(data.mensaje);
        }
      },
    });
  }

  private _regitrar(model: any) {
    this.http.post('ordenproduccion', model).subscribe({
      next: (data: any) => {
        if (data && data.exito) {
          this.submitted = false;
          this.msg.success(`Orden de poduccion registrado con éxito`);
          this.router.navigate([`production/order/edit/${data.id}`]);
        }
      },
    });
  }

  private _insertBeforeNewRow(index: number) {
    this.addNewRow({ type: 'insertBeforeNewRow', index });
  }

  private _insertAfterNewRow(index: number) {
    this.addNewRow({ type: 'insertAfterNewRow', index });
  }

  private _deletePedidoRow(index: number) {
    this.msg
      .confirm(`¿Desea eliminar el registro seleccionados.`)
      .then(({ value }) => {
        if (value) {
          //si es modo edicion debe borrar en el dbo...
          this.dtproduccion.deleteRow(index);
        }
      });
  }

  private _obtenerDetallesTotales(): any[] | null {
    /*const aggregatedPedidos =  this.dtproduccion
      .getModel()
      ?.flatMap((item: any) => item.pedidos)
      .reduce((acc: Map<string, number>, pedido: any) => {
        acc.set(
          pedido.idPedidoDet,
          (acc.get(pedido.idPedidoDet) || 0) + pedido.cantidadProduccion
        );
        return acc;
      }, new Map<string, number>());*/
    const aggregatedPedidos = this.dtproduccion
      .getModel()
      .reduce((acc: any, { idPedidoDetalle, cantidadProduccion }: any) => {
        acc.set(
          idPedidoDetalle,
          (acc.get(idPedidoDetalle) || 0) + cantidadProduccion
        );
        return acc;
      }, new Map<string, number>());

    return aggregatedPedidos
      ? Array.from(aggregatedPedidos, ([id, cantidad]) => ({ idOrden:this.produccionId,id, cantidad }))
      : null;
  }

  changeResponsable(item: any) {
    this.form.patchValue({ responsable: item });
  }

  addNewRow(itemModel: ItemOrderModel | null = null) {
    //obtener ids actuales...
    //console.log('addNewRow',this.dtproduccion.getModel());

    const ignoredFields = this._obtenerDetallesTotales();
    //console.log(ignoredFields);

    const modalRef = this.modalSearhPedidos.show(ModalSearchComponent, {
      class: 'modal-dialog-centered modal-xl',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        settings: {
          search: '',
          index: null,
          scrollx: true,
          title: 'Buscador de pedidos detalles',
          url: 'pedido/listarAprobados',
          columns: this.columnsPedido,
          pageLength: 10,
          ignoreFields:
            ignoredFields != null && ignoredFields.length > 0
              ? ignoredFields
              : null,
        },
      },
    });

    if (modalRef.content) {
      modalRef.content.selection.subscribe(({ index, data, action }: any) => {
        const {
          id: idPedidoDetalle,
          idProducto,
          numero,
          identificacion,
          razonSocial,
          producto,
          cantidad,
          cantidadPedido,
          tipoUnidad,
          idTipoUnidad,
        } = data;

        const row: ItemOrderDt = {
          idPedidoDetalle,
          numero,
          identificacion,
          razonSocial,
          producto,
          cantidad,
          cantidadProduccion: cantidad,
          tipoUnidad,
          idTipoUnidad, //aux para control
          idProducto, //aux para control
          cantidadProduccionAux: cantidad, // aux para control
          cantidadPedido, // aux para control
          idProcesoInicial:null,
          procesoInicial:null
          //tipoUnidadProduccion: this.catalogoTipoUnidad.find((it: any) => it.id === idTipoUnidad) ?? null
        };

        if (itemModel && itemModel.type) {
          if (itemModel.type === 'insertAfterNewRow')
            this.dtproduccion.insertAfterNewRow(itemModel.index, row);
          else if (itemModel.type === 'insertBeforeNewRow')
            this.dtproduccion.insertBeforeNewRow(itemModel.index, row);
        } else {
          this.dtproduccion.addNewRow(row);
        }

        /*
        const row = { ...data };

        row.cantidadProduccion = row.cantidad;
        row.tipoUnidadProduccion = this.catalogoTipoUnidad.find(
          (it: any) => it.id === row.idTipoUnidad
        );
        row.pedidos = [
          {
            cantidad: row.cantidad,
            cantidadProduccion: row.cantidad,
            cantidadProduccionAux: row.cantidad,
            cantidadPedido:row.cantidadPedido,
            idPedidoDet: row.id,
            identificacion: row.identificacion,
            idtipoUnidadProduccion: row.idTipoUnidad,
            numero: row.numero,
            producto: row.producto,
            razonSocial: row.razonSocial,
            tipoUnidad: row.tipoUnidad,

          },
        ];

        */
      });
    }
  }
}
