import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BoardCommentsModel } from './task-edit-comments.model';
import { AuthService } from 'src/app/account/services/auth.service';
import { AlertService } from '@services/alert.service';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import 'moment/locale/es';

@Component({
  selector: 'app-task-edit-comments',
  standalone: true,
  imports: [SimplebarAngularModule, CommonModule, FormsModule],
  templateUrl: './task-edit-comments.component.html',
  styleUrl: './task-edit-comments.component.scss',
})
export class TaskEditCommentsComponent implements OnInit {
  private auth = inject(AuthService);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  comentario: string = '';
  urlUsuario: any = this.auth.currentUser()?.userPhoto;

  @Input() taskId: string;
  @Output() changeLoading = new EventEmitter<boolean>();

  isFocused: boolean = false;
  items: BoardCommentsModel[] = [];

  constructor() {}

  ngOnInit(): void {
    moment.locale('es');
    this._obtener();
    /*this.items = [{
      usuario:"Delores Williams",
      comentario:"If several languages coalesce, the grammar of the resulting of the individual",
      url:"assets/images/users/avatar-5.jpg"
    }];*/
  }

  onFocus() {
    this.isFocused = true;
    //desabilitar modo edicion...
    const rows = this.items.filter((it:any)=>it.activaEditar);
    if(rows.length>0){
      rows.forEach((r:any)=>{
        r.activaEditar=false;
        r.comentario=r.auxComentario;
      });
    }
  }

  onBlur() {
    if (!this.comentario.trim()) {
      this.isFocused = false;
    }
  }

  onInput() {
    // El botón se habilitará o deshabilitará automáticamente gracias a [disabled]="!text.trim()"
  }

  onSave() {
    this.changeLoading.emit(true);
    const model = {
      taskId: this.taskId,
      comentario: this.comentario,
    };

    this.http.post(`planificacion/produccion/task/comment`, model).subscribe({
      next: (data: any) => {
        const { exito, mensaje } = data;
        if (exito) {
          this._obtener();
          this.comentario = '';
          this.isFocused = false;
        } else {
          this.msg.growl.error(mensaje);
        }
        this.changeLoading.emit(false);
      },
      error: (error) => {
        console.error(error);
        this.msg.growl.error(error);
        this.changeLoading.emit(false);
      },
    });
  }

  onUpdate(item: BoardCommentsModel) {
    if (item) {
      const model = {
        id: item.id,
        taskId: this.taskId,
        comentario: item.comentario,
      };

      this._actualizarComentario(model);
    }
  }

  eliminarComentario(id: string) {
    if (id) {
      this.msg.confirm(`¿Desea eliminar el comentario.`).then(({ value }) => {
        if (value) {
          this._eliminarComentario(id);
        }
      });
    }
  }

  editarComentario(id: string) {
    if (!id) return;

    let itemToEdit: any = null;

    // Desactivar el modo de edición en otros ítems
    this.items.forEach((item: any) => {
      if (item.id === id) {
        itemToEdit = item;
      } else if (item.activaEditar) {
        item.activaEditar = false;
        item.comentario = item.auxComentario;
      }
    });

    // Activar el modo de edición para el ítem seleccionado, si se encontró
    if (itemToEdit) {
      itemToEdit.activaEditar = true;
    }
  }

  descartarEditar(id: string) {
    const reg: any = this.items.find((it: any) => it.id === id);
    if (reg) {
      reg.activaEditar = false;
      reg.comentario = reg.auxComentario;
    }
  }

  puedeEditar(responsable: string): boolean {
    return responsable === this.auth.currentUser()?.userId;
  }

  private _eliminarComentario(id: string) {
    this.changeLoading.emit(true);
    this.http.delete(`planificacion/produccion/task/comment/${id}`).subscribe({
      next: (data: any) => {
        const { exito, mensaje } = data;
        if (exito) {
          this.msg.growl.success('comentario eliminado con exito');
          this.items = this.items.filter((it: any) => it.id !== id);
        } else {
          this.msg.growl.error(mensaje);
        }
        this.changeLoading.emit(false);
      },
      error: (error) => {
        console.error(error);
        this.msg.growl.error(error);
        this.changeLoading.emit(false);
      },
    });
  }

  private _actualizarComentario(model: any) {
    this.changeLoading.emit(true);
    this.http.put('planificacion/produccion/task/comment', model).subscribe({
      next: (data: any) => {
        const { exito, mensaje } = data;
        if (exito) {
          this._obtener();
        } else {
          this.msg.growl.error(mensaje);
        }
        this.changeLoading.emit(false);
      },
      error: (error) => {
        console.error(error);
        this.msg.growl.error(error);
        this.changeLoading.emit(false);
      },
    });
  }

  private toDate(date: string) {
    const parsedDate = moment(date, 'DD/MM/YYYY HH:mm');
    return parsedDate.format('DD MMM YYYY, HH:mm');
  }
  private _obtener() {
    this.changeLoading.emit(true);
    this.http
      .get(`planificacion/produccion/task/${this.taskId}/comments`)
      .subscribe({
        next: (data: any) => {
          if (data && data.length > 0) {
            this.items = [];
            data.forEach((item: any) => {
              this.items.push({
                id: item.id,
                idResponsable: item.idResponsable,
                comentario: item.comentario,
                auxComentario: item.comentario,
                usuario: item.usuario,
                url: item.url,
                fecha: this.toDate(item.fecha),
                editado: item.editado,
                activaEditar: false,
              });
            });
          }
          this.changeLoading.emit(false);
        },
        error: (error) => {
          console.error(error);
          this.msg.error(error);
          this.changeLoading.emit(false);
        },
      });
  }
}
