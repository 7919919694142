import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDatepickerConfig, BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import moment from 'moment';
import { CommonModule } from '@angular/common';
defineLocale('es', esLocale);

@Component({
  selector: 'app-datepicker',
  standalone: true,
  imports: [
    BsDatepickerModule,
    FormsModule,
    CommonModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ],
  templateUrl: './datepicker.component.html',
  styleUrl: './datepicker.component.scss'
})
export class DatepickerComponent implements OnInit,ControlValueAccessor {

  @Input() isRequired: boolean = false;
  @Input() minDate:Date | undefined;
  f:BsDatepickerConfig;

  datepickerConfig:any;

  value: string;
  _disabled: boolean = false;
  minDateValue:any=null;
  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private bsLocaleService: BsLocaleService){
    this.bsLocaleService.use('es');
  }

  writeValue(value: any): void {
    if(value){      
      const date =  moment(value).format("DD/MM/YYYY");
      this.value = date;
      setTimeout(()=>{
        this.onChange(date);
        this.onTouch();
      },1);
      }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /*setDisabledState?(isDisabled: boolean): void {
    this._disabled=isDisabled;
  }*/
  set disabled(value:boolean){this._disabled = value;}
  
  ngOnInit(): void {
    this.datepickerConfig = {
      dateInputFormat: 'DD/MM/YYYY'
    };

    /*if(this.minDate){
      console.log(this.minDate);
      //let parts=this.minDate.split('/');
      //this.minDateValue =  moment(this.minDate).format("DD/MM/YYYY");
    }*/
  }

  onChangeDate(value:any){
    const date =  moment(value).format("DD/MM/YYYY");
    this.onChange(date);
  }

  
}
