<div class="form-check-list-right">  
    @for (model of items; track $index) {
        <div class="form-check">
            <input
            [name]="model.id"
            class="form-check-input"
            type="checkbox"
            (change)="changeCheckValue($event)"
            [checked]="model.checked"
          />
          <label class="form-check-label">
            {{ model.nombre }}
          </label>
        </div>    
    }
</div>
