import { Component } from '@angular/core';

@Component({
  selector: 'app-proveedor-list',
  standalone: true,
  imports: [],
  templateUrl: './proveedor-list.component.html',
  styleUrl: './proveedor-list.component.scss'
})
export class ProveedorListComponent {

}
