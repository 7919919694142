import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import { PageButtonsComponent, SpinnerComponent } from '@shared/ui';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SelectCatalogComponent } from '@components/index';
import { CommonModule } from '@angular/common';
import { NumbersOnlyDirective } from '@directives/numbers-only.directive';
import { AlertService } from '@services/alert.service';
import { PersonEditViewComponent } from './person-edit-view/person-edit-view.component';

@Component({
  selector: 'app-person-edit',
  standalone: true,
  imports: [
    CommonModule,
    PageButtonsComponent,
    PersonEditViewComponent
  ],
  templateUrl: './person-edit.component.html',
  styleUrl: './person-edit.component.scss',
})
export class PersonEditComponent implements OnInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  personId: string|null=null;
  title: string = 'Nueva Persona';
  botones: PageButton[];
  editMode: boolean;
  
  @ViewChild('cp_personview') personView: any;

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      if (param.id) {
        this.personId = param.id;
        this.title = 'Editar Persona';
        this.editMode = true;
      } 
    });    

    this._initBotones();
  }


  private _initBotones() {
    this.botones = [
      {
        label: 'cancelar',
        icon: 'bx bx-block',
        cssClass: 'btn-warning',
        actionType: () => this._cancelar(),
      },
      {
        label: 'guardar',
        icon: 'bx bx-save',
        cssClass: 'btn-info',
        actionType: () => this._guardar(),
      },
    ];
  }

  private _guardar() {
    if(this.personView){
      this.personView.guardar();
    }
  }

  private _cancelar() {
    this.router.navigate(['person/list']);
  }

  onSuccesss(){
    this.router.navigate([`person/list`]);
  }
  
}
