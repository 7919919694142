import { Injectable, Type } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private backdropElement: HTMLElement | null = null;
  private openModals: number = 0; 

  constructor(private bsModalService: BsModalService) {}

  show<T>(component: Type<T>, config?: ModalOptions): BsModalRef {
    if (!this.backdropElement && this.openModals === 0) {
      this.createBsModalBackdrop();
    }

    this.openModals++; // Incrementar el contador de modales abiertos

    // Configuración para evitar fondo adicional de ngx-bootstrap
    const modalConfig: any = {
      ...config,
      backdrop: false, // Evita que ngx-bootstrap cree un nuevo fondo
      ignoreBackdropClick: true, // Evita cerrar el modal con clic fuera del modal
      keyboard: false,
    };

    // Abre el modal y devuelve la referencia
    const modalRef = this.bsModalService.show(component, modalConfig);
    modalRef.onHidden?.subscribe(() => this.hide()); // Maneja el fondo al cerrar

    return modalRef;
  }

  private hide(): void {
    this.openModals--; // Decrementa el contador de modales abiertos

    if (this.openModals <= 0) {
      this.openModals = 0; // Asegura que no sea negativo
      this.removeBackdrop();
    }
  }

  private createBsModalBackdrop(): void {
    this.backdropElement = document.createElement('bs-modal-backdrop');
    this.backdropElement.className = 'modal-backdrop fade show'; // Estilo utilizado por ngx-bootstrap
    document.body.appendChild(this.backdropElement);
  }

  private removeBackdrop(): void {
    if (this.backdropElement) {
      document.body.removeChild(this.backdropElement);
      this.backdropElement = null;
    }
  }

}
