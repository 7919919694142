import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { OptionsModel } from '@components/datatable/datatable.model';
import { DatatableComponent, DatepickerComponent, SelectCatalogComponent } from '@components/index';
import { AlertService } from '@services/alert.service';
import { ExcelService } from '@services/excel.service';
import { PageButtonsComponent } from '@shared/ui';
import { PageButton } from '@shared/ui/page-buttons/pagebutton.model';
import moment from 'moment';

@Component({
  selector: 'app-report-order',
  standalone: true,
  imports: [
    CommonModule,
    DatatableComponent,
    PageButtonsComponent,
    ReactiveFormsModule,
    FormsModule,
    SelectCatalogComponent,
    DatepickerComponent
  ],
  templateUrl: './report-order.component.html',
  styleUrl: './report-order.component.scss',
})
export class ReportOrderComponent implements OnInit {
  private formBuilder = inject(FormBuilder);
  private excel = inject(ExcelService);
  private http = inject(HttpClient);
  private msg = inject(AlertService);

  botones: PageButton[];
  title: string = 'Reporte Pedidos';
  optionModel: OptionsModel = {};
  filters: any = null;
  submitted: boolean;
  @ViewChild(DatatableComponent) dtPedidos: DatatableComponent;

  public form: FormGroup;

  @ViewChild('cmbTipoReporte') cmbTipoReporte:any;
  @ViewChild('cmbEstado') cmbEstadoReporte:any;

  private ctgsPedido:any[]=[];
  private ctgsPedidoDet:any[]=[];


  constructor() {}

  ngOnInit(): void {
    this._initCatalogos();
    this._initDatatable();
    this._initBotones();

    this.form = this.formBuilder.group({
      tipo:null,
      estado:null,
      numeroPedido: [''],
      cliente: [''],
      producto:[''],
      fechaDesde:null,
      fechaHasta:null
    });
  }

  get f() {
    return this.form.controls;
  }

  private _initDatatable() {
    var columns: any = [
      {
        title: 'Numero',
        data: 'numero',
      },
      {
        title: 'Estado',
        data: 'estado',
        orderable: false,
        searchable: false,
        render: (data: any, type: any) => {
          var porc = (data * 100) / 4;
          return type === 'display'
            ? `<div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style="width: ${porc}%;"
            aria-valuenow="${porc}" aria-valuemin="0" aria-valuemax="40">
          </div>
        </div>`
            : '';
        },
      },
      {
        title: 'Identificacion',
        data: 'identificacion',
      },
      {
        title: 'Cliente',
        data: 'razonSocial',
      },
      {
        title: 'Producto',
        data: 'producto',
        visible:false
      },
      {
        title: 'Und.',
        data: 'unidad',
        visible:false
      },
      {
        title: 'Cant.',
        data: 'cantidad',
        visible:false
      },
      {
        title: 'Precio',
        data: 'precio',
        visible:false
      },
      {
        title: 'Fecha Emision',
        data: 'fechaEmision',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
      {
        title: 'Fecha Entrega',
        data: 'fechaEntrega',
        render: (data: any) => {
          var local = '-';
          if (data) {
            var stillUtc = moment.utc(data).toDate();
            local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          }
          return local;
        },
      },
      {
        title: 'Fecha Validez',
        data: 'fechaValidez',
        render: (data: any) => {
          var stillUtc = moment.utc(data).toDate();
          var local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
          return local;
        },
      },
    ];

    this.optionModel = {
      searching: false,
      lengthChange: false,
      serverSide: true,
      ordering: false,
      autoLoad:false,
      columns: columns,
      url: 'reporte/pedidos/listar',
    };
  }

  private _initCatalogos(){

    this.http.get(`reporte/catalogos/TP_TIPO_REPORT_PEDIDO,EST_PEDIDO,EST_PEDIDO_DETALLE`).subscribe({
      next: (data: any) => {
        console.log(data);

        if (data) {
          const {TP_TIPO_REPORT_PEDIDO,EST_PEDIDO,EST_PEDIDO_DETALLE} =data;
          if(TP_TIPO_REPORT_PEDIDO){
            this.cmbTipoReporte.setItems(TP_TIPO_REPORT_PEDIDO);
            const df = TP_TIPO_REPORT_PEDIDO[0];
            if(df){
            this.cmbTipoReporte.setSelectedItem(df);
            this.form.patchValue({tipo:df.id})
            }
          }
          if(EST_PEDIDO){
            this.ctgsPedido = EST_PEDIDO;
            this.cmbEstadoReporte.setItems(EST_PEDIDO);
          }
          if(EST_PEDIDO_DETALLE){
            this.ctgsPedidoDet = EST_PEDIDO_DETALLE;
          }
        }
      },
      error: (error) => console.error(error),
    });
  }

  private _initBotones() {
    this.botones = [
      {
        label: 'descargar',
        icon: 'bx bx-plus',
        onlyIcon:true,
        cssClass: 'btn-success',
        actionType: () => this._descargar(),
      },
    ];
  }



  private _descargar() {
    const { numeroPedido, cliente,tipo,estado,producto,fechaDesde,fechaHasta } = this.form.value;
    this.filters = {
      tipo,
      estado,
      numero: this.transformEmptyToNull(numeroPedido),
      cliente:this.transformEmptyToNull(cliente),
      producto: this.transformEmptyToNull(producto),
      fechaDesde,
      fechaHasta
    };
    
    this.excel.download('reporte/pedidos/descargar',this.filters).subscribe(
      () => {
        console.log('Archivo Excel descargado correctamente');
      },
      (error) => {
        console.error('Error al descargar el archivo Excel:', error);
      }
    );
  }

  changeTipoReporte(item:any){
    const {value, label}=item
    
    //if(label==='POR PEDIDO DETALLE') 
    this.dtPedidos.getColumn(4)?.visible(label==='POR PEDIDO DETALLE');
    this.dtPedidos.getColumn(5)?.visible(label==='POR PEDIDO DETALLE');
    this.dtPedidos.getColumn(6)?.visible(label==='POR PEDIDO DETALLE');
    this.dtPedidos.getColumn(7)?.visible(label==='POR PEDIDO DETALLE');

    if(label==='POR PEDIDO DETALLE'){
      this.cmbEstadoReporte.setItems(this.ctgsPedidoDet);
    }

    this.form.patchValue({tipo:value})
    this.buscar();
    //console.log('changeTipoReporte',evt);
  }

  changeEstadoReporte(item:any){
    const {value}=item
    this.form.patchValue({estado:value})
  }

  private transformEmptyToNull = (value: any) => {
    return value === "" ? null : value;
  };

  buscar() {
    const { numeroPedido, cliente,tipo,estado,producto,fechaDesde,fechaHasta } = this.form.value;
    this.filters = {
      tipo,
      estado,
      numero: this.transformEmptyToNull(numeroPedido),
      cliente:this.transformEmptyToNull(cliente),
      producto: this.transformEmptyToNull(producto),
      fechaDesde,
      fechaHasta
    };
    this.dtPedidos.reload();
  }
}
