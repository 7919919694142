import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgOption, NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-select-catalog',
  standalone: true,
  imports: [CommonModule, NgSelectModule, FormsModule],
  templateUrl: './select-catalog.component.html',
  styleUrl: './select-catalog.component.scss',
})
export class SelectCatalogComponent implements OnInit {
  selectValue: Array<NgOption> = [];
  selectedItem: NgOption = {};
  addButton: boolean = false;

  @Input() aplicaJeraquia: boolean = false;
  @Input() selectOneItem: boolean = false;
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = false;

  @Input() urlAddItem: string | null = null;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() data: any[] = [];

  @Output() changeValue = new EventEmitter();

  private listaItems: any[] = [];
  private idCatalogoPadre: any = null;

  constructor() {}

  ngOnInit(): void {
    this.addButton = this.urlAddItem ? true : false;
    if (this.data && this.data.length > 0) {
      this.listaItems = this.data;
      this.selectValue = this._loadItems(this.data);
    }
  }

  setSelectedItem(model: any) {
    if (model) {
      this.selectedItem = {
        value: model.id,
        label: model.nombre,
      };
    } else {
      this.selectedItem = {};
    }
  }

  setSelectedItems(model: any[]) {
    const items = model.map((it: any) => ({
      value: it.id,
      label: it.nombre,
    }));
    //console.log('setSelectedItems',items)
    this.selectedItem = items;
  }

  setItemsJerarquia(id: string, reset: boolean = true) {
    if (this.aplicaJeraquia) {
      this.idCatalogoPadre = id;
      var lts = this.listaItems.filter((it: any) => it.idPadre === id);
      this.selectValue = this._loadItems(lts);

      if (this.selectOneItem && lts.length == 1) {
        var it = lts[0];
        this.selectedItem = {
          value: it.id,
          label: it.nombre,
        };

        this._changeValue(this.selectedItem);
      } else {
        if (reset) {
          this.selectedItem = {};
          this._changeValue(null);
        }
      }
    }
  }

  setItems(items: any) {
    //console.log('catalogos',items);
    
    this.listaItems = items;
    if (!this.aplicaJeraquia) {
      this.selectValue = this._loadItems(items);
    }
  }

  setDisabled(disabled: boolean) {
    this.disabled = disabled;
  }

  getCode(): string {
    //console.log(this.selectedItem);
    if (this.selectedItem && this.selectedItem.value) {
      var r = this.aplicaJeraquia
        ? this.listaItems.find(
            (it) =>
              it.id === this.selectedItem.value &&
              it.idPadre === this.idCatalogoPadre
          )
        : this.listaItems.find((it) => it.id === this.selectedItem.value);

      return r && r.codigo ? r.codigo : '';
    } else {
      return '';
    }
  }

  _changeValue(event: any) {
    this.changeValue.emit(event || '');
  }

  private _loadItems(data: any[]) {
    var catalogo: Array<NgOption> = [];
    catalogo = data.map((it) => {
      return {
        value: it.id,
        label: it.nombre,
      };
    });
    return catalogo || [];
  }

  clickAddItem() {}
}
